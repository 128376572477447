import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { RichText } from './RichText'
import { useMst, useTranslations } from '../state'
import { InsightCard } from '../types/insights'
import { getPillTextContent } from '../lib/carousel'

export const InsightSlide = observer(({ card }: { card: InsightCard }) => {
  const { getTranslation } = useTranslations()
  const navigate = useNavigate()
  const {
    explorerToolSection: {
      updateIsCountry,
      updateDropdownItem,
      initNavigatorData,
      fetchPrioritySourceDataset,
    },
  } = useMst()

  const { title, description, pill, routePath, image } = card

  return (
    <div className="flex flex-col h-full w-[300px]">
      {image && (
        <img
          className="rounded-t-xl"
          src={image}
          alt={image}
          style={{ height: 205, maxHeight: 205 }}
        />
      )}
      <div className="flex flex-col justify-between flex-grow px-4 py-5">
        <div>
          <RichText className="font-stratum text-[54px] leading-120% font-medium pb-3">
            {getTranslation(title)}
          </RichText>

          <div className="text-sm line-clamp-4 leading-120%">{getTranslation(description)}</div>
        </div>

        <button
          className="w-fit font-stratum font-light bg-fii-grey-1 hover:bg-opacity-80 rounded px-2 text-sm py-1"
          onClick={async () => {
            const id = routePath.split('study-area-')[1]
            navigate(routePath)
            updateIsCountry(false)
            updateDropdownItem(`study-area-${id}`)
            initNavigatorData()
            await fetchPrioritySourceDataset(id)
          }}
        >
          {getPillTextContent(getTranslation(pill))}
        </button>
      </div>
    </div>
  )
})

export const PillContent = ({ pillNumber, text }: { pillNumber: number; text: string }) => {
  return (
    <div className="text-left">
      {`Priority no. ${pillNumber}`}
      <br />
      {text}
    </div>
  )
}
