import {
  SUSTAINABILITY_CRITERIA,
  SUSTAINABILITY_CRITERIA_BY_QUESTION_ID_PREFIX,
} from '../constants/data'
import { COUNTRIES_INFO_BY_COUNTRY } from '../constants/geo'
import { SustainabilityCriteria } from '../types/data'

export function getCountryInfo(country: string) {
  return COUNTRIES_INFO_BY_COUNTRY[country]
}

export function getSustainabilityCriteriaByQuestionId(
  questionId: string
): SustainabilityCriteria | null {
  const questionIdStarts = Object.keys(SUSTAINABILITY_CRITERIA_BY_QUESTION_ID_PREFIX)
  const item = questionIdStarts.find((questionIdStart) => questionId.startsWith(questionIdStart))
  if (!item) {
    // console.warn(`! Not found a sustainability criteria for '${questionId}'`)
    return null
  }
  return SUSTAINABILITY_CRITERIA_BY_QUESTION_ID_PREFIX[item]
}

export function sortStudyAreas(studyArea: string[]) {
  return SUSTAINABILITY_CRITERIA.filter((v) => studyArea.includes(v))
}
