import { SustainabilityCriteria } from '../types/data'

///////////////////////////////
// filtering paremeters
///////////////////////////////

// labels
export const FILTERING_PARAMETER_GENDER = 'Gender'
export const FILTERING_PARAMETER_AGE_GROUP = 'Age group'
export const FILTERING_PARAMETER_INCOME = 'Income'

// question id
export const FILTERING_PARAMETER_GENDER_QID = 'D1'
export const FILTERING_PARAMETER_AGE_GROUP_QID = 'D2'
export const FILTERING_PARAMETER_INCOME_QID = 'D12_*'

// types
export const FILTERING_PARAMETERS = [
  FILTERING_PARAMETER_GENDER,
  FILTERING_PARAMETER_AGE_GROUP,
  FILTERING_PARAMETER_INCOME,
] as const

///////////////////////////////
// respid
///////////////////////////////

// labels
export const RESPID = 'respid'

// question id
export const RESPID_QID = 'respid'

///////////////////////////////
// group by
///////////////////////////////

// labels
export const COUNTRY = 'Country'

// question id
export const COUNTRY_QID = 'D6'

///////////////////////////////
// sustainability criteria
///////////////////////////////

// labels
export const SUSTAINABILITY_CRITERIA_CLIMATE_CHANGE = 'climate-change'
export const SUSTAINABILITY_CRITERIA_TECHNOLOGY_AND_INNOVATION = 'technology-and-innovation'
export const SUSTAINABILITY_CRITERIA_HEALTH_AND_WELL_BEING = 'health-and-well-being'
export const SUSTAINABILITY_CRITERIA_POLITICAL_AND_LEGAL_LANDSCAPE = 'political-and-legal-landscape'
export const SUSTAINABILITY_CRITERIA_ECONOMIC_GROWTH = 'economic-growth'
export const SUSTAINABILITY_CRITERIA_SOCIAL_AND_CULTURAL_WELL_BEING =
  'social-and-cultural-well-being'

export const SUSTAINABILITY_CRITERIA = [
  SUSTAINABILITY_CRITERIA_ECONOMIC_GROWTH,
  SUSTAINABILITY_CRITERIA_SOCIAL_AND_CULTURAL_WELL_BEING,
  SUSTAINABILITY_CRITERIA_CLIMATE_CHANGE,
  SUSTAINABILITY_CRITERIA_POLITICAL_AND_LEGAL_LANDSCAPE,
  SUSTAINABILITY_CRITERIA_HEALTH_AND_WELL_BEING,
  SUSTAINABILITY_CRITERIA_TECHNOLOGY_AND_INNOVATION,
] as const

///////////////////////////////
// sustainability criteria map
///////////////////////////////

export const QUESTIONS_ID_PREFIX_BY_SUSTAINABILITY_CRITERIA: Record<
  SustainabilityCriteria,
  string[]
> = {
  [SUSTAINABILITY_CRITERIA_CLIMATE_CHANGE]: ['Q3r', 'Q4r', 'Q5ar', 'Q5br'],
  [SUSTAINABILITY_CRITERIA_TECHNOLOGY_AND_INNOVATION]: [
    'Q6r',
    'Q7r',
    'Q8r',
    'Q9r',
    'Q15r',
    'Q15r12oe',
    'Q16r',
    'Q16r10oe',
    'Q17r',
  ],
  [SUSTAINABILITY_CRITERIA_HEALTH_AND_WELL_BEING]: [
    'Q10r',
    'Q11r',
    'Q12r',
    'Q13r',
    'Q13_1',
    'Q14r',
  ],
  [SUSTAINABILITY_CRITERIA_POLITICAL_AND_LEGAL_LANDSCAPE]: ['Q18r', 'Q19r', 'Q20r'],
  [SUSTAINABILITY_CRITERIA_ECONOMIC_GROWTH]: [
    'Q21r',
    'Q21_1r',
    'Q22r',
    'Q22r11oe',
    'Q22_1',
    'Q22_1r11oe',
    'Q23r',
  ],
  [SUSTAINABILITY_CRITERIA_SOCIAL_AND_CULTURAL_WELL_BEING]: [
    'Q24r',
    'Q25r',
    'Q26r',
    'Q27r',
    'Q28r',
  ],
}

// {
//    Q3r: 'Sustainability & climate change',
//    Q4r: 'Sustainability & climate change',
//    Q5ar: 'Sustainability & climate change',
//    Q5br: 'Sustainability & climate change',
//    Q6r: 'Inclusive Digital Transformation',
//    ...
// }
export const SUSTAINABILITY_CRITERIA_BY_QUESTION_ID_PREFIX = Object.entries(
  QUESTIONS_ID_PREFIX_BY_SUSTAINABILITY_CRITERIA
).reduce<Record<string, SustainabilityCriteria>>((acc, curr) => {
  const sustainabilityCriteria = curr[0] as SustainabilityCriteria
  const questions = curr[1]
  questions.forEach((question) => {
    acc[question] = sustainabilityCriteria
  })
  return acc
}, {})
