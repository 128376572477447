import { observer } from 'mobx-react-lite'
import { ComposableMap, Geographies, Geography, ProjectionFunction } from 'react-simple-maps'
// @ts-ignore
import { geoTimes } from 'd3-geo-projection'

import { useMst } from '../state'
import { useBreakpoints } from '../hooks/useBreakpoints'

interface MapProps {
  content: { marker: JSX.Element | null; tooltip: JSX.Element | null; legend: JSX.Element | null }
  isMobile: boolean
  width: number
}

export const Map = observer(({ content, isMobile, width }: MapProps) => {
  const {
    mapSection: { selectedMapSectionId },
  } = useMst()

  const { isXs, isSm, isLg, isMd } = useBreakpoints()

  const getScaleFactor = () => {
    if (isXs) return 170
    if (isSm) return 170
    if (isMd) return 170
    if (isLg) return 170
    return 160
  }

  const getTranslateFactor = () => {
    if (isXs) return [400, 340]
    if (isSm) return [400, 330]
    if (isMd) return [400, 340]
    if (isLg) return [390, 295]
    return [378, 260]
  }

  const maxHeight = isMobile ? width * 0.7 : width * 0.59

  const geoScaleFactor = getScaleFactor()
  const geoTranslateFactor = getTranslateFactor()
  const projection: ProjectionFunction = geoTimes()
    .scale(geoScaleFactor)
    .translate(geoTranslateFactor)

  const legendWidthDesktop = selectedMapSectionId === 'mapAreNationsDoingEnough' ? 130 : 300

  return (
    <div className="relative w-full h-full flex flex-col overflow-hidden">
      <div style={{ maxHeight: maxHeight }}>
        <ComposableMap projection={projection}>
          <Geographies geography="/assets/map.topojson">
            {({ geographies }) =>
              geographies.map((geography) => {
                return (
                  <Geography
                    key={geography.rsmKey}
                    geography={geography}
                    fill="#D8E2EB"
                    stroke="#989C9E"
                    strokeWidth={0.4}
                    style={{
                      default: { outline: 'none' },
                      hover: { outline: 'none' },
                      pressed: { outline: 'none' },
                    }}
                  />
                )
              })
            }
          </Geographies>

          {content.marker}
        </ComposableMap>

        {content.tooltip}

        <div
          className="absolute hidden md:block top-0 right-0 h-3"
          style={{ width: legendWidthDesktop }}
        >
          {content.legend}
        </div>
      </div>
    </div>
  )
})
