import { types as t, getRoot, Instance } from 'mobx-state-tree'
import { groupBy, uniq, uniqBy } from 'lodash-es'

import type { StateInstance } from '.'
import type { MapSectionId, MapTooltip, SourceDatumMapSectionInfo } from '../types/map'
import { Icon } from '../types/icon'

export const MapSectionModel = t
  .model('MapSectionModel', {
    sourceMapDataset: t.frozen([] as Array<SourceDatumMapSectionInfo>),

    selectedMapSectionId: t.frozen('mapHumanitysPriority' as MapSectionId),
    selectedMapQuestionCategoryIndex: 0,

    tooltipInfo: t.frozen(null as MapTooltip | null),
  })
  .views((self) => ({
    get root(): StateInstance {
      return getRoot(self)
    },

    get selectedMapSection() {
      return self.sourceMapDataset.find((d) => d.id === self.selectedMapSectionId)
    },

    get mapSection() {
      return self.sourceMapDataset.find((d) => d.id === self.selectedMapSectionId)
    },
  }))
  .views((self) => ({
    get mapSectionQuestionCategories() {
      const mapSection = self.selectedMapSection
      if (!mapSection) {
        return []
      }
      const categories = uniq(mapSection.dataset.map((d) => d.questionCategory))
      return categories
    },

    get mapSectionQuestionCategoriesAndIcons() {
      const mapSection = self.selectedMapSection
      if (!mapSection) {
        return []
      }
      const categories = mapSection.dataset.map((d) => {
        return {
          questionCategory: d.questionCategory,
          icon: d.icon as Icon,
        }
      })
      return uniqBy(categories, 'questionCategory')
    },

    get mapSectionData() {
      const mapSection = self.selectedMapSection
      if (!mapSection) {
        return {}
      }
      const groupedByCategory = groupBy(mapSection.dataset, 'questionCategory')
      return groupedByCategory
    },
  }))
  .views((self) => ({
    get mapSectionDataset() {
      const category = self.mapSectionQuestionCategories[self.selectedMapQuestionCategoryIndex]
      return self.mapSectionData[category]
    },
  }))
  .actions((self) => ({
    setTooltipInfo(info: MapTooltip | null) {
      self.tooltipInfo = info
    },

    setSelectedMapSection(value: MapSectionId) {
      self.selectedMapSectionId = value
      self.selectedMapQuestionCategoryIndex = 0
    },

    setSelectedMapQuestionCategoryIndex(index: number) {
      self.selectedMapQuestionCategoryIndex = index
    },

    updateSourceMapDataset(dataset: SourceDatumMapSectionInfo[]) {
      self.sourceMapDataset = dataset
    },
  }))
  .actions((self) => ({
    async fetchSourceMapDataset() {
      if (!self.root.fetchJsonDataset) {
        return
      }
      const dataset = await self.root.fetchJsonDataset<SourceDatumMapSectionInfo>(
        'datasets/formatted/map/mapDataset.json'
      )
      self.updateSourceMapDataset(dataset)
    },
  }))
  .actions((self) => ({
    async afterCreate() {
      await self.fetchSourceMapDataset()
    },
  }))

export const mapSectionModelStore = MapSectionModel.create({})

export interface MapSectionModelInstance extends Instance<typeof MapSectionModel> {}
