import { types as t, getRoot, Instance } from 'mobx-state-tree'
import * as _ from 'lodash-es'

import type { StateInstance } from '.'
import type {
  ExplorerToolNavigationDatum,
  ExplorerToolCountrySourceDatum,
  NavigatorByCountryChartInfo,
  ExplorerToolPrioritySourceDatum,
  NavigatorByPriorityChartInfo,
  NavigatorPrioritiesGroupedBy,
} from '../types/navigator'
import { sortStudyAreas } from '../lib/data'
import { COUNTRIES_INFO_BY_ALPHA2CODE } from '../constants/geo'
import {
  formatNavigatorCountryQuestionDataset,
  formatNavigatorPriorityQuestionDataset,
} from '../lib/navigator'
import { groupByMulti, sortArrayBasedOnAnother, sortByKeyBasedOnSortedArray } from '../lib/array'
import {
  INCOME_DOLLARS_ORDER,
  NAVIGATOR_BY_COUNTRY_OR_PRIORITY_CHART_INFO,
  NAVIGATOR_BY_COUNTRY_QUESTION_PREFIX_ORDER,
} from '../constants/navigator'

export const ExplorerToolSectionModel = t
  .model('ExplorerToolSectionModel', {
    isCountry: false,
    sourceExplorerToolNavigationData: t.frozen([] as ExplorerToolNavigationDatum[]),
    exploreToolNavigationData: t.frozen({} as ExplorerToolNavigationDatum),
    navigationButtons: t.frozen([] as string[]),
    dropdownItem: '',
    selectedNavigationButton: '',
    // ---
    countrySourceDataset: t.frozen([] as ExplorerToolCountrySourceDatum[]),
    // ---
    prioritySourceDataset: t.frozen([] as ExplorerToolPrioritySourceDatum[]),
    // ---
    priorityFilterDataDisplayedList: t.frozen([] as string[]),
    priorityFilterSelectedDataDisplayed: t.maybeNull(t.string),
    priorityFilterCountriesList: t.frozen([] as string[]),
    priorityFilterSelectedCountries: t.frozen([] as string[]),
    priorityFilterGenderList: t.frozen([] as string[]),
    priorityFilterSelectedGender: t.frozen([] as string[]),
    priorityFilterAgeGroupList: t.frozen([] as string[]),
    priorityFilterSelectedAgeGroup: t.frozen([] as string[]),
    priorityFilterIncomeList: t.frozen([] as string[]),
    priorityFilterSelectedIncome: t.frozen([] as string[]),
    // ---
    priorityGroupedBy: t.frozen('countries' as NavigatorPrioritiesGroupedBy),
  })
  .views((self) => ({
    get root(): StateInstance {
      return getRoot(self)
    },
  }))
  .views((self) => ({
    get countryDatasetBySelectedStudyArea() {
      const filteredDataByStudyArea = self.countrySourceDataset.filter(
        (d) => d.studyArea === self.selectedNavigationButton
      )
      const filteredDataByStudyAreaSortedByQuestionPrefix = sortByKeyBasedOnSortedArray(
        filteredDataByStudyArea,
        'questionPrefix',
        NAVIGATOR_BY_COUNTRY_QUESTION_PREFIX_ORDER
      )

      const groupedByQuestionPrefix = _.groupBy(
        filteredDataByStudyAreaSortedByQuestionPrefix,
        'questionPrefix'
      )

      // add charts info
      const groupedByQuestionPrefixWithChartInfo = Object.entries(groupedByQuestionPrefix).reduce<
        Record<string, NavigatorByCountryChartInfo>
      >((acc, curr) => {
        const [questionPrefixId, dataset] = curr
        const formattedChart: NavigatorByCountryChartInfo = formatNavigatorCountryQuestionDataset(
          questionPrefixId,
          dataset
        )
        acc[questionPrefixId] = formattedChart
        return acc
      }, {})

      return groupedByQuestionPrefixWithChartInfo
    },

    get priorityDatasetBySelectedQuestion() {
      return self.prioritySourceDataset.filter(
        (d) => d.questionPrefix === self.selectedNavigationButton
      )
    },
  }))
  .views((self) => ({
    get priorityDatasetMaxSumValuesByQuestionIdAndCountry() {
      const dataset = self.priorityDatasetBySelectedQuestion
      const groupByQuestionIdAndCountry = groupByMulti<ExplorerToolPrioritySourceDatum>(dataset, [
        'questionId',
        'countryName',
      ])
      const groupByQuestionIdAndCountryWithCount = Object.entries(
        groupByQuestionIdAndCountry
      ).reduce((acc, [questionId, questionIdDataset]) => {
        const result = Object.entries(questionIdDataset).reduce<Record<string, number>>(
          (acc1, [country, countryDataset]) => {
            // @ts-ignore
            acc1[country] = _.sumBy(countryDataset, 'count')
            return acc1
          },
          {}
        )
        // @ts-ignore
        acc[questionId] = result
        return acc
      }, {})
      return groupByQuestionIdAndCountryWithCount
    },
  }))
  .views((self) => ({
    get priorityDatasetMaxSumValuesByCountry() {
      return self.priorityDatasetMaxSumValuesByQuestionIdAndCountry[
        // @ts-ignore
        self.priorityFilterSelectedDataDisplayed
      ] as Record<string, number>
    },
  }))
  .views((self) => ({
    get priorityFilteredDataset() {
      const dataset = self.priorityDatasetBySelectedQuestion

      if (!self.priorityFilterSelectedDataDisplayed) {
        return {} as NavigatorByPriorityChartInfo
      }

      const filteredDataset = dataset.filter((d) => {
        return (
          d.questionId === self.priorityFilterSelectedDataDisplayed &&
          self.priorityFilterSelectedCountries.includes(d.countryName) &&
          self.priorityFilterSelectedGender.includes(d.gender) &&
          self.priorityFilterSelectedAgeGroup.includes(d.ageGroup) &&
          self.priorityFilterSelectedIncome.includes(d.incomeDollars)
        )
      })

      if (filteredDataset.length === 0) {
        return {} as NavigatorByPriorityChartInfo
      }

      const filteredDatasetFormatted = formatNavigatorPriorityQuestionDataset(filteredDataset)

      return filteredDatasetFormatted
    },
  }))
  .actions((self) => ({
    setIsLoading(isLoading: boolean) {
      if (self.root?.ui?.setIsLoading) {
        self.root.ui.setIsLoading(isLoading)
      }
    },
  }))
  .actions((self) => ({
    updateNavigationButtons(values: string[]) {
      const sortedNavigationButtons = self.isCountry ? sortStudyAreas(values) : values
      self.navigationButtons = sortedNavigationButtons
    },

    updateSelectedNavigationButton(value: string) {
      self.selectedNavigationButton = value
    },

    updateIsCountry(value: boolean) {
      self.isCountry = value
    },

    updateExploreToolNavigationData(dataset: ExplorerToolNavigationDatum) {
      self.exploreToolNavigationData = dataset
    },

    updateSourceExplorerToolNavigationData(dataset: ExplorerToolNavigationDatum[]) {
      self.sourceExplorerToolNavigationData = dataset
    },

    updateCountrySourceDataset(dataset: ExplorerToolCountrySourceDatum[]) {
      self.countrySourceDataset = dataset
    },

    updatePrioritySourceDataset(dataset: ExplorerToolPrioritySourceDatum[]) {
      self.prioritySourceDataset = dataset
    },

    updatePriorityFilterDataDisplayedList(list: string[]) {
      const order =
        NAVIGATOR_BY_COUNTRY_OR_PRIORITY_CHART_INFO[self.selectedNavigationButton]?.kpisOrder

      const sortedList = order ? sortArrayBasedOnAnother(list, order) : list
      self.priorityFilterDataDisplayedList = sortedList
    },

    updatePriorityFilterSelectedDataDisplayed(value: string | null) {
      self.priorityFilterSelectedDataDisplayed = value
    },

    updatePriorityFilterCountriesList(list: string[]) {
      self.priorityFilterCountriesList = list
    },

    initPriorityFilterSelectedCountries(list: string[]) {
      self.priorityFilterSelectedCountries = list
    },

    updatePriorityFilterSelectedCountries(country: string) {
      const areAllCountriesSelected =
        _.xor(self.priorityFilterSelectedCountries, self.priorityFilterCountriesList).length === 0

      if (areAllCountriesSelected) {
        self.priorityFilterSelectedCountries = [country]
      } else {
        if (self.priorityFilterSelectedCountries.includes(country)) {
          self.priorityFilterSelectedCountries = self.priorityFilterSelectedCountries.filter(
            (d) => d !== country
          )
        } else {
          self.priorityFilterSelectedCountries = [...self.priorityFilterSelectedCountries, country]
        }
      }
    },

    updatePriorityFilterGenderList(list: string[]) {
      self.priorityFilterGenderList = list
    },

    initPriorityFilterSelectedGender(list: string[]) {
      self.priorityFilterSelectedGender = list
    },

    updatePriorityFilterSelectedGender(gender: string) {
      const areAllGenderSelected =
        _.xor(self.priorityFilterSelectedGender, self.priorityFilterGenderList).length === 0

      if (areAllGenderSelected) {
        self.priorityFilterSelectedGender = [gender]
      } else {
        if (self.priorityFilterSelectedGender.includes(gender)) {
          self.priorityFilterSelectedGender = self.priorityFilterSelectedGender.filter(
            (d) => d !== gender
          )
        } else {
          self.priorityFilterSelectedGender = [...self.priorityFilterSelectedGender, gender]
        }
      }
    },

    updatePriorityFilterAgeGroupList(list: string[]) {
      self.priorityFilterAgeGroupList = list
    },

    initPriorityFilterSelectedAgeGroup(list: string[]) {
      self.priorityFilterSelectedAgeGroup = list
    },

    updatePriorityFilterSelectedAgeGroup(ageGroup: string) {
      const areAllAgeGroupSelected =
        _.xor(self.priorityFilterSelectedAgeGroup, self.priorityFilterAgeGroupList).length === 0

      if (areAllAgeGroupSelected) {
        self.priorityFilterSelectedAgeGroup = [ageGroup]
      } else {
        if (self.priorityFilterSelectedAgeGroup.includes(ageGroup)) {
          self.priorityFilterSelectedAgeGroup = self.priorityFilterSelectedAgeGroup.filter(
            (d) => d !== ageGroup
          )
        } else {
          self.priorityFilterSelectedAgeGroup = [...self.priorityFilterSelectedAgeGroup, ageGroup]
        }
      }
    },

    updatePriorityFilterIncomeList(list: string[]) {
      self.priorityFilterIncomeList = list
    },

    initPriorityFilterSelectedIncome(list: string[]) {
      self.priorityFilterSelectedIncome = list
    },

    updatePriorityFilterSelectedIncome(income: string) {
      const areAllIncomeSelected =
        _.xor(self.priorityFilterSelectedIncome, self.priorityFilterIncomeList).length === 0

      if (areAllIncomeSelected) {
        self.priorityFilterSelectedIncome = [income]
      } else {
        if (self.priorityFilterSelectedIncome.includes(income)) {
          self.priorityFilterSelectedIncome = self.priorityFilterSelectedIncome.filter(
            (d) => d !== income
          )
        } else {
          self.priorityFilterSelectedIncome = [...self.priorityFilterSelectedIncome, income]
        }
      }
    },

    prioritiesSelectAllCountries() {
      self.priorityFilterSelectedCountries = self.priorityFilterCountriesList
    },

    prioritiesSelectAllGender() {
      self.priorityFilterSelectedGender = self.priorityFilterGenderList
    },

    prioritiesSelectAllAgeGroup() {
      self.priorityFilterSelectedAgeGroup = self.priorityFilterAgeGroupList
    },

    prioritiesSelectAllIncome() {
      self.priorityFilterSelectedIncome = self.priorityFilterIncomeList
    },
  }))
  .actions((self) => ({
    updateDropdownItem(value: string) {
      self.dropdownItem = value
      const navigationData = self.exploreToolNavigationData
      if (_.isEmpty(navigationData) || !navigationData[value]) {
        return
      }
      const navigationButtons = Object.keys(navigationData[value])
      const sortedNavigationButtons = self.isCountry
        ? sortStudyAreas(navigationButtons)
        : navigationButtons
      self.updateNavigationButtons(sortedNavigationButtons)
      self.updateSelectedNavigationButton(sortedNavigationButtons[0])
    },
  }))
  .actions((self) => ({
    initNavigatorData() {
      const url = window.location.pathname.slice(1)
      const dataset = self.sourceExplorerToolNavigationData

      if (self.isCountry) {
        self.updateExploreToolNavigationData(dataset[0])
        const navigationButtons = Object.keys(dataset[0][url])
        const sortedNavigationButtons = sortStudyAreas(navigationButtons)
        self.updateNavigationButtons(sortedNavigationButtons)
        self.updateSelectedNavigationButton(sortedNavigationButtons[0])
      } else {
        self.updateExploreToolNavigationData(dataset[1])
        self.updateNavigationButtons(Object.keys(dataset[1][url]))
        self.updateSelectedNavigationButton(Object.keys(dataset[1][url])[0])
      }
    },

    initPriorityData() {
      const dataset = self.priorityDatasetBySelectedQuestion

      // data displayed
      const dataDisplayedIds = _.uniqBy(dataset, 'questionId').map((d) => d.questionId)
      self.updatePriorityFilterDataDisplayedList(dataDisplayedIds)

      const order =
        NAVIGATOR_BY_COUNTRY_OR_PRIORITY_CHART_INFO[self.selectedNavigationButton]?.kpisOrder
      const sortedList = order ? sortArrayBasedOnAnother(dataDisplayedIds, order) : dataDisplayedIds
      self.updatePriorityFilterSelectedDataDisplayed(_.first(sortedList) || null)

      // countries
      const countries = _.uniqBy(dataset, 'countryName').map((d) => d.countryName)
      self.updatePriorityFilterCountriesList(countries)
      self.initPriorityFilterSelectedCountries(countries)

      // gender
      const gender = _.uniqBy(dataset, 'gender').map((d) => d.gender)
      self.updatePriorityFilterGenderList(gender)
      self.initPriorityFilterSelectedGender(gender)

      // age group
      const ageGroup = _.uniqBy(dataset, 'ageGroup').map((d) => d.ageGroup)
      self.updatePriorityFilterAgeGroupList(ageGroup)
      self.initPriorityFilterSelectedAgeGroup(ageGroup)

      // income
      const income = _.uniqBy(dataset, 'incomeDollars').map((d) => d.incomeDollars)
      const incomeSorted = sortArrayBasedOnAnother(income, INCOME_DOLLARS_ORDER)
      self.updatePriorityFilterIncomeList(incomeSorted)
      self.initPriorityFilterSelectedIncome(incomeSorted)
    },

    updatePriorityGroupedBy(value: NavigatorPrioritiesGroupedBy) {
      self.priorityGroupedBy = value
    },
  }))
  .actions((self) => ({
    async fetchSourceNavigationDataset() {
      self.setIsLoading(true)
      if (!self.root.fetchJsonDataset) {
        return
      }
      const dataset = await self.root.fetchJsonDataset<ExplorerToolNavigationDatum>(
        'datasets/formatted/navigator/navigation.json'
      )
      self.updateSourceExplorerToolNavigationData(dataset)

      const url = window.location.pathname.slice(1)
      if (url?.startsWith('country-') || url?.startsWith('study-area-')) {
        self.initNavigatorData()
      }
      self.setIsLoading(false)
    },

    async fetchCountrySourceDataset(country: string) {
      self.setIsLoading(true)
      if (!self.root.fetchJsonDataset) {
        return
      }
      const dataset = await self.root.fetchJsonDataset<ExplorerToolCountrySourceDatum>(
        `datasets/formatted/navigator/countries/${country}.json`
      )

      self.updateCountrySourceDataset(dataset)
      self.setIsLoading(false)
    },

    async fetchPrioritySourceDataset(priority: string) {
      self.setIsLoading(true)
      if (!self.root.fetchCsvDataset) {
        return
      }
      const dataset = await self.root.fetchCsvDataset<ExplorerToolPrioritySourceDatum>(
        `datasets/formatted/navigator/priorities/${priority}.csv`
      )
      const formattedDataset = dataset.map((d) => {
        return { ...d, count: Number(d.count) }
      })

      self.updatePrioritySourceDataset(formattedDataset)
      self.initPriorityData()
      self.setIsLoading(false)
    },
  }))
  .actions((self) => ({
    async afterCreate() {
      const url = window.location.pathname.slice(1)

      const isNavigatorByCountryPage = url?.startsWith('country-')
      const isNavigatorByPriorityPage = url?.startsWith('study-area-')
      const isNavigatorPage = isNavigatorByCountryPage || isNavigatorByPriorityPage

      if (isNavigatorPage) {
        self.updateDropdownItem(url)
      }

      self.isCountry = isNavigatorByCountryPage

      await self.fetchSourceNavigationDataset()

      if (isNavigatorByCountryPage) {
        const countryAlpha2Code = url.split('-')[1]
        const countryName = COUNTRIES_INFO_BY_ALPHA2CODE[countryAlpha2Code].countryName
        await self.fetchCountrySourceDataset(countryName)
      }

      if (isNavigatorByPriorityPage) {
        const studyArea = url.split('study-area-')[1]
        await self.fetchPrioritySourceDataset(studyArea)
      }
    },
  }))

export const explorerToolSectionModelStore = ExplorerToolSectionModel.create({})

export interface ExplorerToolSectionModelInstance
  extends Instance<typeof ExplorerToolSectionModel> {}
