import { getIconColor } from '../../lib/icons'
import { IconColorTemplate } from '../../types/icon'

const WithTheWorldIcon = ({
  isSelected,
  template,
}: {
  template: IconColorTemplate
  isSelected: boolean
}) => {
  const color = getIconColor(template, isSelected)
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.41998 4.42768C8.54074 0.242133 15.2744 0.189229 19.4605 4.31L19.4611 4.31059C21.4537 6.26101 22.594 8.92003 22.635 11.7082V12.0649C22.5578 13.5133 21.5258 14.9352 19.6112 16.0408C17.5943 17.2045 14.8924 17.8547 12 17.8547C9.10755 17.8547 6.40455 17.2045 4.38893 16.0408C2.47367 14.9347 1.44164 13.5109 1.36497 12.065V11.7086C1.41136 8.97956 2.5049 6.37306 4.41998 4.42768ZM4.41998 4.42768L4.20723 4.21822L4.41998 4.42768Z"
        stroke={color}
        strokeWidth="0.597128"
      />
      <circle cx="12.0004" cy="11.8852" r="10.7483" stroke={color} strokeWidth="0.796171" />
    </svg>
  )
}

export { WithTheWorldIcon }
