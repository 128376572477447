import { CountryName } from '../types/map'
import { useNavigate } from 'react-router-dom'
import { getCountryIcon } from '../lib/icons'
import { useMst, useTranslations } from '../state'
import { observer } from 'mobx-react-lite'

const NavigatorCountryCard = observer(({ id, path }: { id: string; path: string }) => {
  const navigate = useNavigate()
  const { getTranslation } = useTranslations()
  const {
    explorerToolSection: {
      updateIsCountry,
      updateDropdownItem,
      initNavigatorData,
      fetchCountrySourceDataset,
    },
  } = useMst()

  const CountryIcon = getCountryIcon(id as CountryName)

  const countryName = getTranslation(`countryCardTitle-${path}`)

  return (
    <div
      data-id={id}
      data-path={path}
      className="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2 rounded-xl shadow-md bg-fii-white"
      onClick={async () => {
        navigate(path)
        updateIsCountry(true)
        updateDropdownItem(path)
        initNavigatorData()
        await fetchCountrySourceDataset(id)
      }}
    >
      <div className="flex justify-center bg-fii-green-1 cursor-pointer hover:bg-opacity-70 rounded-t-xl">
        <CountryIcon />
      </div>
      <div className="p-4 cursor-pointer text-xl leading-120%">{countryName}</div>
    </div>
  )
})

export { NavigatorCountryCard }
