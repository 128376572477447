import { NavigatorByCountryChartInfo } from '../types/navigator'

export const NAVIGATOR_BY_COUNTRY_QUESTION_PREFIX_ORDER: string[] = [
  'Q3',
  'Q4',
  'Q5a',
  'Q5b',
  'Q8',
  'Q6',
  'Q7',
  'Q9',
  'Q13',
  'Q13_1',
  'Q10',
  'Q11',
  'Q12',
  'Q14',
  'Q18',
  'Q15',
  'Q16',
  'Q17',
  'Q19',
  'Q20',
  'Q21',
  'Q21_1',
  'Q22',
  'Q22_1',
  'Q23',
  'Q28',
  'Q24',
  'Q25',
  'Q26',
  'Q27',
]

export const NAVIGATOR_BY_COUNTRY_OR_PRIORITY_CHART_INFO: Record<
  string,
  Partial<NavigatorByCountryChartInfo>
> = {
  Q3: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Not important at all',
      'Slightly important',
      'Moderately Important',
      'Somewhat important',
      'Very important',
    ],
    kpisOrder: ['Q3r1', 'Q3r2', 'Q3r3', 'Q3r4', 'Q3r5', 'Q3r6', 'Q3r7'],
  },
  Q4: {
    chartType: 'barchart',
    kpisOrder: ['Q4r1', 'Q4r10', 'Q4r2', 'Q4r3', 'Q4r4', 'Q4r5', 'Q4r6', 'Q4r7', 'Q4r8', 'Q4r9'],
  },
  Q5a: {
    chartType: 'barchart',
    kpisOrder: ['Q5ar1', 'Q5ar2', 'Q5ar3', 'Q5ar4', 'Q5ar5', 'Q5ar6', 'Q5ar7', 'Q5ar8'],
  },
  Q5b: {
    chartType: 'barchart',
    kpisOrder: ['Q5br1', 'Q5br2', 'Q5br3', 'Q5br4', 'Q5br5', 'Q5br6', 'Q5br7', 'Q5br8'],
  },
  Q6: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Not valuable',
      'Limited value',
      'Average value',
      'Valuable',
      'Very valuable',
    ],
    kpisOrder: ['Q6r1', 'Q6r2', 'Q6r3', 'Q6r4', 'Q6r5', 'Q6r6', 'Q6r7', 'Q6r8'],
  },
  Q7: {
    chartType: 'barchart',
    kpisOrder: ['Q7r1', 'Q7r2', 'Q7r3', 'Q7r4', 'Q7r5', 'Q7r6', 'Q7r7'],
  },
  Q8: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Very dissatisfied',
      'Dissatisfied',
      'Neither dissatisfied nor satisfied',
      'Satisfied',
      'Very satisfied',
    ],
    kpisOrder: ['Q8r1', 'Q8r2', 'Q8r3', 'Q8r4', 'Q8r5', 'Q8r6', 'Q8r7', 'Q8r8', 'Q8r9'],
  },
  Q9: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Strongly disagree',
      'Disagree',
      'Neither agree nor disagree',
      'Agree',
      'Strongly agree',
    ],
    kpisOrder: [
      'Q9r1',
      'Q9r10',
      'Q9r11',
      'Q9r2',
      'Q9r3',
      'Q9r4',
      'Q9r5',
      'Q9r6',
      'Q9r7',
      'Q9r8',
      'Q9r9',
    ],
  },
  Q10: {
    chartType: 'barchart',
    kpisOrder: ['Q10r1', 'Q10r2', 'Q10r3', 'Q10r4', 'Q10r5', 'Q10r6', 'Q10r7', 'Q10r8'],
  },
  Q11: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Very dissatisfied',
      'Dissatisfied',
      'Neither dissatisfied nor satisfied',
      'Satisfied',
      'Very satisfied',
    ],
    kpisOrder: [
      'Q11r1',
      'Q11r10',
      'Q11r11',
      'Q11r12',
      'Q11r2',
      'Q11r3',
      'Q11r4',
      'Q11r5',
      'Q11r6',
      'Q11r7',
      'Q11r8',
      'Q11r9',
    ],
  },
  Q12: {
    chartType: 'barchart',
    kpisOrder: ['Q12r1', 'Q12r2', 'Q12r3', 'Q12r4', 'Q12r5', 'Q12r6', 'Q12r7'],
  },
  Q13: {
    chartType: 'barchart',
    kpisOrder: [
      'Q13r1c1',
      'Q13r2c1',
      'Q13r3c1',
      'Q13r4c1',
      'Q13r5c1',
      'Q13r6c1',
      'Q13r7c1',
      'Q13r8c1',
      'Q13r9c1',
    ],
  },
  Q13_1: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Very dissatisfied',
      'Dissatisfied',
      'Neither dissatisfied nor satisfied',
      'Satisfied',
      'Very satisfied',
    ],
    kpisOrder: [
      'Q13_1r1',
      'Q13_1r2',
      'Q13_1r3',
      'Q13_1r4',
      'Q13_1r5',
      'Q13_1r6',
      'Q13_1r7',
      'Q13_1r8',
      'Q13_1r9',
    ],
  },
  Q14: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Strongly disagree',
      'Disagree',
      'Neither agree nor disagree',
      'Agree',
      'Strongly agree',
    ],
    kpisOrder: ['Q14r1', 'Q14r2', 'Q14r3', 'Q14r4', 'Q14r5', 'Q14r6'],
  },
  Q15: {
    chartType: 'barchart',
    kpisOrder: [
      'Q15r1',
      'Q15r10',
      'Q15r11',
      'Q15r12',
      'Q15r2',
      'Q15r3',
      'Q15r4',
      'Q15r5',
      'Q15r6',
      'Q15r7',
      'Q15r8',
      'Q15r9',
    ],
  },
  Q16: {
    chartType: 'barchart',
    kpisOrder: [
      'Q16r1',
      'Q16r10',
      'Q16r2',
      'Q16r3',
      'Q16r4',
      'Q16r5',
      'Q16r6',
      'Q16r7',
      'Q16r8',
      'Q16r9',
    ],
  },
  Q17: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Strongly disagree',
      'Disagree',
      'Neither agree nor disagree',
      'Agree',
      'Strongly agree',
    ],
    kpisOrder: ['Q17r1', 'Q17r2', 'Q17r3', 'Q17r4', 'Q17r5', 'Q17r6', 'Q17r7'],
  },
  Q18: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Not at all concerned',
      'Slightly concerned',
      'Moderately concerned',
      'Somewhat concerned',
      'Very concerned',
    ],
    kpisOrder: ['Q18r1', 'Q18r2', 'Q18r3', 'Q18r4', 'Q18r5', 'Q18r6', 'Q18r7', 'Q18r8', 'Q18r9'],
  },
  Q19: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Very dissatisfied',
      'Dissatisfied',
      'Neither dissatisfied nor satisfied',
      'Satisfied',
      'Very satisfied',
    ],
    kpisOrder: [
      'Q19r1',
      'Q19r10',
      'Q19r11',
      'Q19r2',
      'Q19r3',
      'Q19r4',
      'Q19r5',
      'Q19r6',
      'Q19r7',
      'Q19r8',
      'Q19r9',
    ],
  },
  Q20: {
    chartType: 'barchart',
    kpisOrder: ['Q20r1', 'Q20r2', 'Q20r3', 'Q20r4', 'Q20r5', 'Q20r6', 'Q20r7', 'Q20r8', 'Q20r9'],
  },
  Q21: {
    chartType: 'stacked-barchart',
    categoriesOrder: ['Greatly worsened', 'Worsened', 'No Change', 'Improved', 'Greatly improved'],
    kpisOrder: ['Q21r1', 'Q21r2', 'Q21r3', 'Q21r4', 'Q21r5', 'Q21r6', 'Q21r7'],
  },
  Q21_1: {
    chartType: 'stacked-barchart',
    categoriesOrder: ['No', 'Yes'],
    kpisOrder: [
      'Q21_1r1c6',
      'Q21_1r2c6',
      'Q21_1r3c6',
      'Q21_1r4c6',
      'Q21_1r5c6',
      'Q21_1r6c6',
      'Q21_1r7c6',
    ],
  },
  Q22: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Very dissatisfied',
      'Dissatisfied',
      'Neither dissatisfied nor satisfied',
      'Satisfied',
      'Very satisfied',
    ],
    kpisOrder: [
      'Q22r1',
      'Q22r10',
      'Q22r11',
      'Q22r2',
      'Q22r3',
      'Q22r4',
      'Q22r5',
      'Q22r6',
      'Q22r7',
      'Q22r8',
      'Q22r9',
    ],
  },
  Q22_1: {
    chartType: 'barchart',
    kpisOrder: [
      'Q22_1r1c6',
      'Q22_1r2c6',
      'Q22_1r3c6',
      'Q22_1r4c6',
      'Q22_1r5c6',
      'Q22_1r6c6',
      'Q22_1r7c6',
      'Q22_1r8c6',
      'Q22_1r9c6',
      'Q22_1r10c6',
      'Q22_1r11c6',
    ],
  },
  Q23: {
    chartType: 'stacked-barchart',
    kpisOrder: ['Q23r1', 'Q23r2', 'Q23r3', 'Q23r4', 'Q23r5', 'Q23r6', 'Q23r7', 'Q23r8'],
    categoriesOrder: [
      'Strongly disagree',
      'Disagree',
      'Neither agree nor disagree',
      'Agree',
      'Strongly agree',
    ],
  },
  Q24: {
    chartType: 'barchart',
    kpisOrder: ['Q24r1', 'Q24r2', 'Q24r3', 'Q24r4', 'Q24r5', 'Q24r6', 'Q24r7', 'Q24r8'],
  },
  Q25: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Very dissatisfied',
      'Dissatisfied',
      'Neither dissatisfied nor satisfied',
      'Satisfied',
      'Very satisfied',
    ],
    kpisOrder: ['Q25r1', 'Q25r2', 'Q25r3', 'Q25r4', 'Q25r5', 'Q25r6', 'Q25r7', 'Q25r8'],
  },
  Q26: {
    chartType: 'barchart',
    kpisOrder: ['Q26r1', 'Q26r2', 'Q26r3', 'Q26r4', 'Q26r5', 'Q26r6', 'Q26r7', 'Q26r8', 'Q26r9'],
  },
  Q27: {
    chartType: 'barchart',
    kpisOrder: [
      'Q27r1',
      'Q27r10',
      'Q27r2',
      'Q27r3',
      'Q27r4',
      'Q27r5',
      'Q27r6',
      'Q27r7',
      'Q27r8',
      'Q27r9',
    ],
  },
  Q28: {
    chartType: 'stacked-barchart',
    categoriesOrder: [
      'Strongly disagree',
      'Disagree',
      'Neither agree nor disagree',
      'Agree',
      'Strongly agree',
    ],
    kpisOrder: ['Q28r1', 'Q28r2', 'Q28r3', 'Q28r4', 'Q28r5', 'Q28r6', 'Q28r7', 'Q28r8', 'Q28r9'],
  },
}

export const INCOME_DOLLARS_ORDER = [
  'Less than $1,000 USD',
  '$1,000 to $2,499 USD',
  '$2,500 to $4,999 USD',
  '$5,000 to $9,999 USD',
  '$10,000 to $24,999 USD',
  '$25,000 to $49,999 USD',
  '$50,000 to $99,999 USD',
  '$100,000 to $149,999 USD',
  '$150,000 or more',
]

export const CHART_SIZE = 132
