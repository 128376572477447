import { observer } from 'mobx-react-lite'
import { scaleOrdinal } from 'd3-scale'
import * as _ from 'lodash-es'

import type {
  NavigatorBarchartDatum,
  NavigatorByCountryChartInfo,
  NavigatorStackedBarchartDatum,
} from '../types/navigator'
import { useMst, useTranslations } from '../state'
import { sortArrayOfArraysByKeyBasedOnAnother } from '../lib/array'
import { ExplorerToolStackedBarchart } from './ExplorerToolStackedBarchart'
import { ExplorerToolBarchart } from './ExplorerToolBarchart'
import { CHART_SIZE } from '../constants/navigator'

export const ExplorerToolPageCountryContent = observer(() => {
  const {
    explorerToolSection: { selectedNavigationButton, countryDatasetBySelectedStudyArea },
  } = useMst()
  const { getTranslation } = useTranslations()

  const studyAreaKey = `mapHumanitysPriority-${_.camelCase(selectedNavigationButton)}`
  const studyArea = getTranslation(studyAreaKey)

  return (
    <div className="mb-12">
      <h2 className="block sm:hidden md:block lg:hidden mt-8 sm:mt-0 md:mt-8 lg:mt-0 pb-2 sm:pb-0 md:pb-2 lg:pb-0 text-fii-grey-2 leading-8">
        {studyArea}
      </h2>
      {Object.entries(countryDatasetBySelectedStudyArea).map((chartDataset, i) => {
        const isFirst = i === 0
        const isLast = i === Object.entries(countryDatasetBySelectedStudyArea).length - 1

        return (
          <div
            className={`${isFirst ? 'mt-0 sm:mt-8 md:mt-0 lg:mt-8' : ''} ${isLast ? '' : 'mb-8'}`}
            key={chartDataset[0]}
          >
            <ExplorerToolPageCountryContentRow chartDataset={chartDataset[1]} />
          </div>
        )
      })}
    </div>
  )
})

interface ExplorerToolPageCountryContentRowProps {
  chartDataset: NavigatorByCountryChartInfo
}

const ExplorerToolPageCountryContentRow = observer(
  ({ chartDataset }: ExplorerToolPageCountryContentRowProps) => {
    const { getTranslation } = useTranslations()

    if (_.isEmpty(chartDataset)) {
      return null
    }

    const { questionPrefixId, chartType } = chartDataset

    const questionTitle = getTranslation(questionPrefixId)

    return (
      <>
        <h2 className="text-xl border-b pb-2 mb-3 leading-8">{questionTitle}</h2>

        <div className="flex flex-col gap-8">
          <div className="">
            {chartType === 'stacked-barchart' && (
              <ExplorerToolPageCountryContentRowStackedBarchart chartDataset={chartDataset} />
            )}
            {chartType === 'barchart' && (
              <ExplorerToolPageCountryContentRowBarchart chartDataset={chartDataset} />
            )}
          </div>
        </div>
      </>
    )
  }
)

interface ExplorerToolPageCountryContentRowBarchartProps {
  chartDataset: NavigatorByCountryChartInfo
}
const ExplorerToolPageCountryContentRowBarchart = observer(
  ({ chartDataset }: ExplorerToolPageCountryContentRowBarchartProps) => {
    const { getTranslation } = useTranslations()

    const { dataset, kpiColor, kpisOrder } = chartDataset
    const datasetGroupedByQuestionId = _.groupBy(dataset, 'kpi')

    const sortedDatasetGroupedByQuestionId = sortArrayOfArraysByKeyBasedOnAnother(
      Object.entries(datasetGroupedByQuestionId),
      kpisOrder
    )

    const colorScale = scaleOrdinal([dataset[0].kpi], [kpiColor!])

    return (
      <div className="block w-full my-0 mx-auto xl:mx-0 overflow-hidden">
        <div
          className="grid justify-center lg:justify-start"
          style={{
            gridTemplateColumns: `repeat(auto-fit, ${CHART_SIZE}px)`,
            gridAutoRows: 'auto 1fr',
            gridRowGap: 20,
            gridColumnGap: 32,
          }}
        >
          {sortedDatasetGroupedByQuestionId.map(([questionId, datasetOfQuestionId]) => (
            <ExplorerToolBarchart
              key={questionId}
              dataset={datasetOfQuestionId as NavigatorBarchartDatum[]}
              colorScale={colorScale}
              description={getTranslation(questionId)}
            />
          ))}
        </div>
      </div>
    )
  }
)

interface ExplorerToolPageCountryContentRowStackedBarchartProps {
  chartDataset: NavigatorByCountryChartInfo
}
const ExplorerToolPageCountryContentRowStackedBarchart = observer(
  ({ chartDataset }: ExplorerToolPageCountryContentRowStackedBarchartProps) => {
    const { getTranslation } = useTranslations()

    const { dataset, categoriesColors, categoriesOrder, kpisOrder } = chartDataset

    const datasetGroupedByQuestionId = _.groupBy(dataset, 'kpi')
    const sortedDatasetGroupedByQuestionId = sortArrayOfArraysByKeyBasedOnAnother(
      Object.entries(datasetGroupedByQuestionId),
      kpisOrder
    )

    const colorScale = scaleOrdinal(
      Object.keys(categoriesColors!),
      Object.values(categoriesColors!)
    )

    return (
      <div className="flex flex-col gap-y-8">
        {/* legend */}
        <div className="flex flex-wrap gap-x-2">
          {_.cloneDeep(categoriesOrder)
            ?.reverse()
            .map((category) => {
              return (
                <div key={category} className="flex gap-x-1 items-center">
                  <div
                    className="w-4 h-4 rounded-[5px]"
                    style={{ backgroundColor: colorScale(category) }}
                  />
                  <div className="mt-1 text-sm">{category}</div>
                </div>
              )
            })}
        </div>

        {/* charts */}
        <div className="block w-full my-0 mx-auto xl:mx-0 overflow-hidden">
          <div
            className="grid justify-center lg:justify-start"
            style={{
              gridTemplateColumns: `repeat(auto-fit, ${CHART_SIZE}px)`,
              gridAutoRows: 'auto 1fr',
              gridRowGap: 20,
              gridColumnGap: 32,
            }}
          >
            {sortedDatasetGroupedByQuestionId.map(([questionId, datasetOfQuestionId]) => {
              return (
                <ExplorerToolStackedBarchart
                  key={questionId}
                  dataset={datasetOfQuestionId as NavigatorStackedBarchartDatum[]}
                  colorScale={colorScale}
                  categoriesOrder={categoriesOrder!}
                  description={getTranslation(questionId)}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }
)
