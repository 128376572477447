import { observer } from 'mobx-react-lite'

import { useMst } from '../state'
import { PageContainer } from './PageContainer'
import { ContextualData } from './ContextualData'
import { PriorityFilters } from './PriorityFilters'
import { PriorityFiltersMobile } from './PriorityFiltersMobile'
import { ExplorerToolPageContent } from './ExplorerToolPageContent'
import { ExplorerToolHeader } from './ExplorerToolHeader'

interface ExplorerToolPageProps {
  id: string
}

export const ExplorerToolPage = observer(({ id }: ExplorerToolPageProps) => {
  const {
    explorerToolSection: { isCountry },
  } = useMst()

  return (
    <PageContainer>
      <ExplorerToolHeader id={id} />

      <div className="relative max-w-7xl md:mx-auto grid grid-cols-12 gap-8 px-4">
        <aside
          className={`col-span-12 lg:col-span-3 pt-8 order-last lg:order-first ${
            !isCountry && 'hidden lg:block'
          } `}
        >
          {isCountry ? <ContextualData /> : <PriorityFilters />}
        </aside>

        {!isCountry && <PriorityFiltersMobile />}

        <ExplorerToolPageContent />
      </div>
    </PageContainer>
  )
})
