import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { useUi } from '../state'
import { NAVIGATOR_TOOL_SECTION_ID } from '../constants/ui'
import { PageContainer } from './PageContainer'
import { MapSection } from './MapSection'
import { InsightsSection } from './InsightsSection'
import { ExplorerSection } from './ExplorerSection'

export const Homepage = observer(() => {
  const { shouldScrollToNavigatorToolSection, setShouldScrollToNavigatorToolSection } = useUi()

  useEffect(() => {
    if (!shouldScrollToNavigatorToolSection) {
      return
    }

    // necessary because of the time to render the navigator tool section
    setTimeout(() => {
      const navigatorToolSectionNode = document.querySelector(`#${NAVIGATOR_TOOL_SECTION_ID}`)

      if (navigatorToolSectionNode) {
        navigatorToolSectionNode?.scrollIntoView({
          behavior: 'smooth',
          inline: 'nearest', // defines horizontal alignment
          block: 'start', // defines vertical alignment
        })
        setTimeout(() => setShouldScrollToNavigatorToolSection(false), 1000)
      }
    }, 500)
  }, [setShouldScrollToNavigatorToolSection, shouldScrollToNavigatorToolSection])

  return (
    <PageContainer>
      <MapSection />
      <InsightsSection />
      <ExplorerSection />
    </PageContainer>
  )
})
