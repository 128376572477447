import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { StateProvider, globalState } from '../state'
import { createPriorityExplorerToolRoutes } from '../lib/routes'
import { Homepage } from './Homepage'
import { ExplorerToolPage } from './ExplorerToolPage'

export function App() {
  const priorityExplorerToolRoutes = createPriorityExplorerToolRoutes()

  return (
    <div className="min-h-screen bg-fii-white font-poppins">
      <StateProvider value={globalState}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />} />

            {priorityExplorerToolRoutes.map((route) => {
              const id = route.isCountry ? route.path.split('-')[1] : route.id
              return (
                <Route key={route.id} path={route.path} element={<ExplorerToolPage id={id} />} />
              )
            })}

            <Route
              path="*"
              element={
                <main>
                  <h1>404</h1>
                </main>
              }
            />
          </Routes>
        </BrowserRouter>
      </StateProvider>
    </div>
  )
}
