import { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { Transition } from '@headlessui/react'

import { useMst, useTranslations } from '../state'
import { MapCategoriesIcon } from './MapCategoriesIcon'

const MapCategoriesButtons = observer(() => {
  const { getTranslation } = useTranslations()
  const {
    mapSection: {
      selectedMapQuestionCategoryIndex,
      setSelectedMapQuestionCategoryIndex,
      mapSectionQuestionCategoriesAndIcons,
    },
  } = useMst()

  return (
    <div className="flex my-4 md:my-0 gap-2">
      {mapSectionQuestionCategoriesAndIcons.map((d, i) => {
        const isSelected = i === selectedMapQuestionCategoryIndex

        return (
          <div key={i} className="h-10 flex items-center">
            <MapCategoriesIcon
              icon={d.icon}
              isSelected={isSelected}
              onClick={() => setSelectedMapQuestionCategoryIndex(i)}
            />
            <Transition
              as={Fragment}
              show={isSelected}
              enter="transition ease-in duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <div key={d.questionCategory} onClick={() => setSelectedMapQuestionCategoryIndex(i)}>
                {isSelected && (
                  <p className="hidden sm:block md:hidden xl:block pl-2 text-sm">
                    {getTranslation(d.questionCategory)}
                  </p>
                )}
              </div>
            </Transition>
          </div>
        )
      })}
    </div>
  )
})

export default MapCategoriesButtons
