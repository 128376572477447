import * as _ from 'lodash-es'

import type { RouteInfo } from '../types/route'
import type { SustainabilityCriteria } from '../types/data'
import { SUSTAINABILITY_CRITERIA } from '../constants/data'
import { COUNTRIES_INFO } from '../constants/geo'
import { CountryName } from '../types/map'

const priorityExplorerToolRoutes = createPriorityExplorerToolRoutes()
const priorityExplorerToolRoutesById = _.keyBy(priorityExplorerToolRoutes, 'id')

export function createPriorityExplorerToolRoutes() {
  const countriesRoutes: RouteInfo[] = COUNTRIES_INFO.map((countryInfo) => {
    return {
      id: countryInfo.countryName as CountryName,
      path: `country-${countryInfo.alpha2Code}`,
      isCountry: true,
    }
  })

  const studyAreasRoutes: RouteInfo[] = SUSTAINABILITY_CRITERIA.map((studyArea) => {
    return {
      id: studyArea,
      path: `study-area-${studyArea}`,
      isCountry: false,
    }
  })

  return [...studyAreasRoutes, ...countriesRoutes]
}

export function getPathFromSustainabilityCriteria(sustainabilityCriteria: SustainabilityCriteria) {
  return priorityExplorerToolRoutesById[sustainabilityCriteria].path
}
