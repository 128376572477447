import { scaleLinear } from 'd3-scale'
import { sumBy } from 'lodash-es'
import { stackNarrow } from 'react-composable-charts'

import { getRoundedBarPath } from '../lib/draw'

type Datum = {
  id: string
  value: number
  color: string
}

interface MapMarkerRhombusProps<T> {
  dataset: T[]
  strokeWidth: number
  className?: string
  fillColor?: string
  strokeColor?: string
  size?: number
  borderRadius?: number
  rotationDegree?: number
}

export function MapMarkerRhombus<T extends Datum>({
  dataset,
  strokeWidth,
  size = 200,
  rotationDegree = -45,
  borderRadius = 6,
}: MapMarkerRhombusProps<T>) {
  const svgSize = size
  const fillRhombusSize = size - strokeWidth / 2

  const rhombusPath = getRoundedBarPath({
    dimensions: { width: fillRhombusSize, height: fillRhombusSize },
    startPoint: { x: 0, y: 0 },
    angles: {
      bl: 0,
      br: borderRadius,
      tl: borderRadius,
      tr: 0,
    },
  })

  const x = 0
  const y = 0

  const categories = dataset.map((d) => d.id)
  const stackedData = stackNarrow({
    data: dataset,
    categories,
    getCategory: (d) => d.id,
    getGroup: () => 'none',
    getValue: (d) => d.value,
  })

  const maxSumValue = sumBy(dataset, 'value')
  const bandScale = scaleLinear([0, maxSumValue], [0, size])

  const center = svgSize / 2
  const rotateTransformation = `rotate(${rotationDegree}, ${center}, ${center})`

  return (
    <g transform={rotateTransformation}>
      <defs>
        <clipPath id="clip-id">
          <path d={rhombusPath} fill="transparent" stroke="black" strokeWidth={1} />
        </clipPath>
      </defs>

      <g clipPath="url(#clip-id)">
        {stackedData.map((stackedDatum) => {
          const { base, to, datum } = stackedDatum
          const color = datum.color

          return (
            <rect
              key={datum.id}
              className={`${color}`}
              x={x}
              y={y + bandScale(base) + (size - bandScale(maxSumValue))}
              width={size}
              height={bandScale(to) - bandScale(base)}
              fillOpacity={0.85}
            />
          )
        })}
      </g>

      <path d={rhombusPath} fill="transparent" stroke="white" strokeWidth={1} />
    </g>
  )
}
