import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { CloseIcon, InfoIcon } from './Icons'
import { SourceDatumContextualSectionModalInfo } from '../types/contextual'

const InfoModal = ({ modalContent }: { modalContent: SourceDatumContextualSectionModalInfo }) => {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <div className="flex items-center justify-center">
        <button type="button" onClick={openModal}>
          <InfoIcon />
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[400px] transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-end">
                    <button type="button" onClick={closeModal}>
                      <CloseIcon color={'black'} />
                    </button>
                  </div>

                  <div className="flex flex-col gap-4 mt-4 p-4">
                    {modalContent?.definition && (
                      <p className="text-xs font-poppins">{modalContent.definition}</p>
                    )}
                    {modalContent?.source && (
                      <p className="text-xs font-poppins">{modalContent.source}</p>
                    )}
                    {modalContent?.licence && (
                      <p className="text-xs font-poppins">{modalContent.licence}</p>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default InfoModal
