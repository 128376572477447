import { types as t, getRoot, Instance } from 'mobx-state-tree'
import { Translation } from '../types/translation'
import { StateInstance } from '.'

export const TranslationsDataModel = t
  .model('TranslationsDataModel', {
    translationsDataset: t.frozen([] as Array<Translation>),
  })
  .views((self) => ({
    get root(): StateInstance {
      return getRoot(self)
    },
  }))
  .views((self) => ({
    getTranslation(key: string) {
      const translationItem = self.translationsDataset.find(
        (translation) => translation.key === key
      )
      if (!translationItem) {
        // console.warn(`! Error: translation for "${key}" not found.`)
        return ''
      }
      return translationItem.text
    },
  }))
  .actions((self) => ({
    updateTranslationsDataset(dataset: Translation[]) {
      self.translationsDataset = dataset
    },
  }))
  .actions((self) => ({
    async fetchTranslationsDataset() {
      if (!self.root.fetchCsvDataset) {
        return
      }
      const translationsDataset = await self.root.fetchCsvDataset<Translation>('translations.csv')
      self.updateTranslationsDataset(translationsDataset)
    },
  }))
  .actions((self) => ({
    async afterCreate() {
      await self.fetchTranslationsDataset()
    },
  }))

export interface TranslationsDataModelInstance extends Instance<typeof TranslationsDataModel> {}
