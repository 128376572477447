import { observer } from 'mobx-react-lite'

export const MenuFooter = observer(() => {
  const menuItems = [
    {
      name: 'About FII Institute',
      url: 'https://fii-institute.org/about/',
    },
    {
      name: 'Contact Us',
      url: 'https://fii-institute.org/contact/',
    },
    {
      name: 'Privacy Policy',
      url: 'https://fii-institute.org/privacy-policy/',
    },
  ]

  return (
    <ul className="flex flex-col md:flex-row w-full gap-4 justify-between font-poppinsRegular text-base text-fii-white">
      {menuItems.map(({ name, url }, i) => {
        return (
          <li key={i}>
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer hover:text-fii-grey-2 text-sm"
            >
              {name}
            </a>
          </li>
        )
      })}
    </ul>
  )
})
