import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useTranslations } from '../state'
import { NAVIGATOR_TOOL_SECTION_ID } from '../constants/ui'
import { ExplorerSectionSwitch } from './ExplorerSectionSwitch'

export const ExplorerSection = observer(() => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const { getTranslation } = useTranslations()

  useEffect(() => {
    const headerNode = document.querySelector('header')
    const headerBBox = headerNode?.getBoundingClientRect()
    const headerHeight = headerBBox?.height || 0
    setHeaderHeight(headerHeight)
  }, [])

  return (
    <div className="relative w-full text- md:px-18 py-10 bg-fii-light-bg-1">
      <div id={NAVIGATOR_TOOL_SECTION_ID} className="absolute" style={{ top: -headerHeight }} />
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div className="text-5xl w-full flex md:justify-center leading-120%">
          {getTranslation('navigatorSectionTitle')}
        </div>
        <ExplorerSectionSwitch />
      </div>
    </div>
  )
})
