import { getIconColor } from '../../lib/icons'
import { IconColorTemplate } from '../../types/icon'

const EconomicGrowthIcon = ({
  isSelected,
  template,
}: {
  template: IconColorTemplate
  isSelected: boolean
}) => {
  const color = getIconColor(template, isSelected)
  const size = template === 'ui' ? 40 : 28
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.9691 15.9642C40.134 16.829 39.6166 17.25 38.9445 17.6405C32.3508 21.475 25.7647 25.3273 19.1811 29.177C17.6163 30.0925 16.0389 30.9903 14.5046 31.954C13.8021 32.3953 13.2011 32.3775 12.5164 31.9692C8.59816 29.6513 4.65968 27.3688 0.756693 25.0255C-0.30084 24.3915 -0.234925 22.7228 0.860649 22.0735C4.37562 19.9838 7.91087 17.9322 11.4487 15.8805C15.6433 13.4484 19.9216 11.1533 24.0173 8.56398C25.9676 7.33146 27.4765 7.52166 29.2898 8.66035C32.4294 10.6334 35.668 12.4467 38.871 14.3157C39.5126 14.6911 40.134 15.0563 39.9717 15.9591L39.9691 15.9642ZM26.6954 8.23683C26.1907 8.20639 25.927 8.43464 25.6379 8.60455C23.8246 9.67984 22.0215 10.7703 20.1955 11.8253C14.251 15.2642 8.30649 18.7006 2.34423 22.1065C1.48198 22.5985 1.5327 22.8698 2.34423 23.3314C5.82623 25.3095 9.29049 27.3155 12.7547 29.3216C13.2746 29.6234 13.7413 29.669 14.2891 29.3444C15.5216 28.614 16.7972 27.9572 18.0348 27.2369C24.555 23.4481 31.0574 19.6262 37.6004 15.8729C38.5743 15.3124 38.5768 15.0563 37.641 14.5186C34.2072 12.5405 30.7962 10.5243 27.3725 8.53101C27.124 8.38646 26.8374 8.30023 26.6929 8.23683H26.6954Z"
        fill={color}
      />
      <path
        d="M22.5288 22.3982C19.8786 22.454 17.6393 21.5613 15.6155 20.0701C14.7101 19.4031 14.208 18.5079 14.3018 17.3489C14.3982 16.1772 15.2833 15.7639 16.2089 15.3936C19.1989 14.2017 24.3421 15.3911 26.1655 17.6989C27.6262 19.5502 27.0734 21.3559 24.8138 22.104C24.0428 22.3601 23.2465 22.4261 22.5288 22.3982ZM20.9361 21.3483C20.2261 20.6661 20.2159 19.5096 19.024 19.3017C18.2555 19.1673 17.4389 18.835 17.5302 17.8967C17.624 16.9228 18.4889 16.7301 19.3308 16.6768C19.975 16.6363 20.713 16.6337 21.1162 17.1207C22.3157 18.5637 24.0276 18.0463 25.5137 18.589C25.501 18.3329 25.5289 18.2264 25.4883 18.1731C23.7943 15.9338 18.5954 14.8002 16.1912 16.1392C15.2757 16.649 14.9739 17.5619 15.4177 18.4927C16.1126 19.9534 19.0772 21.5334 20.9336 21.3457L20.9361 21.3483ZM22.2853 18.8046C21.9049 19.0176 20.6978 18.5129 21.0148 19.7024C21.243 20.5595 21.8821 21.5562 22.9219 21.6779C23.9616 21.7971 25.0192 21.5004 25.7318 20.59C26.2061 19.9839 26.1173 19.5299 25.2981 19.3118C24.3649 19.0633 23.4367 18.8224 22.2853 18.8046Z"
        fill={color}
      />
      <path
        d="M11.1491 18.7483C10.8676 18.9588 10.5886 19.2149 10.2666 19.4001C9.02898 20.1152 7.70767 20.6833 6.53602 21.5253C5.51653 22.2557 5.56471 23.4096 6.66028 24.0563C8.1109 24.9109 9.56406 25.7656 10.9995 26.6456C11.5346 26.9727 12.0342 27.1553 12.653 26.8408C12.9395 26.6963 13.3935 26.5289 13.6091 26.6532C15.1104 27.5281 16.1553 26.4985 17.2889 25.8492C18.1664 25.3471 19.0185 24.8044 19.896 24.3023C20.2104 24.1222 20.6517 23.8128 20.8951 24.249C21.1107 24.6345 20.6035 24.7689 20.3347 24.9337C19.1503 25.659 17.9559 26.3717 16.7665 27.0919C15.8231 27.6625 14.8898 28.2991 13.7435 27.5484C13.6801 27.5053 13.5609 27.4774 13.5 27.5078C11.4357 28.5425 10.1372 26.8687 8.59275 26.1054C7.83954 25.7326 7.19286 25.0834 6.40922 24.8526C4.89773 24.4037 4.8825 23.227 4.75317 22.0477C4.7202 21.7459 4.90789 21.5735 5.12853 21.4467C6.8784 20.4373 8.63081 19.4305 10.3883 18.4338C10.6926 18.2614 11.0325 18.1473 11.1466 18.7508L11.1491 18.7483Z"
        fill={color}
      />
      <path
        d="M28.0546 10.8384C27.1467 11.4039 26.2616 11.2365 25.2979 10.8663C24.7958 10.6735 24.319 11.2644 23.8423 11.518C22.8735 12.0328 21.9199 12.5756 20.9537 13.0954C20.6823 13.24 20.3501 13.3262 20.198 12.9636C20.0661 12.6466 20.4008 12.5401 20.6037 12.4234C21.9199 11.6677 23.2463 10.9246 24.5676 10.1765C24.6031 10.1562 24.6487 10.1156 24.6792 10.1232C26.1146 10.4833 27.6641 9.71997 29.0082 10.3793C30.8976 11.305 32.6804 12.4462 34.4988 13.5139C35.5664 14.1403 35.5867 16.0576 34.5393 16.6941C33.4209 17.3738 32.3 18.0484 31.1689 18.7027C30.8595 18.8827 30.4157 19.253 30.1621 18.8396C29.8121 18.2665 30.5197 18.2031 30.8088 18.0128C31.6204 17.4828 32.4928 17.0466 33.3119 16.5267C34.6154 15.6974 34.6737 14.5816 33.393 13.7802C31.8587 12.819 30.2559 11.9618 28.6785 11.0691C28.4985 10.9677 28.288 10.9246 28.0546 10.8409V10.8384Z"
        fill={color}
      />
    </svg>
  )
}

export { EconomicGrowthIcon }
