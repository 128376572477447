import { observer } from 'mobx-react-lite'
import { Disclosure, RadioGroup } from '@headlessui/react'

import { useMst, useTranslations } from '../state'
import {
  ArrowDropdown,
  CountriesIcon,
  GenderIcon,
  AgeIcon,
  IncomeIcon,
  DataDisplayedIcon,
} from './Icons'

export const PriorityFilters = () => {
  return (
    <div className="w-full">
      <DataDisplayedAccordion />
      <CountriesAccordion />
      <GenderAccordion />
      <AgeGroupAccordion />
      <IncomeAccordion />
    </div>
  )
}

const DataDisplayedAccordion = observer(() => {
  const {
    explorerToolSection: {
      priorityFilterDataDisplayedList,
      priorityFilterSelectedDataDisplayed,
      updatePriorityFilterSelectedDataDisplayed,
    },
  } = useMst()
  const { getTranslation } = useTranslations()

  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <div className="bg-fii-light-bg-1 rounded-2xl mb-3">
          <Disclosure.Button className="flex w-full justify-between p-4 text-left text-base leading-120% focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 cursor-pointer">
            <div className="flex items-center relative">
              <span className="absolute top-[-5px]">
                <DataDisplayedIcon />
              </span>
              <span className="pl-7 leading-120%">Data displayed</span>
            </div>

            <ArrowDropdown
              className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
              color="#111"
            />
          </Disclosure.Button>

          {open && priorityFilterDataDisplayedList.length > 0 && (
            <Disclosure.Panel className="px-4 mt-1 pb-4 leading-120% text-base font-poppins">
              <RadioGroup value={priorityFilterSelectedDataDisplayed}>
                {priorityFilterDataDisplayedList.map((questionId, i) => {
                  const isSelected = questionId === priorityFilterSelectedDataDisplayed

                  return (
                    <RadioGroup.Option key={i} value={questionId} className="cursor-pointer my-4">
                      {({ checked }) => (
                        <div className="relative flex items-center">
                          <input
                            className="checkbox-round"
                            type="checkbox"
                            value={questionId}
                            checked={isSelected}
                            onChange={() => updatePriorityFilterSelectedDataDisplayed(questionId)}
                          />
                          <span className="checkmark" />
                          <span className="pl-7">{getTranslation(questionId)}</span>
                        </div>
                      )}
                    </RadioGroup.Option>
                  )
                })}
              </RadioGroup>
            </Disclosure.Panel>
          )}
        </div>
      )}
    </Disclosure>
  )
})

const CountriesAccordion = observer(() => {
  const {
    explorerToolSection: {
      priorityFilterCountriesList,
      priorityFilterSelectedCountries,
      updatePriorityFilterSelectedCountries,
      prioritiesSelectAllCountries,
    },
  } = useMst()

  return (
    <Disclosure>
      {({ open }) => (
        <div className="bg-fii-light-bg-1 rounded-2xl mb-3">
          <Disclosure.Button className="flex w-full justify-between p-4 text-left text-base leading-120% focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 cursor-pointer">
            <div className="flex items-center relative">
              <span className="absolute top-[-5px]">
                <CountriesIcon />
              </span>
              <span className="pl-7 leading-120%">Countries</span>
            </div>

            <ArrowDropdown
              className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
              color="#111"
            />
          </Disclosure.Button>

          <Disclosure.Panel className="px-4 mt-1 pb-4 leading-120% text-base font-poppins">
            <SelectAllButton onClick={() => prioritiesSelectAllCountries()} />
            {priorityFilterCountriesList.map((element, i) => {
              const isSelected = priorityFilterSelectedCountries.includes(element)

              return (
                <div
                  key={i}
                  className="flex items-center cursor-pointer my-4"
                  onClick={() => updatePriorityFilterSelectedCountries(element)}
                >
                  <div
                    className={`w-4 h-4 rounded-[3px] ${
                      isSelected ? 'bg-fii-blue-light' : 'bg-fii-grey-1'
                    }`}
                  />
                  <div className="pl-2 mt-1">{element}</div>
                </div>
              )
            })}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
})

const GenderAccordion = observer(() => {
  const {
    explorerToolSection: {
      priorityFilterGenderList,
      priorityFilterSelectedGender,
      updatePriorityFilterSelectedGender,
      prioritiesSelectAllGender,
    },
  } = useMst()

  return (
    <Disclosure>
      {({ open }) => (
        <div className="bg-fii-light-bg-1 rounded-2xl mb-3">
          <Disclosure.Button className="flex w-full justify-between p-4 text-left text-base leading-120% focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 cursor-pointer">
            <div className="flex items-center relative">
              <span className="absolute top-[-5px]">
                <GenderIcon />
              </span>
              <span className="pl-7 leading-120%">Gender</span>
            </div>

            <ArrowDropdown
              className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
              color="#111"
            />
          </Disclosure.Button>

          <Disclosure.Panel className="px-4 mt-1 pb-4 leading-120% text-base font-poppins">
            <SelectAllButton onClick={() => prioritiesSelectAllGender()} />
            {priorityFilterGenderList.map((element, i) => {
              const isSelected = priorityFilterSelectedGender.includes(element)

              return (
                <div
                  key={i}
                  className="flex items-center cursor-pointer my-4"
                  onClick={() => updatePriorityFilterSelectedGender(element)}
                >
                  <div
                    className={`w-4 h-4 rounded-[3px] ${
                      isSelected ? 'bg-fii-blue-light' : 'bg-fii-grey-1'
                    }`}
                  />
                  <div className="pl-2 mt-1">{element}</div>
                </div>
              )
            })}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
})

const AgeGroupAccordion = observer(() => {
  const {
    explorerToolSection: {
      priorityFilterAgeGroupList,
      priorityFilterSelectedAgeGroup,
      updatePriorityFilterSelectedAgeGroup,
      prioritiesSelectAllAgeGroup,
    },
  } = useMst()
  const { getTranslation } = useTranslations()

  return (
    <Disclosure>
      {({ open }) => (
        <div className="bg-fii-light-bg-1 rounded-2xl mb-3">
          <Disclosure.Button className="flex w-full justify-between p-4 text-left text-base leading-120% focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 cursor-pointer">
            <div className="flex items-center relative">
              <span className="absolute top-[-5px]">
                <AgeIcon />
              </span>
              <span className="pl-7 leading-120%">Age group (in years)</span>
            </div>

            <ArrowDropdown
              className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
              color="#111"
            />
          </Disclosure.Button>

          <Disclosure.Panel className="px-4 mt-1 pb-4 leading-120% text-base font-poppins">
            <SelectAllButton onClick={() => prioritiesSelectAllAgeGroup()} />
            {priorityFilterAgeGroupList.map((element, i) => {
              const isSelected = priorityFilterSelectedAgeGroup.includes(element)

              return (
                <div
                  key={i}
                  className="flex items-center cursor-pointer my-4"
                  onClick={() => updatePriorityFilterSelectedAgeGroup(element)}
                >
                  <div
                    className={`w-4 h-4 rounded-[3px] ${
                      isSelected ? 'bg-fii-blue-light' : 'bg-fii-grey-1'
                    }`}
                  />
                  <div className="pl-2 mt-1">
                    {getTranslation(`navigatorByPriority-age-group-${element}`)}
                  </div>
                </div>
              )
            })}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
})

const IncomeAccordion = observer(() => {
  const {
    explorerToolSection: {
      priorityFilterIncomeList,
      priorityFilterSelectedIncome,
      updatePriorityFilterSelectedIncome,
      prioritiesSelectAllIncome,
    },
  } = useMst()
  const { getTranslation } = useTranslations()

  return (
    <Disclosure>
      {({ open }) => (
        <div className="bg-fii-light-bg-1 rounded-2xl mb-3">
          <Disclosure.Button className="flex w-full justify-between p-4 text-left text-base leading-120% focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 cursor-pointer">
            <div className="flex items-center relative">
              <span className="absolute top-[-5px]">
                <IncomeIcon />
              </span>
              <span className="pl-7 leading-120%">Income (in USD)</span>
            </div>

            <ArrowDropdown
              className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
              color="#111"
            />
          </Disclosure.Button>

          <Disclosure.Panel className="px-4 mt-1 pb-4 leading-120% text-base font-poppins">
            <SelectAllButton onClick={() => prioritiesSelectAllIncome()} />
            {priorityFilterIncomeList.map((element, i) => {
              const isSelected = priorityFilterSelectedIncome.includes(element)

              return (
                <div
                  key={i}
                  className="flex items-center cursor-pointer my-4"
                  onClick={() => updatePriorityFilterSelectedIncome(element)}
                >
                  <div
                    className={`w-4 h-4 rounded-[3px] ${
                      isSelected ? 'bg-fii-blue-light' : 'bg-fii-grey-1'
                    }`}
                  />
                  <div className="pl-2 mt-1">
                    {getTranslation(`navigatorByPriority-income-${element}`)}
                  </div>
                </div>
              )
            })}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
})

interface SelectAllButtonProps {
  onClick: () => void
}
function SelectAllButton({ onClick }: SelectAllButtonProps) {
  return (
    <div className="underline text-black cursor-pointer hover:opacity-70" onClick={onClick}>
      Select all
    </div>
  )
}
