import { FiiInstituteLogo } from './Logo'
import { MenuFooter } from './MenuFooter'

export function Footer() {
  return (
    <footer className="w-full bg-fii-menu-bg text-fii-white">
      <div className="max-w-7xl mx-auto px-4 xl:px-0 pt-8 pb-16">
        <div className="max-w-2xl flex flex-col gap-y-8">
          <div className="flex flex-col">
            <div className="text-sm">Powered by</div>
            <FiiInstituteLogo className="max-h-[40px]" />
          </div>
          <div className="text-sm">
            FII Institute is a new global non-profit foundation with an investment arm and one
            agenda: Impact on Humanity
          </div>
          <MenuFooter />
        </div>
      </div>
    </footer>
  )
}
