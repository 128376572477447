import { observer } from 'mobx-react-lite'
import { useMst, useTranslations } from '../state'
import InfoModal from './InfoModal'

export const ContextualData = observer(() => {
  const {
    contextualSection: { currentContextualData, sourceContextualDatasetInfo },
  } = useMst()
  const { getTranslation } = useTranslations()

  const translatedKeys =
    currentContextualData?.dataset &&
    Object.keys(currentContextualData.dataset).map((key) => {
      return {
        key,
        label: getTranslation(key),
        values: [
          {
            number:
              currentContextualData?.dataset[key as keyof typeof currentContextualData.dataset],
          },
        ],
      }
    })

  return (
    <div className="w-full">
      {translatedKeys?.map((item, i) => {
        const modalContent = sourceContextualDatasetInfo?.find((i) => i.key === item.key)
        return (
          <div
            key={i}
            className="bg-fii-light-bg-1 text-fii-black rounded-2xl mb-3 w-full p-4 text-base leading-120%"
          >
            <div className="w-full">
              <div className="flex justify-between items-start">
                <h4 className="mr-4">{item.label}</h4>
                {modalContent && (
                  <div className="cursor-pointer">
                    <InfoModal modalContent={modalContent} />
                  </div>
                )}
              </div>

              <ul className="flex flex-col gap-1 mt-2">
                {item.values.map((value, i) => (
                  <li key={i} className="flex justify-between h-[34px] font-stratum ">
                    <span className="font-bold text-fii-green-2 text-[28px]">{value.number}</span>
                    {/* {value.label && <span>{value.label}</span>} */}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )
      })}
    </div>
  )
})
