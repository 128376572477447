import { cloneDeep, groupBy, uniq } from 'lodash-es'

export function sortByKeyBasedOnSortedArray<T, K extends keyof T, V extends T[K]>(
  dataset: T[],
  key: K,
  order: V[]
) {
  const cloned = cloneDeep(dataset)
  return cloned.sort((a, b) => {
    const indexA = order.findIndex((value) => a[key] === value)
    const indexB = order.findIndex((value) => b[key] === value)
    return indexA - indexB
  })
}

export function groupByMulti<T>(dataset: T[], keys: Array<keyof T>) {
  if (keys.length === 0) {
    return dataset
  }
  const byFirst = groupBy(dataset, keys[0])
  const rest = keys.slice(1)
  for (const prop in byFirst) {
    // @ts-ignore
    byFirst[prop] = groupByMulti(byFirst[prop], rest)
  }
  return byFirst
}

export function joinBy<T>(dataset: T[], name: keyof T, separator: string) {
  return uniq(dataset.map((d) => d[name])).join(separator)
}

export function sortArrayBasedOnAnother(dataset: string[], order: string[]) {
  return order.filter((value) => dataset.includes(value))
}

export function sortArrayOfArraysByKeyBasedOnAnother<T>(dataset: [string, T][], order: string[]) {
  return dataset.sort(([a], [b]) => order.indexOf(a) - order.indexOf(b))
}
