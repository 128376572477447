/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { groupBy } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import { Tab } from '@headlessui/react'

import { createPriorityExplorerToolRoutes } from '../lib/routes'
import { useTranslations } from '../state'
import { transformNavigatorTabsData } from '../lib/navigator'
import {
  HomepageNavigatorDataStructure,
  HomepageNavigatorTransformedData,
} from '../types/navigator'
import { NavigatorCountryCard } from './NavigatorCountryCard'
import { NavigatorPriorityCard } from './NavigatorPriorityCard'
import { classNames } from '../lib/ui'

const ExplorerSectionSwitch = observer(() => {
  const { getTranslation } = useTranslations()

  const priorityExplorerToolRoutes = createPriorityExplorerToolRoutes()
  const priorityExplorerToolRoutesGroupedByIsCountry = groupBy(
    priorityExplorerToolRoutes,
    'isCountry'
  )
  const originalData: HomepageNavigatorDataStructure = Object.entries(
    priorityExplorerToolRoutesGroupedByIsCountry
  )

  const navigatorLabels = [
    getTranslation('navigatorSectionButton-1'),
    getTranslation('navigatorSectionButton-2'),
  ]

  const transformedData: HomepageNavigatorTransformedData = transformNavigatorTabsData(
    navigatorLabels[0],
    navigatorLabels[1],
    originalData
  )

  return (
    <div className="w-full py-8">
      <Tab.Group>
        <Tab.List className="flex justify-center gap-3">
          {Object.keys(transformedData).map((category) => {
            return (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    'h-[40px] px-4 flex justify-center items-center rounded-lg text-sm font-medium leading-5 text-fii-black focus:outline-none',
                    '',
                    selected
                      ? 'bg-fii-grey-2 text-fii-black shadow'
                      : 'bg-fii-grey-1 text-fii-black'
                  )
                }
              >
                {category}
              </Tab>
            )
          })}
        </Tab.List>
        <Tab.Panels className="mt-8">
          {Object.values(transformedData).map((elements, idx) => {
            return (
              <Tab.Panel key={idx} className={classNames('rounded-xl', 'focus:outline-none')}>
                <div className="grid grid-cols-12 gap-5 lg:gap-8 max-w-[1300px] items-start content-start">
                  {elements.map((e: any) => {
                    if (e.isCountry)
                      return <NavigatorCountryCard key={e.id} id={e.id} path={e.path} />

                    return <NavigatorPriorityCard key={e.id} id={e.id} path={e.path} />
                  })}
                </div>
              </Tab.Panel>
            )
          })}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
})

export { ExplorerSectionSwitch }
