import { types as t, getRoot, Instance } from 'mobx-state-tree'

type ExplorerTab = {
  title: string
  content: {
    title: string
    icon: string
    description: string
  }[]
}

export const UiModel = t
  .model('UiModel', {
    isMenuOpen: false,
    isAboutModalOpen: false,
    explorerTab: t.frozen({} as ExplorerTab),
    isMethodologyModalOpen: false,
    isLoading: false,
    shouldScrollToNavigatorToolSection: false,
  })
  .views((self) => ({
    get root() {
      return getRoot(self)
    },
  }))
  .actions((self) => ({
    openMenu() {
      self.isMenuOpen = true
    },

    closeMenu() {
      self.isMenuOpen = false
    },

    openAboutModal() {
      self.isAboutModalOpen = true
    },

    closeAboutModal() {
      self.isAboutModalOpen = false
    },

    openMethodologyModal() {
      self.isMethodologyModalOpen = true
    },

    closeMethodologyModal() {
      self.isMethodologyModalOpen = false
    },

    setIsLoading(isLoading: boolean) {
      self.isLoading = isLoading
    },

    setShouldScrollToNavigatorToolSection(value: boolean) {
      self.shouldScrollToNavigatorToolSection = value
    },
  }))

export const dataStore = UiModel.create({})

export interface UiModelInstance extends Instance<typeof UiModel> {}
