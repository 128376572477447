import { observer } from 'mobx-react-lite'

import { useUi } from '../state'
import { DrawerMobile } from './DrawerMobile'
import { HamburgerIcon } from './Icons'
import { CloseIcon } from './Icons'
import { AcnLogo, FiiCompassNavigatorLogo, FiiInstituteLogo } from './Logo'
import { Menu } from './Menu'

export const Header = () => {
  return (
    <header className="sticky top-0 bg-fii-black z-50 transition-all duration-500">
      <div className="max-w-7xl mx-auto h-18 px-4 py-4 flex justify-between items-center gap-2">
        <LeftSideHeader />

        <nav className="hidden xlg:block">
          <Menu />
        </nav>

        <RightSideHeader />
      </div>
      <DrawerMobile />
    </header>
  )
}

const LeftSideHeader = () => {
  return (
    <div className="flex items-start gap-3">
      <a href="/">
        <FiiCompassNavigatorLogo className="max-h-[36px]" />
      </a>
    </div>
  )
}

const RightSideHeader = observer(() => {
  const { isMenuOpen, openMenu, closeMenu } = useUi()

  return (
    <div className="flex items-center justify-end text-white">
      <div className="items-center hidden xlg:flex lg:gap-8">
        <a href="https://fii-institute.org/about/" target="_blank" rel="noreferrer">
          <FiiInstituteLogo className="max-h-[40px]" />
        </a>
        <a
          className="flex flex-col gap-y-1"
          href="https://www.accenture.com/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text-xxs leading-[4px]">In partnership with</span>
          <AcnLogo className="max-h-[24px]" />
        </a>
      </div>

      <div className="flex items-center xlg:hidden z-60 cursor-pointer pr-1">
        {isMenuOpen ? (
          <button onClick={() => closeMenu()}>
            <CloseIcon color="white" />
          </button>
        ) : (
          <HamburgerIcon onClick={() => openMenu()} />
        )}
      </div>
    </div>
  )
})
