export const CloseIcon = ({ color }: { color: string }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L17 17" stroke={color} />
      <path d="M1 17L17 0.999999" stroke={color} />
    </svg>
  )
}

interface HamburgerIconProps {
  onClick: () => void
  stroke?: string
}

export const HamburgerIcon = ({ onClick, stroke = 'white' }: HamburgerIconProps) => {
  return (
    <button onClick={onClick} className="xl:hidden">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="2" y1="6" x2="22" y2="6" stroke={stroke} />
        <line x1="2" y1="12" x2="22" y2="12" stroke={stroke} />
        <line x1="2" y1="18" x2="22" y2="18" stroke={stroke} />
      </svg>
    </button>
  )
}

export const ArrowDropdown = ({ className, color }: { className?: string; color: string }) => {
  return (
    <div className={`${className} flex justify-center items-center`}>
      <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.70711 7.40813C7.31658 7.79865 6.68342 7.79865 6.29289 7.40813L0.707107 1.82234C0.077142 1.19238 0.523309 0.115234 1.41421 0.115234H12.5858C13.4767 0.115234 13.9229 1.19237 13.2929 1.82234L7.70711 7.40813Z"
          fill={color}
        />
      </svg>
    </div>
  )
}

export const DataDisplayedIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4"
        y="4"
        width="6"
        height="6"
        rx="1"
        stroke="#8ACFF2"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="4"
        y="14"
        width="6"
        height="6"
        rx="1"
        stroke="#8ACFF2"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="14"
        y="14"
        width="6"
        height="6"
        rx="1"
        stroke="#8ACFF2"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="14"
        y="4"
        width="6"
        height="6"
        rx="1"
        stroke="#8ACFF2"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const CountriesIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 6.29395L18.0333 7.39395C17.6871 7.65359 17.2661 7.79395 16.8333 7.79395H13.475C12.8775 7.79395 12.3312 8.13155 12.064 8.66601V8.66601C11.7342 9.32555 11.9053 10.1256 12.476 10.5925L14.476 12.2288C16.0499 13.5166 16.8644 15.514 16.6399 17.5352L16.5936 17.9516C16.5314 18.5116 16.4102 19.0635 16.232 19.598L16 20.2939"
        stroke="#8ACFF2"
        strokeWidth="2"
      />
      <path
        d="M2.5 10.7939L5.7381 10.2543C7.09174 10.0287 8.26529 11.2022 8.03968 12.5558L7.90517 13.3629C7.66434 14.8079 8.3941 16.241 9.70437 16.8961V16.8961C10.7535 17.4207 11.2976 18.6036 11.0131 19.7416L10.5 21.7939"
        stroke="#8ACFF2"
        strokeWidth="2"
      />
      <circle cx="12" cy="12.2939" r="9" stroke="#8ACFF2" strokeWidth="2" />
    </svg>
  )
}

export const GenderIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="7.29395" r="4" stroke="#8ACFF2" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M5.33788 18.6145C5.99897 15.8208 8.77173 14.2939 11.6426 14.2939H12.3574C15.2283 14.2939 18.001 15.8208 18.6621 18.6145C18.79 19.1551 18.8917 19.7207 18.9489 20.2956C19.0036 20.8452 18.5523 21.2939 18 21.2939H6C5.44772 21.2939 4.99642 20.8452 5.0511 20.2956C5.1083 19.7207 5.20997 19.1551 5.33788 18.6145Z"
        stroke="#8ACFF2"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export const AgeIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="6.29395" width="18" height="15" rx="2" stroke="#8ACFF2" strokeWidth="2" />
      <path
        d="M3 10.2939C3 8.40833 3 7.46552 3.58579 6.87973C4.17157 6.29395 5.11438 6.29395 7 6.29395H17C18.8856 6.29395 19.8284 6.29395 20.4142 6.87973C21 7.46552 21 8.40833 21 10.2939H3Z"
        fill="#8ACFF2"
      />
      <path d="M7 3.29395L7 6.29395" stroke="#8ACFF2" strokeWidth="2" strokeLinecap="round" />
      <path d="M17 3.29395L17 6.29395" stroke="#8ACFF2" strokeWidth="2" strokeLinecap="round" />
      <rect x="7" y="12.2939" width="4" height="2" rx="0.5" fill="#8ACFF2" />
      <rect x="7" y="16.2939" width="4" height="2" rx="0.5" fill="#8ACFF2" />
      <rect x="13" y="12.2939" width="4" height="2" rx="0.5" fill="#8ACFF2" />
      <rect x="13" y="16.2939" width="4" height="2" rx="0.5" fill="#8ACFF2" />
    </svg>
  )
}

export const IncomeIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.00001 6.79395V6.79395C3.00001 5.41323 4.1193 4.29395 5.50001 4.29395L19.2857 4.29395C19.4852 4.29395 19.585 4.29395 19.6651 4.322C19.8088 4.37226 19.9217 4.48518 19.9719 4.62881C20 4.70899 20 4.80874 20 5.00823V5.00823C20 6.20519 20 6.80367 19.8317 7.28478C19.5301 8.14652 18.8526 8.82406 17.9908 9.1256C17.5097 9.29395 16.9112 9.29395 15.7143 9.29395L15 9.29395M3.00001 6.79395V6.79395C3.00001 8.17466 4.11929 9.29395 5.50001 9.29395L19 9.29395C19.9428 9.29395 20.4142 9.29395 20.7071 9.58684C21 9.87973 21 10.3511 21 11.2939L21 13.2939M3.00001 6.79395L3.00001 17.2939C3.00001 19.1796 3.00001 20.1224 3.58579 20.7082C4.17158 21.2939 5.11439 21.2939 7.00001 21.2939L19 21.2939C19.9428 21.2939 20.4142 21.2939 20.7071 21.0011C21 20.7082 21 20.2368 21 19.2939L21 17.2939M21 17.2939H17C16.0572 17.2939 15.5858 17.2939 15.2929 17.0011C15 16.7082 15 16.2368 15 15.2939V15.2939C15 14.3511 15 13.8797 15.2929 13.5868C15.5858 13.2939 16.0572 13.2939 17 13.2939H21M21 17.2939L21 13.2939"
        stroke="#8ACFF2"
        strokeWidth="2"
      />
    </svg>
  )
}

export const InfoIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.28 17.8833V10.2993H12.368V17.8833H11.28ZM11.28 8.9713V7.2273H12.368V8.9713H11.28Z"
        fill="#111111"
      />
      <rect x="0.5" y="1.3833" width="23" height="23" rx="11.5" stroke="#111111" />
    </svg>
  )
}
