import * as _ from 'lodash-es'

import { CountryInfo } from '../types/geo'

export const COUNTRIES_INFO: CountryInfo[] = [
  {
    countryName: 'United Kingdom',
    alpha2Code: 'GB',
    region: 'Europe',
    centroid: {
      longitude: -5.156995563222747,
      latitude: 56.90083227861851,
    },
  },
  {
    countryName: 'Germany',
    alpha2Code: 'DE',
    region: 'Europe',
    centroid: {
      longitude: 6.46159124903506,
      latitude: 53.14928158531881,
    },
  },
  {
    countryName: 'France',
    alpha2Code: 'FR',
    region: 'Europe',
    centroid: {
      longitude: -0.936178131958199,
      latitude: 49.20410930340389,
    },
  },
  {
    countryName: 'Italy',
    alpha2Code: 'IT',
    region: 'Europe',
    centroid: {
      longitude: 9.4964,
      latitude: 43.9028,
    },
  },
  {
    countryName: 'Sweden',
    alpha2Code: 'SE',
    region: 'Europe',
    centroid: {
      longitude: 11.277289833215,
      latitude: 64.46541151205906,
    },
  },
  {
    countryName: 'Poland',
    alpha2Code: 'PL',
    region: 'Europe',
    centroid: {
      longitude: 15.43573279234468,
      latitude: 55.06848055692473,
    },
  },
  {
    countryName: 'USA',
    alpha2Code: 'US',
    region: 'North America',
    centroid: {
      longitude: -105.0846159,
      latitude: 41.8938672,
    },
  },
  {
    countryName: 'Canada',
    alpha2Code: 'CA',
    region: 'North America',
    centroid: {
      longitude: -115.56663466105805,
      latitude: 62.3658719808934,
    },
  },
  {
    countryName: 'Mexico',
    alpha2Code: 'MX',
    region: 'North America',
    centroid: {
      longitude: -105.12043937176708,
      latitude: 23.64394843426795,
    },
  },
  {
    countryName: 'Brazil',
    alpha2Code: 'BR',
    region: 'South America',
    centroid: {
      longitude: -53.355206608256424,
      latitude: -10.524630416426652,
    },
  },
  {
    countryName: 'Argentina',
    alpha2Code: 'AR',
    region: 'South America',
    centroid: {
      longitude: -69.44315167435285,
      latitude: -34.60564452525247,
    },
  },
  {
    countryName: 'Egypt',
    alpha2Code: 'EG',
    region: 'Middle East and North Africa (MENA)',
    centroid: {
      longitude: 26.240135435012338,
      latitude: 28.60517034450628,
    },
  },
  {
    countryName: 'Morocco',
    alpha2Code: 'MA',
    region: 'Middle East and North Africa (MENA)',
    centroid: {
      longitude: -11.817212587250811,
      latitude: 33.687598134979325,
    },
  },
  {
    countryName: 'Ethiopia',
    alpha2Code: 'ET',
    region: 'Africa',
    centroid: {
      longitude: 35.914902886544276,
      latitude: 10.729389557048396,
    },
  },
  {
    countryName: 'South Africa',
    alpha2Code: 'ZA',
    region: 'Africa',
    centroid: {
      longitude: 21.578231339253033,
      latitude: -26.72976617311268,
    },
  },
  {
    countryName: 'Nigeria',
    alpha2Code: 'NG',
    region: 'Africa',
    centroid: {
      longitude: 3.147632718717043,
      latitude: 11.609707376481591,
    },
  },
  {
    countryName: 'India',
    alpha2Code: 'IN',
    region: 'Asia',
    centroid: {
      longitude: 75.83375236985977,
      latitude: 25.688393643630018,
    },
  },
  {
    countryName: 'China',
    alpha2Code: 'CN',
    region: 'Asia',
    centroid: {
      longitude: 95.45120548784291,
      latitude: 37.458640079762894,
    },
  },
  {
    countryName: 'Japan',
    alpha2Code: 'JP',
    region: 'Asia',
    centroid: {
      longitude: 133.44649610719037,
      latitude: 38.83580347196827,
    },
  },
  {
    countryName: 'Saudi Arabia',
    alpha2Code: 'SA',
    region: 'Middle East and North Africa (MENA)',
    centroid: {
      longitude: 40.54714296861613,
      latitude: 27.075476084830914,
    },
  },
  {
    countryName: 'Jordan',
    alpha2Code: 'JO',
    region: 'Middle East and North Africa (MENA)',
    centroid: {
      longitude: 31.95728884547246,
      latitude: 34.387064884449156,
    },
  },
  {
    countryName: 'Thailand',
    alpha2Code: 'TH',
    region: 'Asia',
    centroid: {
      longitude: 97.06860621475552,
      latitude: 18.596475025422246,
    },
  },
  {
    countryName: 'Australia',
    alpha2Code: 'AU',
    region: 'Oceania',
    centroid: {
      longitude: 130.11656434074948,
      latitude: -21.134160279858374,
    },
  },
]

export const COUNTRIES_INFO_BY_COUNTRY = _.keyBy(COUNTRIES_INFO, 'countryName')
export const COUNTRIES_INFO_BY_ALPHA2CODE = _.keyBy(COUNTRIES_INFO, 'alpha2Code')

export const COUNTRIES_INFO_BY_REGION = _.groupBy(COUNTRIES_INFO, 'region')

export const REGIONS_ORDER = [
  'Africa',
  'Asia',
  'Europe',
  'Middle East and North Africa (MENA)',
  'North America',
  'Oceania',
  'South America',
]
