import { ExplorerSectionDropdown } from './ExplorerSectionDropdown'
import { ExplorerToolCategoriesButtons } from './ExplorerToolCategoriesButtons'

const ExplorerToolHeader = ({ id }: { id: string }) => {
  return (
    <div className="w-full bg-fii-light-bg-1">
      <div className="max-w-7xl  mx-auto px-4 xl:px-0 grid grid-cols-12 gap-3 md:gap-8 py-8">
        <div className="col-span-12 md:col-span-4 lg:col-span-3">
          <ExplorerSectionDropdown id={id} />
        </div>

        <div className="col-span-12 md:col-span-8 lg:col-span-9 w-full flex justify-center md:justify-start">
          <ExplorerToolCategoriesButtons />
        </div>
      </div>
    </div>
  )
}

export { ExplorerToolHeader }
