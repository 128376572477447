import type { InsightCard } from '../types/insights'
import { getPathFromSustainabilityCriteria } from '../lib/routes'
import {
  SUSTAINABILITY_CRITERIA_ECONOMIC_GROWTH,
  SUSTAINABILITY_CRITERIA_SOCIAL_AND_CULTURAL_WELL_BEING,
  SUSTAINABILITY_CRITERIA_CLIMATE_CHANGE,
  SUSTAINABILITY_CRITERIA_POLITICAL_AND_LEGAL_LANDSCAPE,
  SUSTAINABILITY_CRITERIA_HEALTH_AND_WELL_BEING,
  SUSTAINABILITY_CRITERIA_TECHNOLOGY_AND_INNOVATION,
} from './data'

export const insightsCardsDataset: InsightCard[] = [
  {
    title: 'insightsCardTitle-1',
    description: 'insightsCardDescription-1',
    pill: 'insightsCardPill-1',
    routePath: getPathFromSustainabilityCriteria(SUSTAINABILITY_CRITERIA_ECONOMIC_GROWTH),
    image: '/assets/insights/insight-1.jpg',
  },
  {
    title: 'insightsCardTitle-2',
    description: 'insightsCardDescription-2',
    pill: 'insightsCardPill-2',
    routePath: getPathFromSustainabilityCriteria(
      SUSTAINABILITY_CRITERIA_SOCIAL_AND_CULTURAL_WELL_BEING
    ),
    image: '/assets/insights/insight-2.jpg',
  },
  {
    title: 'insightsCardTitle-3',
    description: 'insightsCardDescription-3',
    pill: 'insightsCardPill-3',
    routePath: getPathFromSustainabilityCriteria(SUSTAINABILITY_CRITERIA_CLIMATE_CHANGE),
    image: '/assets/insights/insight-3.jpg',
  },
  {
    title: 'insightsCardTitle-4',
    description: 'insightsCardDescription-4',
    pill: 'insightsCardPill-4',
    routePath: getPathFromSustainabilityCriteria(
      SUSTAINABILITY_CRITERIA_POLITICAL_AND_LEGAL_LANDSCAPE
    ),
    image: '/assets/insights/insight-4.jpg',
  },
  {
    title: 'insightsCardTitle-5',
    description: 'insightsCardDescription-5',
    pill: 'insightsCardPill-5',
    routePath: getPathFromSustainabilityCriteria(SUSTAINABILITY_CRITERIA_HEALTH_AND_WELL_BEING),
    image: '/assets/insights/insight-5.jpg',
  },
  {
    title: 'insightsCardTitle-6',
    description: 'insightsCardDescription-6',
    pill: 'insightsCardPill-6',
    routePath: getPathFromSustainabilityCriteria(SUSTAINABILITY_CRITERIA_TECHNOLOGY_AND_INNOVATION),
    image: '/assets/insights/insight-6.jpg',
  },
  {
    title: 'insightsCardTitle-7',
    description: 'insightsCardDescription-7',
    pill: 'insightsCardPill-7',
    routePath: getPathFromSustainabilityCriteria(SUSTAINABILITY_CRITERIA_ECONOMIC_GROWTH),
    image: '/assets/insights/insight-7.jpg',
  },
  {
    title: 'insightsCardTitle-8',
    description: 'insightsCardDescription-8',
    pill: 'insightsCardPill-8',
    routePath: getPathFromSustainabilityCriteria(
      SUSTAINABILITY_CRITERIA_SOCIAL_AND_CULTURAL_WELL_BEING
    ),
    image: '/assets/insights/insight-8.jpg',
  },
  {
    title: 'insightsCardTitle-9',
    description: 'insightsCardDescription-9',
    pill: 'insightsCardPill-9',
    routePath: getPathFromSustainabilityCriteria(SUSTAINABILITY_CRITERIA_CLIMATE_CHANGE),
    image: '/assets/insights/insight-9.jpg',
  },
  {
    title: 'insightsCardTitle-10',
    description: 'insightsCardDescription-10',
    pill: 'insightsCardPill-10',
    routePath: getPathFromSustainabilityCriteria(
      SUSTAINABILITY_CRITERIA_POLITICAL_AND_LEGAL_LANDSCAPE
    ),
    image: '/assets/insights/insight-10.jpg',
  },
  {
    title: 'insightsCardTitle-11',
    description: 'insightsCardDescription-11',
    pill: 'insightsCardPill-11',
    routePath: getPathFromSustainabilityCriteria(SUSTAINABILITY_CRITERIA_HEALTH_AND_WELL_BEING),
    image: '/assets/insights/insight-11.jpg',
  },
  {
    title: 'insightsCardTitle-12',
    description: 'insightsCardDescription-12',
    pill: 'insightsCardPill-12',
    routePath: getPathFromSustainabilityCriteria(SUSTAINABILITY_CRITERIA_TECHNOLOGY_AND_INNOVATION),
    image: '/assets/insights/insight-12.jpg',
  },
]
