import { ArgentinaIcon } from '../components/country-icons/ArgentinaIcon'
import { AustraliaIcon } from '../components/country-icons/AustraliaIcon'
import { BrazilIcon } from '../components/country-icons/BrazilIcon'
import { CanadaIcon } from '../components/country-icons/CanadaIcon'
import { ChinaIcon } from '../components/country-icons/ChinaIcon'
import { EgyptIcon } from '../components/country-icons/EgyptIcon'
import { EthiopiaIcon } from '../components/country-icons/EthiopiaIcon'
import { FranceIcon } from '../components/country-icons/FranceIcon'
import { GermanyIcon } from '../components/country-icons/GermanyIcon'
import { IndiaIcon } from '../components/country-icons/IndiaIcon'
import { ItalyIcon } from '../components/country-icons/ItalyIcon'
import { JapanIcon } from '../components/country-icons/JapanIcon'
import { JordanIcon } from '../components/country-icons/JordanIcon'
import { MexicoIcon } from '../components/country-icons/MexicoIcon'
import { MoroccoIcon } from '../components/country-icons/MoroccoIcon'
import { NigeriaIcon } from '../components/country-icons/NigeriaIcon'
import { PolandIcon } from '../components/country-icons/PolandIcon'
import { SaudiArabiaIcon } from '../components/country-icons/SaudiArabiaIcon'
import { SouthAfricaIcon } from '../components/country-icons/SouthAfricaIcon'
import { SwedenIcon } from '../components/country-icons/SwedenIcon'
import { ThailandIcon } from '../components/country-icons/ThailandIcon'
import { UKIcon } from '../components/country-icons/UKIcon'
import { USAIcon } from '../components/country-icons/USAIcon'
import { ClimateChangeIcon } from '../components/map-icons/ClimateChangeIcon'
import { EconomicGrowthIcon } from '../components/map-icons/EconomicGrowthIcon'
import { HumanResilienceIcon } from '../components/map-icons/HumanResilienceIcon'
import { PoliticalAndLegalLandscapeIcon } from '../components/map-icons/PoliticalAndLegalLandscapeIcon'
import { SocialAndCulturalWellBeingIcon } from '../components/map-icons/SocialAndCulturalWellBeingIcon'
import { TechnologyAndInnovationIcon } from '../components/map-icons/TechnologyAndInnovationIcon'
import { WithTheWorldIcon } from '../components/map-icons/WithTheWorldIcon'
import { WithYourCountryIcon } from '../components/map-icons/WithYourCountryIcon'
import { WithYourLifeIcon } from '../components/map-icons/WithYourLifeIcon'
import { Icon, IconColorTemplate } from '../types/icon'
import { CountryName } from '../types/map'

const EmptyIcon = () => null

export function getCountryIcon(countryName: CountryName) {
  switch (countryName) {
    case 'United Kingdom':
      return UKIcon
    case 'Germany':
      return GermanyIcon
    case 'France':
      return FranceIcon
    case 'Italy':
      return ItalyIcon
    case 'Sweden':
      return SwedenIcon
    case 'Poland':
      return PolandIcon
    case 'USA':
      return USAIcon
    case 'Canada':
      return CanadaIcon
    case 'Mexico':
      return MexicoIcon
    case 'Brazil':
      return BrazilIcon
    case 'Argentina':
      return ArgentinaIcon
    case 'Egypt':
      return EgyptIcon
    case 'Morocco':
      return MoroccoIcon
    case 'Ethiopia':
      return EthiopiaIcon
    case 'South Africa':
      return SouthAfricaIcon
    case 'Nigeria':
      return NigeriaIcon
    case 'India':
      return IndiaIcon
    case 'China':
      return ChinaIcon
    case 'Japan':
      return JapanIcon
    case 'Saudi Arabia':
      return SaudiArabiaIcon
    case 'Jordan':
      return JordanIcon
    case 'Thailand':
      return ThailandIcon
    case 'Australia':
      return AustraliaIcon
    default:
      return EmptyIcon
  }
}

export function getCategoryIcon(icon: Icon, isSelected: boolean, template: 'map' | 'ui') {
  switch (icon) {
    case 'climateChangeIcon':
      return () => ClimateChangeIcon({ isSelected, template })
    case 'technologyAndInnovationIcon':
      return () => TechnologyAndInnovationIcon({ isSelected, template })
    case 'humanResilienceIcon':
    case 'healthAndWellBeingIcon':
      return () => HumanResilienceIcon({ isSelected, template })
    case 'politicalAndLegalLandscapeIcon':
      return () => PoliticalAndLegalLandscapeIcon({ isSelected, template })
    case 'economicGrowthIcon':
      return () => EconomicGrowthIcon({ isSelected, template })
    case 'socialAndCulturalWellBeingIcon':
      return () => SocialAndCulturalWellBeingIcon({ isSelected, template })
    case 'withYourLifeIcon':
      return () => WithYourLifeIcon({ isSelected, template })
    case 'withYourCountryIcon':
      return () => WithYourCountryIcon({ isSelected, template })
    case 'withTheWorldIcon':
      return () => WithTheWorldIcon({ isSelected, template })
    default:
      return EmptyIcon
  }
}

function toCamelCase(input: string): string {
  return input.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase())
}

export function convertToIconName(input: string) {
  const camelCaseName = toCamelCase(input)
  return `${camelCaseName}Icon` as Icon
}

export function getIconColor(template: IconColorTemplate, isSelected: boolean) {
  if (template === 'ui') {
    return '#29414D'
  }
  if (template === 'map') {
    if (isSelected) {
      return 'black'
    } else {
      return '#989C9E'
    }
  }
}
