import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'

import { useUi } from '../state'
import { Footer } from './Footer'
import { Header } from './Header'
import { ModalAbout, ModalMethodology } from './Modals'

interface PageContainerProps {
  children: JSX.Element | JSX.Element[]
}

export const PageContainer = observer(({ children }: PageContainerProps) => {
  const location = useLocation()
  const { closeMenu, shouldScrollToNavigatorToolSection } = useUi()

  useEffect(() => {
    closeMenu()
    if (!shouldScrollToNavigatorToolSection) {
      window.scrollTo({ top: 0 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <div>
      <ModalAbout />
      <ModalMethodology />

      <Header />

      <div>{children}</div>

      <Footer />
    </div>
  )
})
