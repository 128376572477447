import { getIconColor } from '../../lib/icons'
import { IconColorTemplate } from '../../types/icon'

const WithYourLifeIcon = ({
  isSelected,
  template,
}: {
  template: IconColorTemplate
  isSelected: boolean
}) => {
  const color = getIconColor(template, isSelected)
  return (
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3406_3312)">
        <path
          d="M17.7442 5.83215C16.1845 4.93326 14.6248 4.78949 13.4562 5.41402C12.4843 3.59454 11.0524 2.06152 9.30298 0.968333C7.52763 -0.0565266 5.90031 -0.0878417 4.80783 0.838091L2.97839 1.86758C2.8638 1.9117 2.75278 1.96437 2.64566 2.02486C2.53784 2.08856 2.43429 2.15902 2.33572 2.23589L2.19658 2.31666V2.33908C1.36495 3.05435 0.910876 4.25429 0.90625 5.79621C0.90625 9.98568 4.02567 15.11 8.76351 22.8772L10.3143 25.3853C10.3965 25.5169 10.541 25.597 10.6962 25.5966C10.7631 25.5977 10.8289 25.5838 10.8894 25.5561L12.2605 24.9312L12.4583 24.8369C14.2045 24.0743 15.8866 23.1722 17.4883 22.1399C21.0305 20.3417 23.0845 18.6023 23.0938 15.1548C23.1162 11.6126 20.8102 7.60323 17.7446 5.83215H17.7442ZM5.31564 1.56617L5.94479 1.20213C6.72233 0.918869 7.74292 1.08505 8.85745 1.73698C10.4485 2.75259 11.7545 4.1575 12.6512 5.81862L10.889 6.83459C9.91968 5.03397 8.49911 3.51625 6.76717 2.42947C6.2583 2.12877 5.70886 1.90316 5.13558 1.75975C5.19073 1.69107 5.25087 1.62666 5.31528 1.56652L5.31564 1.56617ZM12.058 24.0412L10.8894 24.576L9.54069 22.4096C4.87972 14.768 1.80051 9.74263 1.81403 5.8051C1.81403 4.52403 2.16918 3.55753 2.80722 3.00916L3.39153 2.67643C3.64312 2.58355 3.90966 2.53623 4.17833 2.53729C4.93843 2.57466 5.67541 2.80845 6.31772 3.21591C8.05358 4.32973 9.45209 5.89656 10.3631 7.74664C10.4239 7.8655 10.5342 7.95126 10.6641 7.98044C10.7965 8.01033 10.9353 7.97901 11.0417 7.89503C11.9406 7.18012 13.2893 7.25236 14.7636 8.09289C17.5278 9.68854 19.7082 13.4421 19.6765 16.6334C19.663 20.4094 16.8087 21.7712 12.0754 24.0277L12.058 24.0412ZM19.7804 19.735C20.3046 18.7913 20.5715 17.7262 20.5537 16.6469C20.5807 13.1047 18.2748 9.05939 15.2091 7.30182C14.5782 6.92924 13.8828 6.67766 13.1594 6.56022L13.2672 6.49724C13.3747 6.50578 13.4814 6.47553 13.5682 6.41183C14.4671 5.69692 15.8158 5.76916 17.2944 6.60969C20.0587 8.20534 22.239 11.9632 22.2073 15.1502C22.203 17.1999 21.3668 18.5393 19.7978 19.7215L19.78 19.735H19.7804Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3406_3312">
          <rect
            width="22.1875"
            height="25.4261"
            fill="white"
            transform="translate(0.90625 0.172852)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export { WithYourLifeIcon }
