import * as _ from 'lodash-es'
import {
  ExplorerToolCountrySourceDatum,
  HomepageNavigatorDataStructure,
  HomepageNavigatorTransformedData,
  NavigatorBarchartDatum,
  NavigatorByCountryChartInfo,
  NavigatorByPriorityChartInfo,
  NavigatorStackedBarchartDatum,
} from '../types/navigator'
import { NAVIGATOR_BY_COUNTRY_OR_PRIORITY_CHART_INFO } from '../constants/navigator'
import { scaleOrdinal } from 'd3-scale'

export function transformNavigatorTabsData(
  navigatorLabel1: string,
  navigatorLabel2: string,
  data: HomepageNavigatorDataStructure
) {
  const transformedData: HomepageNavigatorTransformedData = {
    [navigatorLabel1]: [],
    [navigatorLabel2]: [],
  }

  data.forEach(([isCountry, items]) => {
    const targetArray =
      isCountry === 'true' ? transformedData[navigatorLabel2] : transformedData[navigatorLabel1]
    targetArray.push(...items.map((item) => ({ ...item, isCountry: isCountry === 'true' })))
  })

  const sortedData = transformedData[navigatorLabel2].sort((a, b) => a.id.localeCompare(b.id))

  return {
    [navigatorLabel1]: transformedData[navigatorLabel1],
    [navigatorLabel2]: sortedData,
  }
}

export function formatNavigatorCountryQuestionDataset(
  questionPrefixId: string,
  dataset: ExplorerToolCountrySourceDatum[]
) {
  const info = NAVIGATOR_BY_COUNTRY_OR_PRIORITY_CHART_INFO[questionPrefixId]
  const { chartType } = info

  if (chartType === 'barchart') {
    const kpis = _.uniqBy(dataset, 'questionId').map((d) => d.questionId)

    const chartMetadata = {
      kpiColor: categoriesColorScale('fii-blue-light'),
      kpisOrder: kpis,
    }

    const formattedDataset: NavigatorBarchartDatum[] = Object.entries(
      _.groupBy(dataset, 'questionId')
    ).map((d) => {
      const [questionId, questionIdDataset] = d
      const countSumOfSelectedAnAnswer = _.sumBy(
        questionIdDataset.filter((d) => d.answerLabel !== 'Not selected'),
        'count'
      )
      // this should be equal to the number of partecipants of a specific country
      const countSumOfAllData = _.sumBy(questionIdDataset, 'count')
      return {
        kpi: questionId,
        value: countSumOfSelectedAnAnswer,
        total: countSumOfAllData,
      }
    })

    return {
      questionPrefixId,
      ...chartMetadata,
      ...info,
      dataset: formattedDataset,
    } as NavigatorByCountryChartInfo
  } else if (chartType === 'stacked-barchart') {
    const kpis = _.uniqBy(dataset, 'questionId').map((d) => d.questionId)
    const categories = _.uniqBy(dataset, 'answerLabel').map((d) => d.answerLabel)

    const chartMetadata = {
      kpisOrder: kpis,
      categoriesColors: _.pick(CATEGORIES_COLORS_MAP, categories),
      categoriesOrder: categories,
    }

    const formattedDataset: NavigatorStackedBarchartDatum[] = dataset.map((d) => {
      return {
        kpi: d.questionId,
        value: d.count,
        category: d.answerLabel,
      }
    })

    return {
      questionPrefixId,
      ...chartMetadata,
      ...info,
      dataset: formattedDataset,
    } as NavigatorByCountryChartInfo
  } else {
    console.warn(`Someting goes wrong with questionPrefixId "${questionPrefixId}"`)
    return { questionPrefixId, ...info } as NavigatorByCountryChartInfo
  }
}

export function formatNavigatorPriorityQuestionDataset(
  filteredDataset: ExplorerToolCountrySourceDatum[]
) {
  //console.log('>> formatNavigatorPriorityQuestionDataset <<')
  //console.log(' > dataset: ', dataset)
  //console.log(' > filteredDataset: ', filteredDataset)

  if (filteredDataset.length === 0) {
    return {} as NavigatorByPriorityChartInfo
  }

  const { questionId, questionPrefix, studyArea } = filteredDataset[0]
  const countryName = ''

  const info = NAVIGATOR_BY_COUNTRY_OR_PRIORITY_CHART_INFO[questionPrefix]
  const { chartType, categoriesOrder } = info

  const kpis = _.uniqBy(filteredDataset, 'countryName').map((d) => d.countryName)
  const categories = _.uniqBy(filteredDataset, 'answerLabel').map((d) => d.answerLabel)
  const filteredDatasetGroupedByCountry = _.groupBy(filteredDataset, 'countryName')

  if (chartType === 'barchart') {
    const chartMetadata = {
      kpiColor: categoriesColorScale('fii-blue-light'),
      kpisOrder: kpis,
    }

    const formattedDataset = Object.entries(filteredDatasetGroupedByCountry).reduce<
      Record<string, NavigatorBarchartDatum[]>
    >((acc, [country, countryDataset]) => {
      const countSumOfSelectedAnAnswer = _.sumBy(
        countryDataset.filter((d) => d.answerLabel !== 'Not selected'),
        'count'
      )
      // this should be equal to the number of partecipants of a specific country
      const countSumOfAllData = _.sumBy(countryDataset, 'count')
      acc[country] = [
        {
          kpi: countryDataset[0].countryName,
          value: countSumOfSelectedAnAnswer,
          total: countSumOfAllData,
        },
      ]
      return acc
    }, {})

    return {
      questionId,
      questionPrefix,
      studyArea,
      countryName,
      chartType,
      ...chartMetadata,
      dataset: formattedDataset,
    } as NavigatorByPriorityChartInfo
  } else if (chartType === 'stacked-barchart') {
    const formattedDataset = Object.entries(filteredDatasetGroupedByCountry).reduce<
      Record<string, NavigatorStackedBarchartDatum[]>
    >((acc, [country, countryDataset]) => {
      const groupedByAnswerLabel = _.groupBy(countryDataset, 'answerLabel')
      acc[country] = Object.entries(groupedByAnswerLabel).map(
        ([answerLabel, answerLabelDataset]) => {
          return {
            kpi: country,
            value: _.sumBy(answerLabelDataset, 'count'),
            category: answerLabel,
          }
        }
      )
      return acc
    }, {})

    return {
      questionId,
      questionPrefix,
      studyArea,
      countryName,
      chartType,
      kpisOrder: kpis,
      categoriesOrder: categoriesOrder,
      categoriesColors: _.pick(CATEGORIES_COLORS_MAP, categories),
      dataset: formattedDataset,
    } as NavigatorByPriorityChartInfo
  } else {
    console.warn(`Someting goes wrong with questionPrefixId "${questionPrefix}"`)
    return {} as NavigatorByPriorityChartInfo
  }
}

const categoriesColorScale = scaleOrdinal(
  ['fii-green-2', 'fii-green-1', 'fii-yellow', 'fii-orange-1', 'fii-orange-2', 'fii-blue-light'],
  ['#5ED500', '#A7EE70', '#FED141', '#FFA985', '#FF7033', '#8ACFF2']
)

const CATEGORIES_COLORS_MAP = {
  'Very satisfied': categoriesColorScale('fii-green-2'),
  Satisfied: categoriesColorScale('fii-green-1'),
  'Neither dissatisfied nor satisfied': categoriesColorScale('fii-yellow'),
  Dissatisfied: categoriesColorScale('fii-orange-1'),
  'Very dissatisfied': categoriesColorScale('fii-orange-2'),
  //
  No: categoriesColorScale('fii-orange-2'),
  Yes: categoriesColorScale('fii-green-2'),
  //
  'Greatly improved': categoriesColorScale('fii-green-2'),
  Improved: categoriesColorScale('fii-green-1'),
  'No Change': categoriesColorScale('fii-yellow'),
  Worsened: categoriesColorScale('fii-orange-1'),
  'Greatly worsened': categoriesColorScale('fii-orange-2'),
  //
  'Strongly agree': categoriesColorScale('fii-green-2'),
  Agree: categoriesColorScale('fii-green-1'),
  'Neither agree nor disagree': categoriesColorScale('fii-yellow'),
  Disagree: categoriesColorScale('fii-orange-1'),
  'Strongly disagree': categoriesColorScale('fii-orange-2'),
  //
  'Very important': categoriesColorScale('fii-green-2'),
  'Somewhat important': categoriesColorScale('fii-green-1'),
  'Moderately Important': categoriesColorScale('fii-yellow'),
  'Slightly important': categoriesColorScale('fii-orange-1'),
  'Not important at all': categoriesColorScale('fii-orange-2'),
  //
  'Very concerned': categoriesColorScale('fii-green-2'),
  'Somewhat concerned': categoriesColorScale('fii-green-1'),
  'Moderately concerned': categoriesColorScale('fii-yellow'),
  'Slightly concerned': categoriesColorScale('fii-orange-1'),
  'Not at all concerned': categoriesColorScale('fii-orange-2'),
  //
  'Very valuable': categoriesColorScale('fii-green-2'),
  Valuable: categoriesColorScale('fii-green-1'),
  'Average value': categoriesColorScale('fii-yellow'),
  'Limited value': categoriesColorScale('fii-orange-1'),
  'Not valuable': categoriesColorScale('fii-orange-2'),
  //
}
