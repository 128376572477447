import { Marker } from 'react-simple-maps'
import { observer } from 'mobx-react-lite'
import { ScaleOrdinal } from 'd3-scale'
import { groupBy, maxBy } from 'lodash-es'

import type { SourceDatumMapHowSatisfiedArePeople } from '../types/map'
import { Point } from '../types/draw'
import { useMst } from '../state'
import { MapMarkerRhombus } from './MapMarkerRhombus'

interface MapMarkersMapHowSatisfiedArePeopleProps {
  dataset: SourceDatumMapHowSatisfiedArePeople[]
  colorScale: ScaleOrdinal<SourceDatumMapHowSatisfiedArePeople['answerLabel'], string, never>
}

export const MapMarkersMapHowSatisfiedArePeople = observer(
  ({ dataset, colorScale }: MapMarkersMapHowSatisfiedArePeopleProps) => {
    const {
      mapSection: { setTooltipInfo },
    } = useMst()

    const groupedByCountry = groupBy(dataset, 'country')

    const datasetByCountry = Object.entries(groupedByCountry).reduce(
      (acc, [country, countryDataset]) => {
        const category = maxBy(countryDataset, 'count')?.answerLabel
        // @ts-ignore
        acc[country] = {
          dataset: countryDataset,
          category,
        }
        return acc
      },
      {}
    )

    return (
      <>
        {/* @ts-ignore */}
        {Object.entries(datasetByCountry).map(([country, { category, dataset }]) => {
          const centroid = dataset[0].centroid
          return (
            <Marker
              key={country}
              coordinates={[centroid.longitude, centroid.latitude]}
              onMouseMove={(e) => {
                const position: Point = { x: e.clientX, y: e.clientY }
                setTooltipInfo({ position, country })
              }}
              onMouseOut={() => setTooltipInfo(null)}
            >
              <DatumPoint country={country} category={category} colorScale={colorScale} />
            </Marker>
          )
        })}
      </>
    )
  }
)

interface DatumPointProps {
  country: SourceDatumMapHowSatisfiedArePeople['country']
  category: SourceDatumMapHowSatisfiedArePeople['answerLabel']
  colorScale: ScaleOrdinal<SourceDatumMapHowSatisfiedArePeople['answerLabel'], string, never>
}

function DatumPoint({ country, category, colorScale }: DatumPointProps) {
  const fillColorClass = colorScale(category)

  return (
    <MapMarkerRhombus
      dataset={[{ id: country, value: 100, color: fillColorClass }]}
      className={`${fillColorClass} stroke-fii-white`}
      size={25}
      strokeWidth={1}
    />
  )
}
