import { Instance, flow, types as t } from 'mobx-state-tree'

import { UiModel, UiModelInstance } from './UiModel'
import { TranslationsDataModel, TranslationsDataModelInstance } from './DataTranslationsModel'
import { fetchCsvDatasetFromPublic, fetchJsonDatasetFromPublic } from '../lib/fetch'
import { createSafeContext } from '../lib/context'
import { MapSectionModel, MapSectionModelInstance } from './MapSectionModel'
import { ContextualSectionModel, ContextualSectionModelInstance } from './ContextualSectionModel'
import {
  ExplorerToolSectionModel,
  ExplorerToolSectionModelInstance,
} from './ExplorerToolSectionModel'

export const StateModel = t
  .model('StateModel', {
    ui: t.optional(UiModel, {} as UiModelInstance),
    translations: t.optional(TranslationsDataModel, {} as TranslationsDataModelInstance),
    mapSection: t.optional(MapSectionModel, {} as MapSectionModelInstance),
    contextualSection: t.optional(ContextualSectionModel, {} as ContextualSectionModelInstance),
    explorerToolSection: t.optional(
      ExplorerToolSectionModel,
      {} as ExplorerToolSectionModelInstance
    ),
  })
  .actions((self) => ({
    fetchJsonDataset: flow(function* fetchDataset<T>(dataUrl: string) {
      try {
        const dataset: T[] = yield fetchJsonDatasetFromPublic<T>(dataUrl)
        return dataset
      } catch (error) {
        console.log(`! Error: ${error}`)
        return []
      }
    }),
    fetchCsvDataset: flow(function* fetchDataset<T>(dataUrl: string) {
      try {
        const dataset: T[] = yield fetchCsvDatasetFromPublic<T>(dataUrl)
        return dataset
      } catch (error) {
        console.log(`! Error: ${error}`)
        return []
      }
    }),
  }))

export interface StateInstance extends Instance<typeof StateModel> {}

export const globalState = StateModel.create()

if (process.env.NODE_ENV === 'development') {
  Object.assign(window, { globalState })
}

const [Provider, createHook] = createSafeContext<StateInstance>('StateProvider')

export const StateProvider = Provider

export const useMst = createHook((state) => state)

export const useTranslations = createHook(({ translations }) => {
  if (!translations) {
    throw new Error('Translations store not initialized')
  }
  return translations
})

export const useUi = createHook(({ ui }) => {
  if (!ui) {
    throw new Error('Ui store not initialized')
  }
  return ui
})
