import { observer } from 'mobx-react-lite'
import { Marker } from 'react-simple-maps'
import { ScaleOrdinal } from 'd3-scale'

import type { SourceDatumMapHumanitysPriority } from '../types/map'
import { Point } from '../types/draw'
import { useMst } from '../state'
import { MapMarkerRhombus } from './MapMarkerRhombus'

interface MapMarkersMapHumanitysPriorityProps {
  dataset: SourceDatumMapHumanitysPriority[]
  colorScale: ScaleOrdinal<SourceDatumMapHumanitysPriority['percentageCategory'], string, never>
}

export const MapMarkersMapHumanitysPriority = observer(
  ({ dataset, colorScale }: MapMarkersMapHumanitysPriorityProps) => {
    const {
      mapSection: { setTooltipInfo },
    } = useMst()

    return (
      <>
        {dataset.map((datum) => {
          return (
            <Marker
              key={datum.country}
              coordinates={[datum.centroid.longitude, datum.centroid.latitude]}
              onMouseMove={(e) => {
                const position: Point = { x: e.clientX, y: e.clientY }
                setTooltipInfo({ position, country: datum.country })
              }}
              onMouseOut={() => setTooltipInfo(null)}
            >
              <DatumPoint datum={datum} colorScale={colorScale} />
            </Marker>
          )
        })}
      </>
    )
  }
)

interface DatumPointProps {
  datum: SourceDatumMapHumanitysPriority
  colorScale: ScaleOrdinal<SourceDatumMapHumanitysPriority['percentageCategory'], string, never>
}

function DatumPoint({ datum, colorScale }: DatumPointProps) {
  const fillColorClass = colorScale(datum.percentageCategory)

  return (
    <MapMarkerRhombus
      dataset={[{ id: datum.country, value: 100, color: fillColorClass }]}
      className={`${fillColorClass} stroke-fii-white`}
      size={25}
      strokeWidth={1}
    />
  )
}
