import { ScaleOrdinal } from 'd3-scale'

import { NavigatorBarchartDatum } from '../types/navigator'
import { CHART_SIZE } from '../constants/navigator'
import { formatPercentage } from '../lib/format'
import { SquareChart } from './SquareChart'

interface ExplorerToolBarchartProps {
  dataset: NavigatorBarchartDatum[]
  colorScale: ScaleOrdinal<string, string>
  description: string
  maxSumValue?: number
}

export function ExplorerToolBarchart({
  dataset,
  colorScale,
  description,
  maxSumValue,
}: ExplorerToolBarchartProps) {
  const size = CHART_SIZE

  const formattedDataset = dataset.map((d) => {
    const total = maxSumValue || d.total
    const percentage = (d.value / total) * 100
    return {
      id: d.kpi,
      value: percentage,
      category: d.kpi,
      label: formatPercentage(percentage, { minPrecision: 0, maxPrecision: 0 }),
    }
  })

  return (
    <div style={{ maxWidth: size }}>
      <SquareChart
        dataset={formattedDataset}
        colorScale={colorScale}
        maxSumValue={100}
        options={{
          width: size,
          height: size,
          stroke: {
            width: 1,
            color: colorScale.range()[0],
          },
        }}
      />
      <div className="mt-2 text-sm leading-120% line-clamp-3">{description}</div>
    </div>
  )
}
