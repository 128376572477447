import { Disclosure, Transition } from '@headlessui/react'
import { ArrowDropdown } from './Icons'
import { PriorityFilters } from './PriorityFilters'

export const PriorityFiltersMobile = () => {
  return (
    <div className="lg:hidden fixed left-0 right-0 bottom-0 max-h-[calc(100vh-75px)] bg-fii-light-bg-1 rounded-t-lg z-10">
      <Disclosure>
        {({ open }) => (
          <div className="h-full bg-fii-light-bg-1 rounded-t-lg">
            <Disclosure.Button className="flex w-full justify-between p-4 text-left text-base leading-120% focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 cursor-pointer">
              <div className="flex items-center relative">Filters</div>

              <ArrowDropdown
                className={`${!open ? '' : 'rotate-180 transform'} h-5 w-5`}
                color={'#111'}
              />
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel>
                <div className="max-h-[calc(100vh-125px)] overflow-scroll">
                  <PriorityFilters />
                </div>
              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </div>
  )
}
