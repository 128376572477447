import { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import * as _ from 'lodash-es'
import { Transition } from '@headlessui/react'

import type { Icon } from '../types/icon'
import { useMst, useTranslations } from '../state'
import { MapCategoriesIcon } from './MapCategoriesIcon'
import { PriorityIcon } from './PriorityIcon'

export const ExplorerToolCategoriesButtons = observer(() => {
  const { getTranslation } = useTranslations()
  const {
    explorerToolSection: {
      isCountry,
      navigationButtons,
      selectedNavigationButton,
      updateSelectedNavigationButton,
      initPriorityData,
    },
  } = useMst()

  return (
    <div className="flex gap-2">
      {navigationButtons.map((navigationButton) => {
        const navigationButtonName = getTranslation(navigationButton).split('—')[0]
        const isSelected = navigationButton === selectedNavigationButton
        const icon = `${_.camelCase(navigationButton)}Icon` as Icon
        const label = isCountry
          ? getTranslation(`navigatorByCountry-${_.camelCase(navigationButton)}`)
          : getTranslation(navigationButton).split('—')[1]

        return (
          <div
            key={navigationButton}
            data-id={navigationButton}
            className="h-10 flex justify-center items-center"
            onClick={() => {
              updateSelectedNavigationButton(navigationButton)
              initPriorityData()
            }}
          >
            {isCountry ? (
              <MapCategoriesIcon icon={icon} isSelected={isSelected} />
            ) : (
              <PriorityIcon label={navigationButtonName} isSelected={isSelected} />
            )}

            <Transition
              as={Fragment}
              show={isSelected}
              enter="transition ease-in duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <div>
                {isSelected && (
                  <p className="hidden sm:block md:hidden lg:block pl-2 text-sm max-w-md truncate">
                    {label}
                  </p>
                )}
              </div>
            </Transition>
          </div>
        )
      })}
    </div>
  )
})
