import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { scaleOrdinal, ScaleOrdinal } from 'd3-scale'
import { useMeasure } from '@uidotdev/usehooks'

import type {
  MapSectionId,
  SourceDatumMapAreNationsDoingEnough,
  SourceDatumMapHowSatisfiedArePeople,
  SourceDatumMapHumanitysPriority,
  SourceDatumMapSectionInfoDatum,
} from '../types/map'
import { Dimensions } from '../types/draw'
import { useMst } from '../state'
import { Map } from './Map'
import { MapMarkersMapHumanitysPriority } from './MapMarkersMapHumanitysPriority'
import { MapMarkersMapHowSatisfiedArePeople } from './MapMarkersMapHowSatisfiedArePeople'
import { MapMarkersAreNationsDoingEnough } from './MapMarkersAreNationsDoingEnough'
import { MapTooltip } from './MapTooltip'
import { MapLegend } from './MapLegend'
import { MapDescription } from './MapDescription'
import MapControlsDesktop from './MapControlsDesktop'
import MapControlsMobile from './MapControlsMobile'

type MapContent = {
  marker: JSX.Element | null
  tooltip: JSX.Element | null
  legend: JSX.Element | null
}

export const MapSection = observer(() => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const {
    mapSection: { sourceMapDataset, selectedMapSectionId, mapSectionDataset },
  } = useMst()

  const [mapContent, setMapContent] = useState<MapContent>({
    marker: null,
    tooltip: null,
    legend: null,
  })

  useEffect(() => {
    const headerNode = document.querySelector('header')
    const headerBBox = headerNode?.getBoundingClientRect()
    const headerHeight = headerBBox?.height || 0
    setHeaderHeight(headerHeight)
  }, [])

  const isLoadingData = sourceMapDataset.length === 0 || !mapSectionDataset

  const isMobile = window.innerWidth <= 768

  return (
    <div className="bg-fii-light-bg-1">
      <div className="max-w-7xl h-fit mx-auto px-4 lg:px-0 relative" style={{ maxHeight: 820 }}>
        <MapControlsMobile />
        <MapControlsDesktop />
        <div
          className="w-screen md:w-full -mx-8 md:mx-0 py-0 md:py-0"
          style={{
            height: isMobile ? 'fit-content' : `calc(100svh - ${headerHeight}px)`,
          }}
        >
          {isLoadingData ? (
            <div className="w-full h-full flex justify-center items-center">Loading data...</div>
          ) : (
            <MapContainer
              selectedMapSectionId={selectedMapSectionId}
              mapSectionDataset={mapSectionDataset}
              content={mapContent}
              setMapContent={setMapContent}
              isMobile={isMobile}
              headerHeight={headerHeight}
            />
          )}
        </div>

        <div className="block md:hidden pointer-events-none">
          {mapContent.legend}
          <MapDescription />
        </div>
      </div>
    </div>
  )
})

interface MapContainerProps {
  selectedMapSectionId: MapSectionId
  mapSectionDataset: SourceDatumMapSectionInfoDatum[]
  content: MapContent
  setMapContent: React.Dispatch<React.SetStateAction<MapContent>>
  isMobile: boolean
  headerHeight: number
}
function MapContainer({
  isMobile,
  content,
  setMapContent,
  selectedMapSectionId,
  mapSectionDataset,
  headerHeight,
}: MapContainerProps) {
  const [mapContainerRef, { width, height }] = useMeasure<HTMLDivElement>()

  useEffect(() => {
    const mapDimensions = { width, height } as Dimensions
    const mapBBox = mapContainerRef.current.getBoundingClientRect()

    const content = getMarkerAndTooltip(
      selectedMapSectionId,
      mapSectionDataset,
      mapDimensions,
      mapBBox
    )
    setMapContent(content)
  }, [selectedMapSectionId, mapSectionDataset, setMapContent, width, height, mapContainerRef])

  return (
    <div
      ref={mapContainerRef}
      className="w-full overflow-visible"
      // note: 32px is due to the py-8 of the parent
      style={{ height: isMobile ? 'fit-content' : `calc(100svh - ${headerHeight}px - ${32}px)` }}
    >
      <Map content={content} isMobile={isMobile} width={width || 0} />
    </div>
  )
}

function getMarkerAndTooltip(
  selectedMapSectionId: MapSectionId,
  mapSectionDataset: SourceDatumMapSectionInfoDatum[],
  mapContainerDimensions: Dimensions,
  mapBBox: DOMRect
): {
  marker: JSX.Element | null
  tooltip: JSX.Element | null
  legend: JSX.Element | null
} {
  if (selectedMapSectionId === 'mapHumanitysPriority') {
    const categories: SourceDatumMapHumanitysPriority['percentageCategory'][] = [
      'priority-1',
      'priority-2',
      'priority-3',
      'priority-4',
      'priority-5',
    ]

    const colorScale: ScaleOrdinal<
      SourceDatumMapHumanitysPriority['percentageCategory'],
      string,
      never
    > = scaleOrdinal(categories, [
      'fill-fii-green-2',
      'fill-fii-green-1',
      'fill-fii-yellow',
      'fill-fii-orange-1',
      'fill-fii-orange-2',
    ])
    const dataset = mapSectionDataset as SourceDatumMapHumanitysPriority[]
    return {
      marker: <MapMarkersMapHumanitysPriority dataset={dataset} colorScale={colorScale} />,
      tooltip: (
        <MapTooltip
          colorScale={colorScale as ScaleOrdinal<string, string, never>}
          mapBBox={mapBBox}
        />
      ),
      legend: (
        <MapLegend
          dataset={colorScale.domain().map((_, i) => {
            const color = colorScale.range()[i]
            return { name: `mapHumanitysPriorityLegendLabel-${i + 1}`, color }
          })}
        />
      ),
    }
  }
  if (selectedMapSectionId === 'mapHowSatisfiedArePeople') {
    const categories: SourceDatumMapHowSatisfiedArePeople['answerLabel'][] = [
      'satisfaction-1',
      'satisfaction-2',
      'satisfaction-3',
      'satisfaction-4',
      'satisfaction-5',
    ]

    const colorScale: ScaleOrdinal<
      SourceDatumMapHowSatisfiedArePeople['answerLabel'],
      string,
      never
    > = scaleOrdinal(categories, [
      'fill-fii-orange-2',
      'fill-fii-orange-1',
      'fill-fii-yellow',
      'fill-fii-green-1',
      'fill-fii-green-2',
    ])
    const dataset = mapSectionDataset as SourceDatumMapHowSatisfiedArePeople[]
    return {
      marker: <MapMarkersMapHowSatisfiedArePeople dataset={dataset} colorScale={colorScale} />,
      tooltip: (
        <MapTooltip
          colorScale={colorScale as ScaleOrdinal<string, string, never>}
          mapBBox={mapBBox}
        />
      ),
      legend: (
        <MapLegend
          dataset={colorScale.domain().map((_, i) => {
            const color = colorScale.range()[i]
            return { name: `mapHowSatisfiedArePeopleLegendLabel-${i + 1}`, color }
          })}
        />
      ),
    }
  }
  if (selectedMapSectionId === 'mapAreNationsDoingEnough') {
    const colorScale: ScaleOrdinal<string, string, never> = scaleOrdinal(
      ['countYes', 'countNo'],
      ['fill-fii-green-2', 'fill-fii-orange-2']
    )
    const dataset = mapSectionDataset as SourceDatumMapAreNationsDoingEnough[]
    return {
      marker: <MapMarkersAreNationsDoingEnough dataset={dataset} colorScale={colorScale} />,
      tooltip: <MapTooltip colorScale={colorScale} mapBBox={mapBBox} />,
      legend: (
        <MapLegend
          dataset={colorScale.domain().map((_, i) => {
            const color = colorScale.range()[i]
            return { name: `mapAreNationsDoingEnoughLegendLabel-${i + 1}`, color }
          })}
        />
      ),
    }
  }
  return { marker: null, tooltip: null, legend: null }
}
