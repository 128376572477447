const ArrowPrevIcon = () => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2746_16350)">
        <rect
          x="5"
          y="3.87695"
          width="33"
          height="32"
          rx="8"
          fill="white"
          shapeRendering="crispEdges"
        />
        <path
          d="M21.592 23.001L18.372 19.823L21.592 16.645H23.608L20.374 19.823L23.608 23.001H21.592Z"
          fill="#111111"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2746_16350"
          x="0"
          y="0.876953"
          width="41"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2746_16350" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2746_16350"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export { ArrowPrevIcon }
