export const AgeIcon = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="6.5"
        y="13.1475"
        width="39"
        height="32.5"
        rx="4.33333"
        stroke="#111111"
        strokeWidth="4.33333"
      />
      <path
        d="M6.5 21.8141C6.5 17.7286 6.5 15.6859 7.7692 14.4167C9.03841 13.1475 11.0812 13.1475 15.1667 13.1475H36.8333C40.9188 13.1475 42.9616 13.1475 44.2308 14.4167C45.5 15.6859 45.5 17.7286 45.5 21.8141H6.5Z"
        fill="#111111"
      />
      <path
        d="M15.167 6.64746L15.167 13.1475"
        stroke="#111111"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
      <path
        d="M36.833 6.64746L36.833 13.1475"
        stroke="#111111"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
      <rect x="15.167" y="26.1475" width="8.66667" height="4.33333" rx="1.08333" fill="#111111" />
      <rect x="15.167" y="34.814" width="8.66667" height="4.33333" rx="1.08333" fill="#111111" />
      <rect x="28.167" y="26.1475" width="8.66667" height="4.33333" rx="1.08333" fill="#111111" />
      <rect x="28.167" y="34.814" width="8.66667" height="4.33333" rx="1.08333" fill="#111111" />
    </svg>
  )
}

export const GenderIcon = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25.9997" cy="17.4801" r="8.66667" fill="#222222" />
      <path
        d="M13.2074 32.519C14.2664 29.8506 16.9381 28.3135 19.8089 28.3135H32.1904C35.0613 28.3135 37.733 29.8506 38.792 32.519C39.845 35.1724 40.9641 38.7867 41.1419 42.4804C41.1685 43.032 40.7186 43.4801 40.1663 43.4801H11.833C11.2807 43.4801 10.8309 43.032 10.8574 42.4804C11.0352 38.7867 12.1543 35.1724 13.2074 32.519Z"
        fill="#222222"
      />
    </svg>
  )
}

export const EducationIcon = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26 13.1475V41.3141" stroke="#33363F" strokeWidth="4.33333" strokeLinecap="round" />
      <path
        d="M45.5 13.1475L45.5 41.3141"
        stroke="#33363F"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
      <path
        d="M6.5 13.1475L6.5 41.3141"
        stroke="#33363F"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
      <path
        d="M45.5 41.3148C45.5 41.3148 43.3333 36.9814 35.75 36.9814C28.1667 36.9814 26 41.3148 26 41.3148"
        stroke="#33363F"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
      <path
        d="M26 41.3148C26 41.3148 23.8333 36.9814 16.25 36.9814C8.66667 36.9814 6.5 41.3148 6.5 41.3148"
        stroke="#33363F"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
      <path
        d="M45.5 13.1468C45.5 13.1468 43.3333 8.81348 35.75 8.81348C28.1667 8.81348 26 13.1468 26 13.1468"
        stroke="#33363F"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
      <path
        d="M26 13.1468C26 13.1468 23.8333 8.81348 16.25 8.81348C8.66667 8.81348 6.5 13.1468 6.5 13.1468"
        stroke="#33363F"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
    </svg>
  )
}

export const IncomeIcon = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.50002 14.2308V14.2308C6.50002 11.965 6.50002 10.8321 7.08068 10.0402C7.26164 9.79341 7.47929 9.57575 7.72609 9.3948C8.51801 8.81413 9.6509 8.81413 11.9167 8.81413L42.3333 8.81413C42.8047 8.81413 43.0404 8.81413 43.1869 8.96058C43.3333 9.10702 43.3333 9.34273 43.3333 9.81413V13.6475C43.3333 16.4759 43.3333 17.8901 42.4547 18.7688C41.576 19.6475 40.1618 19.6475 37.3333 19.6475L32.5 19.6475M6.50002 14.2308V14.2308C6.50002 16.4966 6.50002 17.6295 7.08068 18.4214C7.26164 18.6682 7.47929 18.8858 7.72609 19.0668C8.51801 19.6475 9.6509 19.6475 11.9167 19.6475L43.5 19.6475C44.4428 19.6475 44.9142 19.6475 45.2071 19.9404C45.5 20.2332 45.5 20.7047 45.5 21.6475L45.5 28.3141M6.50002 14.2308L6.50002 41.6475C6.50002 43.5331 6.50002 44.4759 7.0858 45.0617C7.67159 45.6475 8.6144 45.6475 10.5 45.6475L43.5 45.6475C44.4428 45.6475 44.9142 45.6475 45.2071 45.3546C45.5 45.0617 45.5 44.5903 45.5 43.6475L45.5 36.9808M45.5 36.9808H34.5C33.5572 36.9808 33.0858 36.9808 32.7929 36.6879C32.5 36.395 32.5 35.9236 32.5 34.9808V30.3141C32.5 29.3713 32.5 28.8999 32.7929 28.607C33.0858 28.3141 33.5572 28.3141 34.5 28.3141H45.5M45.5 36.9808L45.5 28.3141"
        stroke="#33363F"
        strokeWidth="4"
      />
    </svg>
  )
}

export const SocialClassIcon = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.7093 7.22884L6.73517 45.1771C6.62713 45.3932 6.78426 45.6475 7.02586 45.6475H44.9741C45.2157 45.6475 45.3729 45.3932 45.2648 45.1771L26.2907 7.22884C26.1709 6.9893 25.8291 6.9893 25.7093 7.22884Z"
        stroke="#33363F"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
      <path
        d="M41.1663 36.9814H10.833"
        stroke="#33363F"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
      <path
        d="M36.8337 28.3135H15.167"
        stroke="#33363F"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
      <path d="M32.5 19.6475H19.5" stroke="#33363F" strokeWidth="4.33333" strokeLinecap="round" />
    </svg>
  )
}

export const EmploymentStatusIcon = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 28.1673V11.9173C13 11.4066 13 11.1513 13.1587 10.9926C13.3173 10.834 13.5726 10.834 14.0833 10.834H22.4943C23.1458 10.834 23.4715 10.834 23.7333 10.9958C23.9951 11.1576 24.1408 11.4489 24.4322 12.0317L25.4011 13.9696C25.6925 14.5524 25.8382 14.8437 26.1 15.0055C26.3618 15.1673 26.6875 15.1673 27.3391 15.1673H37.9167C38.4274 15.1673 38.6827 15.1673 38.8414 15.326C39 15.4846 39 15.74 39 16.2507V31.4173C39 31.928 39 32.1834 38.8414 32.342C38.6827 32.5007 38.4274 32.5007 37.9167 32.5007H27.3391C26.6875 32.5007 26.3618 32.5007 26.1 32.3389C25.8382 32.1771 25.6925 31.8857 25.4011 31.3029L24.4322 29.365C24.1408 28.7823 23.9951 28.4909 23.7333 28.3291C23.4715 28.1673 23.1458 28.1673 22.4943 28.1673H13ZM13 28.1673V41.1673"
        stroke="#33363F"
        strokeWidth="4.33333"
        strokeLinecap="round"
      />
    </svg>
  )
}
