import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'

import { useTranslations, useUi } from '../state'

export const Menu = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()

  const { openAboutModal, openMethodologyModal, closeMenu, setShouldScrollToNavigatorToolSection } =
    useUi()
  const { getTranslation } = useTranslations()

  // setTimeout is necessary because of the transition on menu close
  // so 500ms should be the same of the menu
  const menuItems = [
    {
      name: getTranslation('menuHome'),
      onClick: () => navigate('/'),
    },
    {
      name: getTranslation('menuAbout'),
      onClick: () => {
        closeMenu()
        setTimeout(() => openAboutModal(), 500)
      },
    },
    {
      name: getTranslation('menuMethodology'),
      onClick: () => {
        closeMenu()
        setTimeout(() => openMethodologyModal(), 500)
      },
    },
    {
      name: getTranslation('menuExplorer'),
      onClick: () => {
        const fromHomepage = location.pathname === '/'
        setShouldScrollToNavigatorToolSection(true)
        closeMenu()
        if (fromHomepage) {
          return
        }
        navigate(`/`)
      },
    },
  ]

  return (
    <div className="flex flex-col xlg:flex-row items-center h-full gap-8 lg:gap-5 text-xl lg:text-sm font-medium text-fii-white">
      {menuItems.map(({ name, onClick }, i) => {
        return (
          <div
            key={i}
            className="font-poppins cursor-pointer hover:text-fii-grey-2"
            onClick={onClick}
          >
            {name}
          </div>
        )
      })}
    </div>
  )
})
