const JordanIcon = () => {
  return (
    <svg
      width="127"
      height="126"
      viewBox="0 0 127 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M97.0895 44.9069L94.6745 47.4404L94.069 47.705L90.8411 48.7632L84.1378 50.9167L79.6881 52.3519L73.9576 54.2016L69.2011 55.7488L64.5038 57.2578L60.1721 58.6531L62.7786 61.6308L66.8782 66.15L69.5496 69.1603L72.7549 73.0331L75.6331 76.4895L78.6435 80.1308L76.7144 81.3689L73.4565 83.4318L73.1628 83.8068L72.869 84.1817L71.6613 87.8169L70.6687 90.7004L70.3727 91.0374L65.7653 92.0855L61.0969 93.1707L58.1458 93.8068L57.308 94.5551L55.5331 98.1446L53.6971 101.806L50.4681 104.831L46.8758 108.151L46.0155 108.375L43.3248 107.853L38.6965 106.995L34.2546 106.137L31.1889 105.54L27.5 104.868L27.948 102.03L27.7034 100.536L28.375 95.5274L28.7778 93.1707L29.0185 91.4118L30.1178 87.8918L29.885 86.7303L30.0302 82.6443L29.8743 81.8567L30.3426 79.6429L31.2629 76.3768L32.2017 73.5968L32.5842 72.3565L33.527 69.6869L34.3829 66.4511L33.7519 64.6818L33.6123 64.3052L33.8982 62.2712L34.2524 58.917L34.423 57.107L34.8789 54.73L35.8437 52.6917L35.1449 47.9318L35.0958 45.3608L35.6483 42.4098L35.1274 38.9645L35.2229 34.0758L35.2647 33.6208L35.6087 33.0139L35.8426 32.7104L37.9593 31.6862L38.8191 31.5724L40.6923 32.0277L41.7877 33.0518L43.709 35.9713L46.4651 36.8051L47.6142 37.6387L49.227 39.1539L51.1064 39.722L56.9589 40.6686L61.4482 37.4492L65.2156 34.7203L69.5818 31.6103L72.5086 29.4851L77.464 25.8774L80.8128 23.5972L85.1123 20.5931L89.4113 17.625L90.8812 22.4566L92.285 27.2069L93.7571 32.1036L95.2208 36.843L93.9452 37.2976L95.1078 40.9337L96.811 40.3657L98.5837 39.9492L99.5 42.2963L97.0895 44.9069Z"
        fill="#3A6600"
      />
    </svg>
  )
}

export { JordanIcon }
