import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { observer } from 'mobx-react-lite'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'

import { useMst, useTranslations } from '../state'
import { MapSectionId } from '../types/map'

export const MapSectionDropdown = observer(() => {
  const { getTranslation } = useTranslations()
  const {
    mapSection: { sourceMapDataset, selectedMapSectionId, setSelectedMapSection },
  } = useMst()

  const mapSectionIds = sourceMapDataset.map((d) => d.id) as MapSectionId[]

  const mapSections = mapSectionIds.map((mapSectionId) => {
    return {
      id: mapSectionId,
      value: mapSectionId,
      label: getTranslation(`dropdown-${mapSectionId}`),
    }
  })

  const selected = mapSections.find((mapSection) => {
    return mapSection.id === selectedMapSectionId
  })

  return (
    <Listbox value={selected} onChange={(e) => setSelectedMapSection(e.value)}>
      <div className="relative">
        <Listbox.Button className="relative w-full h-10 flex items-center rounded-lg bg-fii-green-4 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm cursor-pointer">
          {selected && <span className="block truncate leading-120% mt-1">{selected.label}</span>}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon className="h-5 w-5 text-fii-grey-2" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 xl:mb-1 xl:bottom-full max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {mapSections.map((section) => (
              <Listbox.Option
                key={section.id}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2 pl-2 pr-4 ${
                    active ? 'bg-fii-green-4 text-fii-black' : 'text-gray-900'
                  }`
                }
                value={section}
              >
                {({ selected }) => (
                  <span
                    className={`block text-sm leading-120% truncate ${
                      selected ? 'font-medium' : 'font-normal'
                    }`}
                  >
                    {section.label}
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
})
