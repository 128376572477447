import MapCategoriesButtons from './MapCategoriesButtons'
import { MapDescription } from './MapDescription'
import { MapSectionDropdown } from './MapSectionDropdown'

const MapControlsDesktop = () => {
  return (
    <div className="hidden md:block absolute left-0 bottom-8 pl-4">
      <div className="grid grid-cols-12 gap-8 items-end">
        <div className="col-start-1 col-span-3 z-10 pointer-events-none">
          <MapDescription />
        </div>
        <div className="col-start-4 col-span-3 h-fit z-10">
          <MapSectionDropdown />
        </div>
        <div className="col-start-7 col-span-6 flex z-10">
          <MapCategoriesButtons />
        </div>
      </div>
    </div>
  )
}

export default MapControlsDesktop
