import type { Icon } from '../types/icon'
import { getCategoryIcon } from '../lib/icons'

export const MapCategoriesIcon = ({
  icon,
  isSelected,
  onClick,
}: {
  icon: Icon
  isSelected: boolean
  onClick?: () => void
}) => {
  const CategoryIcon = getCategoryIcon(icon, isSelected, 'map')

  return (
    <div
      className={`min-w-[40px] h-full ${
        isSelected ? 'bg-fii-green-1' : 'bg-fii-grey-1'
      } hover:bg-opacity-70 cursor-pointer
      rounded-[5px] shadow-md p-1 flex justify-center items-center`}
      onClick={onClick}
    >
      <CategoryIcon />
    </div>
  )
}
