import { observer } from 'mobx-react-lite'

import { useMst, useTranslations } from '../state'
import MapCategoriesButtons from './MapCategoriesButtons'
import { MapSectionDropdown } from './MapSectionDropdown'

const MapControlsMobile = observer(() => {
  const {
    mapSection: { selectedMapQuestionCategoryIndex, mapSectionQuestionCategories },
  } = useMst()
  const { getTranslation } = useTranslations()

  const studyArea = mapSectionQuestionCategories[selectedMapQuestionCategoryIndex]

  return (
    <div className="block pt-4 md:hidden">
      <MapSectionDropdown />
      <div className="flex w-full justify-center">
        <MapCategoriesButtons />
      </div>

      <div className="sm:hidden flex justify-center text-fii-grey-2 text-xs">
        {getTranslation(studyArea)}
      </div>
    </div>
  )
})

export default MapControlsMobile
