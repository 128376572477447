import { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { Dialog, Transition } from '@headlessui/react'

import { useUi } from '../state'
import { Menu } from './Menu'
import { AcnLogo, FiiInstituteLogo } from './Logo'

export const DrawerMobile = observer(() => {
  const { isMenuOpen, closeMenu } = useUi()

  return (
    <Transition show={isMenuOpen} as={Fragment}>
      <Dialog className="relative xl:hidden" onClose={closeMenu}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 top-18 bg-fii-black z-10" aria-hidden="true" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="-translate-y-full"
          enterTo="delay-450 translate-y-0"
          leave="ease-in duration-500"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full delay-450"
        >
          <Dialog.Panel className="fixed inset-0 top-18 pb-14 overflow-y-auto text-fii-white h-full z-20">
            <div className="absolute top-5 right-5 cursor-pointer" />
            <div className="px-4 py-8 flex flex-col items-center justify-between h-full bg-fii-black">
              <Menu />

              <div className="flex flex-col gap-y-4">
                <div className="flex items-center flex-col gap-y-3">
                  <div className="text-xs">Powered by</div>
                  <a href="https://fii-institute.org/about/" target="_blank" rel="noreferrer">
                    <FiiInstituteLogo className="max-h-[40px]" />
                  </a>
                </div>

                <div className="flex flex-col gap-y-3 items-center text-white mt-4 mb-8">
                  <span className="text-xs">In partnership with</span>
                  <a href="https://www.accenture.com/" target="_blank" rel="noreferrer">
                    <AcnLogo className="max-h-[21px]" />
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
})
