import { observer } from 'mobx-react-lite'

import { useTranslations } from '../state'
import { insightsCardsDataset } from '../constants/insightsCardsDataset'
import { WidgetInsights } from './WidgetInsights'

export const InsightsSection = observer(() => {
  const { getTranslation } = useTranslations()

  return (
    <div className="py-16 bg-fii-light-bg-2">
      <div className="max-w-[1500px] xl:max-w-none mx-auto">
        <div className="flex flex-col md:items-center gap-5 mb-8 px-4">
          <h2 className="text-5.5xl leading-120%">{getTranslation('insightsSectionTitle')}</h2>
          <p className="text-base leading-120%">{getTranslation('insightsSectionDescription')}</p>
        </div>
        <WidgetInsights cards={insightsCardsDataset} />
      </div>
    </div>
  )
})
