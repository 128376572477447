import { Dimensions, Point } from '../types/draw'

/**
 * Create path in clockwise order: from A -> B -> C -> ... -> L -> A.
 *
 *    startPoint
 *        *  L      startPointTopHalf = A        B
 *        ╭*------------------*->----------------*╮
 *      H *                                        * C
 *        |                                        |
 *        |                                        |
 *      G *                                        * D
 *        ╰*-<-----------------------------------*╯
 *          F                                      E
 */
export interface Angles {
  tl: number
  tr: number
  br: number
  bl: number
}

export function getRoundedBarPath({
  dimensions,
  startPoint = { x: 0, y: 0 },
  angles = {
    tl: 0,
    tr: 0,
    br: 0,
    bl: 0,
  },
}: {
  dimensions: Dimensions
  startPoint: Point
  angles: Angles
}): string {
  const { x, y } = startPoint
  const { width, height } = dimensions
  const startPointTopHalf = { x: x + width / 2, y }

  const path = `
    M ${startPointTopHalf.x} ${startPointTopHalf.y}

    L ${startPointTopHalf.x + width / 2 - angles.tr} ${startPointTopHalf.y}
    A ${angles.tr} ${angles.tr} 0 0 1 ${startPointTopHalf.x + width / 2} ${
      startPointTopHalf.y + angles.tr
    }

    L ${startPointTopHalf.x + width / 2} ${startPointTopHalf.y + height - angles.br}
    A ${angles.br} ${angles.br} 0 0 1 ${startPointTopHalf.x + width / 2 - angles.br} ${
      startPointTopHalf.y + height
    }

    L ${startPointTopHalf.x - width / 2 + angles.bl} ${startPointTopHalf.y + height}
    A ${angles.bl} ${angles.bl} 0 0 1 ${startPointTopHalf.x - width / 2} ${
      startPointTopHalf.y + height - angles.bl
    }

    L ${startPointTopHalf.x - width / 2} ${startPointTopHalf.y + angles.tl}
    A ${angles.tl} ${angles.tl} 0 0 1 ${startPointTopHalf.x - width / 2 + angles.tl} ${
      startPointTopHalf.y
    }
    
    Z
  `
  return path
}
