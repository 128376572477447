import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMeasure } from '@uidotdev/usehooks'
import { ScaleOrdinal } from 'd3-scale'
import * as _ from 'lodash-es'

import type {
  MapSectionId,
  MapTooltip as MapTooltipType,
  SourceDatumMapAreNationsDoingEnough,
  SourceDatumMapHowSatisfiedArePeople,
  SourceDatumMapHumanitysPriority,
  SourceDatumMapSectionInfoDatum,
} from '../types/map'
import { useMst, useTranslations } from '../state'
import { formatPercentage } from '../lib/format'

const TOOLTIP_OFFSET = 10

interface MapTooltipProps {
  colorScale: ScaleOrdinal<string, string, never>
  mapBBox: DOMRect
}

export const MapTooltip = observer(({ colorScale, mapBBox }: MapTooltipProps) => {
  const {
    mapSection: { mapSectionDataset, tooltipInfo, selectedMapSectionId },
  } = useMst()

  const [left, setLeft] = useState(0)
  const [top, setTop] = useState(0)

  if (!tooltipInfo || !mapSectionDataset) {
    return null
  }

  const countryData = mapSectionDataset.filter((d) => d.country === tooltipInfo.country)

  return (
    <div
      className="absolute rounded-md-lg bg-fii-white text-fii-black z-20 pointer-events-none"
      style={{
        top: top,
        left: left,
        boxShadow: '-1px 1px 4px 0px rgba(0, 0, 0, 0.15)',
      }}
    >
      <MapTooltipContentWrapper
        selectedMapSectionId={selectedMapSectionId}
        tooltipInfo={tooltipInfo}
        countryData={countryData}
        setLeft={setLeft}
        setTop={(value: number) => setTop(value - mapBBox.top)}
        colorScale={colorScale}
        mapBBox={mapBBox}
      />
    </div>
  )
})

interface MapTooltipContentWrapperProps {
  selectedMapSectionId: MapSectionId
  tooltipInfo: MapTooltipType
  countryData?: SourceDatumMapSectionInfoDatum[]
  setTop: (value: number) => void
  setLeft: React.Dispatch<React.SetStateAction<number>>
  colorScale: ScaleOrdinal<string, string, never>
  mapBBox: DOMRect
}

const MapTooltipContentWrapper = ({
  selectedMapSectionId,
  tooltipInfo,
  countryData,
  setTop,
  setLeft,
  colorScale,
  mapBBox,
}: MapTooltipContentWrapperProps) => {
  const [contentRef, { width: tooltipWidthTmp, height: tooltipHeightTmp }] =
    useMeasure<HTMLDivElement>()

  const updateTooltipPosition = useCallback(() => {
    if (!contentRef.current) {
      return
    }

    const tooltipWidth = tooltipWidthTmp || 0
    const tooltipHeight = tooltipHeightTmp || 0

    const x = tooltipInfo.position.x - mapBBox.x + TOOLTIP_OFFSET
    const y = tooltipInfo.position.y + TOOLTIP_OFFSET

    if (x + tooltipWidth > mapBBox.width / 2) {
      setLeft(x - tooltipWidth - TOOLTIP_OFFSET)
    } else {
      setLeft(x)
    }
    if (y + tooltipHeight > mapBBox.height / 2) {
      setTop(y - tooltipHeight - TOOLTIP_OFFSET)
    } else {
      setTop(y)
    }
  }, [
    contentRef,
    tooltipWidthTmp,
    tooltipHeightTmp,
    mapBBox,
    tooltipInfo.position.x,
    tooltipInfo.position.y,
    setLeft,
    setTop,
  ])

  useEffect(() => {
    updateTooltipPosition()
    window.addEventListener('resize', updateTooltipPosition)
    return () => {
      window.removeEventListener('resize', updateTooltipPosition)
    }
  }, [updateTooltipPosition])

  return (
    <div className="max-w-xs" ref={contentRef}>
      <div className="p-4">
        <div className="mb-2">{tooltipInfo.country}</div>
        {countryData && countryData.length > 0 && (
          <div>
            <MapTooltipContent
              selectedMapSectionId={selectedMapSectionId}
              countryData={countryData}
              colorScale={colorScale}
            />
          </div>
        )}
      </div>
    </div>
  )
}

interface MapTooltipContentProps {
  selectedMapSectionId: MapSectionId
  countryData: SourceDatumMapSectionInfoDatum[]
  colorScale: ScaleOrdinal<string, string, never>
}

const MapTooltipContent = observer(
  ({ selectedMapSectionId, countryData, colorScale }: MapTooltipContentProps) => {
    const { getTranslation } = useTranslations()

    if (selectedMapSectionId === 'mapHumanitysPriority') {
      const datum = countryData[0] as SourceDatumMapHumanitysPriority
      const color = colorScale(datum.percentageCategory)

      return (
        <div className="flex items-center">
          <div className="w-[20px]">
            <TooltipCircle fillColorClass={color} />
          </div>
          <div className="ml-1">{formatPercentage(datum.percentage)}</div>
        </div>
      )
    }

    if (selectedMapSectionId === 'mapHowSatisfiedArePeople') {
      const data = countryData as SourceDatumMapHowSatisfiedArePeople[]
      const sortedData = _.sortBy(data, 'answerLabel')
      const total = _.sumBy(sortedData, 'count')

      return (
        <div>
          {sortedData.map((datum) => {
            const label = getTranslation(datum.answerLabel)
            const color = colorScale(datum.answerLabel)
            const percentage = (datum.count / total) * 100

            return (
              <div key={datum.answerLabel} className="flex justify-between gap-x-4 items-start">
                <div className="flex items-start">
                  <div className="w-[20px] flex-shrink-0">
                    <TooltipCircle fillColorClass={color} />
                  </div>
                  <div className="ml-1">{label}</div>
                </div>
                <div className="flex-shrink-0">{formatPercentage(percentage)}</div>
              </div>
            )
          })}
        </div>
      )
    }

    if (selectedMapSectionId === 'mapAreNationsDoingEnough') {
      const datum = countryData[0] as SourceDatumMapAreNationsDoingEnough
      const total = datum['countYes'] + datum['countNo']

      return (
        <div>
          {['countYes', 'countNo'].map((category) => {
            const label = getTranslation(_.camelCase(`map ${category}`))
            const color = colorScale(category)
            // @ts-ignore
            const value = datum[category]
            const percentage = (value / total) * 100

            return (
              <div key={category} className="flex justify-between gap-x-4 items-center">
                <div className="flex items-center">
                  <div className="w-[20px] flex-shrink-0">
                    <TooltipCircle fillColorClass={color} />
                  </div>
                  <div className="ml-1">{label}</div>
                </div>
                <div className="flex-shrink-0">{formatPercentage(percentage)}</div>
              </div>
            )
          })}
        </div>
      )
    }

    return null
  }
)

function TooltipCircle({ fillColorClass }: { fillColorClass: string }) {
  return (
    <svg x={0} y={0} width={14} height={14} viewBox="0 0 14 14">
      <circle className={`${fillColorClass}`} cx={7} cy={7} r={7} />
    </svg>
  )
}
