import * as _ from 'lodash-es'
import { ScaleOrdinal, scaleLinear } from 'd3-scale'
import { stackNarrow } from 'react-composable-charts'

import { getRoundedBarPath } from '../lib/draw'
import { sortByKeyBasedOnSortedArray } from '../lib/array'

const LABEL_HEIGHT = 24
const LABEL_PADDING = 2

// copied and pasted from react-composable-charts
type Stack<T> = {
  datum: T
  to: number
  base: number
  group: string
  category: string | number
  value: number
}

type StackWithLabelY<T> = Stack<T> & {
  labelY: number
}

type Options = {
  width?: number
  height?: number
  stroke?: {
    width?: number
    color?: string
  }
  labels?: {
    toShow?: boolean
    leftPadding?: number
    topPadding?: number
  }
  angles?: {
    bl?: number
    br?: number
    tl?: number
    tr?: number
  }
  categoriesOrder?: string[]
}

interface SquareChartProps<Datum> {
  dataset: Datum[]
  colorScale: ScaleOrdinal<string, string>
  maxSumValue?: number
  options?: Options
}

function getDefaultOptions() {
  return {
    width: 120,
    height: 120,
    stroke: {
      width: 2,
      color: 'white',
    },
    labels: {
      toShow: true,
      leftPadding: 10,
      topPadding: 10,
    },
    angles: { bl: 0, br: 40, tl: 40, tr: 0 },
  }
}

export function SquareChart<
  Datum extends { id: string; category: string; value: number; label: string },
>({
  dataset,
  colorScale,
  maxSumValue: maybeMaxSumValue,
  options: temporaryOptions,
}: SquareChartProps<Datum>) {
  if (dataset.length === 0) {
    console.warn('Dataset is empty')
    return null
  }

  const defaultOptions = getDefaultOptions()
  const options = _.merge(defaultOptions, temporaryOptions)
  const { width, height, stroke, labels, categoriesOrder } = options

  const x = 0
  const y = 0

  const rhombusPath = getRoundedBarPath({
    dimensions: { width, height },
    startPoint: { x: 0, y: 0 },
    angles: options.angles,
  })
  const rhombusStrokePath = getRoundedBarPath({
    dimensions: { width: width - stroke.width, height: height - stroke.width },
    startPoint: { x: 0 + stroke.width / 2, y: 0 + stroke.width / 2 },
    angles: options.angles,
  })

  const datasetSumValue = _.sumBy(dataset, 'value')

  const maxSumValue = maybeMaxSumValue || datasetSumValue
  const summedDatasetValue = _.sumBy(dataset, 'value')

  const unsortedCategories = dataset.map((d) => d.category)
  const categories = categoriesOrder || unsortedCategories

  const stackedData = stackNarrow({
    data: sortByKeyBasedOnSortedArray(dataset, 'category', categories),
    categories,
    getCategory: (d) => d.category,
    getGroup: () => 'none',
    getValue: (d) => d.value,
  })

  const bandScale = scaleLinear([0, maxSumValue], [0, height])

  const labelsInfo = getLabelsPositionToAvoidOverlapping<Datum>(stackedData, height)

  const clipPathId = `clip-id-${Math.random()}`

  return (
    <div className="relative" style={{ width, height }}>
      <svg x={x} y={y} width={width} height={height} className="overflow-visible">
        <defs>
          <clipPath id={clipPathId}>
            <path d={rhombusPath} fill="transparent" stroke="black" strokeWidth={1} />
          </clipPath>
        </defs>

        <path
          d={rhombusStrokePath}
          fill="transparent"
          stroke={stroke.color}
          strokeWidth={stroke.width}
        />
        <g clipPath={`url(#${clipPathId})`}>
          <g>
            {stackedData.map((stackedDatum) => {
              const { base, to, datum } = stackedDatum
              const color = colorScale(datum.category)

              return (
                <rect
                  key={`${Math.random()}-${datum.id}`}
                  data-id={datum.id}
                  x={x}
                  y={y + bandScale(base) + (height - bandScale(summedDatasetValue))}
                  width={width}
                  height={bandScale(to) - bandScale(base)}
                  fill={color}
                  fillOpacity={1}
                />
              )
            })}
          </g>
        </g>
      </svg>

      {labels.toShow &&
        labelsInfo.map((rect, i) => {
          const color = colorScale(rect.datum.category)

          return (
            <div
              key={i}
              className="absolute pointer-events-none bg-fii-white flex items-center"
              style={{
                top: rect.labelY,
                right: `calc(0px - ${stroke.width}px)`,
                height: LABEL_HEIGHT,
                width: 44,
              }}
            >
              <div style={{ backgroundColor: color, width: 6, height: 12 }} />
              <div className="text-sm font-stratum text-fii-black ml-1">{rect.datum.label}</div>
            </div>
          )
        })}
    </div>
  )
}

function getLabelsPositionToAvoidOverlapping<T>(rectsInfo: Stack<T>[], chartHeight: number) {
  const labelsInfo: Array<StackWithLabelY<T>> = rectsInfo.map((info, i) => ({
    ...info,
    labelY: i * (LABEL_HEIGHT + LABEL_PADDING),
  }))

  return labelsInfo

  // const labelsInfo: Array<StackWithLabelY<T>> = rectsInfo
  //   .map((info) => ({ ...info, labelY: info.to }))
  //   .filter((info) => Math.abs(info.base - info.to) !== 0)

  // // from bottom label, move up to avoid overlap
  // // at the end, the top label could be outside available space
  // const labelsMovedBottomToTop = labelsInfo.reduce<Array<StackWithLabelY<T>>>((acc, currRect) => {
  //   const prevRect = _.last(acc)
  //   const maxY = prevRect
  //     ? prevRect.labelY - LABEL_HEIGHT - LABEL_PADDING
  //     : chartHeight - LABEL_HEIGHT
  //   const labelY = Math.min(maxY, currRect.labelY)
  //   acc.push({ ...currRect, labelY })
  //   return acc
  // }, [])

  // // from top label, move down to avoid overlap
  // const labelsMovedTopToBottom = labelsMovedBottomToTop.reduce<Array<StackWithLabelY<T>>>(
  //   (acc, currRect) => {
  //     const nextRect = _.last(acc)
  //     const minY = nextRect ? nextRect.labelY + LABEL_HEIGHT + LABEL_PADDING : 0
  //     const labelY = Math.max(minY, currRect.labelY)
  //     acc.push({ ...currRect, labelY })
  //     return acc
  //   },
  //   []
  // )
  // return labelsMovedTopToBottom
}
