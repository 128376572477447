import { Marker } from 'react-simple-maps'
import { observer } from 'mobx-react-lite'
import { ScaleOrdinal } from 'd3-scale'

import type { SourceDatumMapAreNationsDoingEnough } from '../types/map'
import type { Point } from '../types/draw'
import { useMst } from '../state'
import { MapMarkerRhombus } from './MapMarkerRhombus'

interface MapMarkersAreNationsDoingEnoughProps {
  dataset: SourceDatumMapAreNationsDoingEnough[]
  colorScale: ScaleOrdinal<string, string, never>
}

export const MapMarkersAreNationsDoingEnough = observer(
  ({ dataset, colorScale }: MapMarkersAreNationsDoingEnoughProps) => {
    const {
      mapSection: { setTooltipInfo },
    } = useMst()

    return (
      <>
        {dataset.map((datum) => {
          const countryDataset = [
            {
              id: `${datum.country}-countYes`,
              value: datum.countYes,
              color: colorScale('countYes'),
            },
            { id: `${datum.country}-countNo`, value: datum.countNo, color: colorScale('countNo') },
          ]

          return (
            <Marker
              key={datum.country}
              coordinates={[datum.centroid.longitude, datum.centroid.latitude]}
              onMouseMove={(e) => {
                const position: Point = { x: e.clientX, y: e.clientY }
                setTooltipInfo({ position, country: datum.country })
              }}
              onMouseOut={() => setTooltipInfo(null)}
            >
              <DatumPoint dataset={countryDataset} />
            </Marker>
          )
        })}
      </>
    )
  }
)

interface DatumPointProps {
  dataset: {
    id: string
    value: number
    color: string
  }[]
}

function DatumPoint({ dataset }: DatumPointProps) {
  return <MapMarkerRhombus dataset={dataset} size={25} strokeWidth={1} />
}
