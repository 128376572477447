import { csvToJson } from './parse'

export type FetchDatasetOptions<T> = {
  columnNamesWithDynamicTyping: Array<keyof T>
}

export async function fetchJsonDatasetFromPublic<T>(path: string) {
  try {
    const url = `/${path}`

    const response = await fetch(url)

    if (!/^2/.test(response.status.toString())) {
      console.log('Fetch status:', response.status)
      return []
    }
    const json = await response.json()

    return json as T[]
  } catch (error) {
    console.log(`! Fetch error: ${error}`)
    return []
  }
}

export async function fetchCsvDatasetFromPublic<T>(
  path: string,
  options: FetchDatasetOptions<T> = { columnNamesWithDynamicTyping: [] }
) {
  try {
    const url = `/${path}`

    const response = await fetch(url)

    if (!/^2/.test(response.status.toString())) {
      console.log('Fetch status:', response.status)
      return []
    }
    const text = await response.text()

    const json = csvToJson(text, options)

    return json as T[]
  } catch (error) {
    console.log(`! Fetch error: ${error}`)
    return []
  }
}
