import { observer } from 'mobx-react-lite'
import * as _ from 'lodash-es'
import { scaleOrdinal, ScaleOrdinal } from 'd3-scale'

import type { NavigatorBarchartDatum, NavigatorStackedBarchartDatum } from '../types/navigator'
import { useMst, useTranslations } from '../state'
import { sortArrayOfArraysByKeyBasedOnAnother } from '../lib/array'
import { COUNTRIES_INFO_BY_REGION, REGIONS_ORDER } from '../constants/geo'
import { ExplorerToolBarchart } from './ExplorerToolBarchart'
import { ExplorerToolStackedBarchart } from './ExplorerToolStackedBarchart'
import { ExplorerToolGroupByButtons } from './ExplorerToolGroupByButtons'
import { CHART_SIZE } from '../constants/navigator'

export const ExplorerToolPagePriorityContent = observer(() => {
  const {
    ui: { isLoading },
    explorerToolSection: { priorityFilteredDataset },
  } = useMst()
  const { getTranslation } = useTranslations()

  const { questionPrefix, chartType, questionId } = priorityFilteredDataset
  const questionPrefixTitle = getTranslation(questionPrefix)
  const questionIdTitle = getTranslation(questionId)

  if (isLoading) {
    return <div className="w-full h-full flex justify-center items-center">Loading data...</div>
  }

  return (
    <div className="mt-8 mb-28">
      <h2 className="text-fii-grey-2 pb-2 leading-8">{questionPrefixTitle}</h2>
      <h2 className="text-xl border-b pb-2 mb-3 leading-8">{questionIdTitle}</h2>

      <div className="z-0">
        {_.isEmpty(priorityFilteredDataset) && <div>No data</div>}
        {chartType === 'stacked-barchart' && <ExplorerToolPagePriorityContentRowStackedBarchart />}
        {chartType === 'barchart' && <ExplorerToolPagePriorityContentRowBarchart />}
      </div>
    </div>
  )
})

const ExplorerToolPagePriorityContentRowBarchart = observer(() => {
  const {
    explorerToolSection: {
      priorityFilteredDataset,
      priorityDatasetMaxSumValuesByCountry,
      priorityGroupedBy,
    },
  } = useMst()

  const { dataset, kpisOrder, kpiColor } = priorityFilteredDataset

  const datasetGroupedByRegions = Object.entries(COUNTRIES_INFO_BY_REGION).reduce<
    Record<string, Record<string, NavigatorBarchartDatum[] | NavigatorStackedBarchartDatum[]>>
  >((acc, [region, regionCountries]) => {
    const countries = regionCountries.map((d) => d.countryName)
    acc[region] = _.pick(dataset, countries)
    return acc
  }, {})

  const sortedDatasetGroupedByRegions = sortArrayOfArraysByKeyBasedOnAnother(
    Object.entries(datasetGroupedByRegions),
    REGIONS_ORDER
  )

  const sortedDatasetGroupedByCountry = sortArrayOfArraysByKeyBasedOnAnother(
    Object.entries(dataset),
    kpisOrder
  )

  return (
    <>
      <ExplorerToolGroupByButtons className="mt-2" />

      <div className="flex flex-col gap-y-16 mt-8">
        {priorityGroupedBy === 'regions' &&
          sortedDatasetGroupedByRegions.map(([region, regionDataset]) => {
            const sortedRegionDataset = sortArrayOfArraysByKeyBasedOnAnother(
              Object.entries(regionDataset),
              kpisOrder
            )
            return (
              <div key={region} className="">
                <div className="border-b pb-2 mb-8 leading-120%">{region}</div>
                <CountriesBarchart
                  dataset={sortedRegionDataset}
                  priorityDatasetMaxSumValuesByCountry={priorityDatasetMaxSumValuesByCountry}
                  kpiColor={kpiColor!}
                />
              </div>
            )
          })}
        {priorityGroupedBy === 'countries' && (
          <CountriesBarchart
            dataset={sortedDatasetGroupedByCountry}
            priorityDatasetMaxSumValuesByCountry={priorityDatasetMaxSumValuesByCountry}
            kpiColor={kpiColor!}
          />
        )}
      </div>
    </>
  )
})

interface CountriesBarchartProps {
  dataset: [string, NavigatorBarchartDatum[] | NavigatorStackedBarchartDatum[]][]
  priorityDatasetMaxSumValuesByCountry: Record<string, number>
  kpiColor: string
}
function CountriesBarchart({
  dataset,
  priorityDatasetMaxSumValuesByCountry,
  kpiColor,
}: CountriesBarchartProps) {
  return (
    <div className="block w-full my-0 mx-auto xl:mx-0 overflow-hidden">
      <div
        className="grid justify-center lg:justify-start"
        style={{
          gridTemplateColumns: `repeat(auto-fit, ${CHART_SIZE}px)`,
          gridAutoRows: 'auto 1fr',
          gridRowGap: 20,
          gridColumnGap: 32,
        }}
      >
        {dataset.map(([country, countryDataset]) => (
          <ExplorerToolBarchart
            key={country}
            dataset={countryDataset as NavigatorBarchartDatum[]}
            colorScale={scaleOrdinal([countryDataset[0].kpi], [kpiColor!])}
            description={country}
            maxSumValue={priorityDatasetMaxSumValuesByCountry[country]}
          />
        ))}
      </div>
    </div>
  )
}

const ExplorerToolPagePriorityContentRowStackedBarchart = observer(() => {
  const {
    explorerToolSection: {
      priorityFilteredDataset,
      priorityDatasetMaxSumValuesByCountry,
      priorityGroupedBy,
    },
  } = useMst()

  const { dataset, categoriesOrder, kpisOrder, categoriesColors } = priorityFilteredDataset

  const datasetGroupedByRegions = Object.entries(COUNTRIES_INFO_BY_REGION).reduce<
    Record<string, Record<string, NavigatorBarchartDatum[] | NavigatorStackedBarchartDatum[]>>
  >((acc, [region, regionCountries]) => {
    const countries = regionCountries.map((d) => d.countryName)
    acc[region] = _.pick(dataset, countries)
    return acc
  }, {})

  const sortedDatasetGroupedByRegions = sortArrayOfArraysByKeyBasedOnAnother(
    Object.entries(datasetGroupedByRegions),
    REGIONS_ORDER
  )

  const sortedDatasetGroupedByCountry = sortArrayOfArraysByKeyBasedOnAnother(
    Object.entries(dataset),
    kpisOrder
  )

  const colorScale = scaleOrdinal(Object.keys(categoriesColors!), Object.values(categoriesColors!))

  return (
    <div className="flex flex-col">
      {/* legend */}
      <div className="flex flex-wrap gap-x-2 mb-2">
        {_.cloneDeep(categoriesOrder)
          ?.reverse()
          .map((category) => {
            return (
              <div key={category} className="flex gap-x-1 items-center">
                <div
                  className="w-4 h-4 rounded-[5px]"
                  style={{ backgroundColor: colorScale(category) }}
                />
                <div className="mt-1 text-sm">{category}</div>
              </div>
            )
          })}
      </div>

      <ExplorerToolGroupByButtons className="mt-2 mb-8" />

      <div className="flex flex-col gap-y-16 mt-8">
        {priorityGroupedBy === 'regions' &&
          sortedDatasetGroupedByRegions.map(([region, regionDataset]) => {
            const sortedRegionDataset = sortArrayOfArraysByKeyBasedOnAnother(
              Object.entries(regionDataset),
              kpisOrder
            )
            return (
              <div key={region} className="">
                <div className="border-b pb-2 mb-8 leading-120%">{region}</div>
                <CountriesStackedBarchart
                  dataset={sortedRegionDataset}
                  priorityDatasetMaxSumValuesByCountry={priorityDatasetMaxSumValuesByCountry}
                  colorScale={colorScale}
                  categoriesOrder={categoriesOrder!}
                />
              </div>
            )
          })}

        {priorityGroupedBy === 'countries' && (
          <CountriesStackedBarchart
            dataset={sortedDatasetGroupedByCountry}
            priorityDatasetMaxSumValuesByCountry={priorityDatasetMaxSumValuesByCountry}
            colorScale={colorScale}
            categoriesOrder={categoriesOrder!}
          />
        )}
      </div>
    </div>
  )
})

interface CountriesStackedBarchartProps {
  dataset: [string, NavigatorBarchartDatum[] | NavigatorStackedBarchartDatum[]][]
  priorityDatasetMaxSumValuesByCountry: Record<string, number>
  colorScale: ScaleOrdinal<string, string>
  categoriesOrder: string[]
}
function CountriesStackedBarchart({
  dataset,
  priorityDatasetMaxSumValuesByCountry,
  colorScale,
  categoriesOrder,
}: CountriesStackedBarchartProps) {
  return (
    <div className="block w-full my-0 mx-auto xl:mx-0 overflow-hidden">
      <div
        className="grid justify-center lg:justify-start"
        style={{
          gridTemplateColumns: `repeat(auto-fit, ${CHART_SIZE}px)`,
          gridAutoRows: 'auto 1fr',
          gridRowGap: 20,
          gridColumnGap: 32,
        }}
      >
        {dataset.map(([country, datasetOfQuestionId]) => {
          return (
            <ExplorerToolStackedBarchart
              key={country}
              dataset={datasetOfQuestionId as NavigatorStackedBarchartDatum[]}
              colorScale={colorScale}
              categoriesOrder={categoriesOrder}
              description={country}
              maxSumValue={priorityDatasetMaxSumValuesByCountry[country]}
            />
          )
        })}
      </div>
    </div>
  )
}
