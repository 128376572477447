import papa from 'papaparse'
import type { FetchDatasetOptions } from './fetch'

export function jsonToCsv<T>(json: T[], shouldIncludeHeader = true) {
  try {
    return papa.unparse(json, {
      quotes: (value) => typeof value === 'string',
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ',',
      header: shouldIncludeHeader,
      skipEmptyLines: 'greedy',
    })
  } catch (error) {
    console.log(`! Error unparsing json:`, (error as Error).message)
    return ''
  }
}

export function csvToJson<T>(
  csv: string,
  options: FetchDatasetOptions<T> = { columnNamesWithDynamicTyping: [] }
) {
  const dynamicTypingValue = options.columnNamesWithDynamicTyping.reduce(
    (acc, column) => {
      acc[column] = true
      return acc
    },
    {} as Record<keyof T, boolean>
  )
  const { data: json } = papa.parse<T>(csv, { header: true, dynamicTyping: dynamicTypingValue })
  return json
}

export function parseCsv<T>(csvContent: string): T[] {
  console.log('Start parsing csv content string...')

  return papa.parse<T>(csvContent, {
    header: true,
  }).data
}
