import { useNavigate } from 'react-router-dom'

import { convertToIconName, getCategoryIcon } from '../lib/icons'
import { useMst, useTranslations } from '../state'

const NavigatorPriorityCard = ({ id, path }: { id: string; path: string }) => {
  const { getTranslation } = useTranslations()
  const navigate = useNavigate()
  const {
    explorerToolSection: {
      updateIsCountry,
      updateDropdownItem,
      initNavigatorData,
      fetchPrioritySourceDataset,
    },
  } = useMst()

  const iconId = convertToIconName(id)
  const CategoryIcon = getCategoryIcon(iconId, false, 'ui')

  const title = getTranslation(`priorityCardTitle-${id}`)

  const description = getTranslation(`priorityCardDescription-${id}`)

  return (
    <div
      className={`col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 rounded-xl shadow-md bg-fii-white self-stretch`}
      onClick={async () => {
        navigate(path)
        updateIsCountry(false)
        updateDropdownItem(`study-area-${id}`)
        initNavigatorData()
        await fetchPrioritySourceDataset(id)
      }}
    >
      <div
        className={
          'bg-fii-blue-light cursor-pointer hover:bg-opacity-70 rounded-t-xl p-4 h-[94px] flex items-center'
        }
      >
        <div>
          <CategoryIcon />
        </div>
      </div>
      <div className="flex flex-col gap-4 cursor-pointer px-4 py-5">
        <div className="min-h-[65px]">
          <h3 className="text-2xl leading-120%">{title}</h3>
        </div>
        {description && <div className="text-sm leading-120%">{description}</div>}
      </div>
    </div>
  )
}

export { NavigatorPriorityCard }
