export const PriorityIcon = ({
  label,
  isSelected,
  onClick,
}: {
  label: string
  isSelected: boolean
  onClick?: () => void
}) => {
  return (
    <div
      className={`min-w-[40px] h-full ${
        isSelected ? 'bg-fii-blue-light' : 'bg-fii-grey-1'
      } hover:bg-opacity-70 cursor-pointer
      } rounded-[5px] shadow-md p-1 flex justify-center items-center`}
      onClick={onClick}
    >
      {label}
    </div>
  )
}
