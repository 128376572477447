import { observer } from 'mobx-react-lite'
import { useMeasure } from '@uidotdev/usehooks'
import * as _ from 'lodash-es'

import { useTranslations } from '../state'

type LegendDatum = {
  name: string
  color: string
}

interface MapLegendProps {
  dataset: LegendDatum[]
  strokeWidth?: number
  overlapWidth?: number
  height?: number
}

export const MapLegend = observer(
  ({ dataset, strokeWidth = 2, overlapWidth = 20, height = 12 }: MapLegendProps) => {
    const [legendContainerRef, { width: w }] = useMeasure<HTMLDivElement>()
    const width = w || 0
    const { getTranslation } = useTranslations()

    const piecesNumber = dataset.length
    const x = 0
    const y = 0

    const pieceWidth =
      (width - 2 * strokeWidth) / piecesNumber + (overlapWidth * (piecesNumber - 1)) / piecesNumber
    const pieceHeight = height

    return (
      <div className="w-full">
        <div ref={legendContainerRef} className="w-full pt-8">
          <svg x={x} y={y} width={width} height={height + strokeWidth}>
            {dataset.map((datum, i) => {
              const x = i * (pieceWidth - overlapWidth) + strokeWidth / 2
              const y = strokeWidth / 2

              return (
                <rect
                  key={datum.name}
                  className={`${datum.color} stroke-fii-light-bg-1`}
                  x={x}
                  y={y}
                  rx={height / 2}
                  ry={height / 2}
                  width={pieceWidth}
                  height={pieceHeight}
                  strokeWidth={strokeWidth}
                />
              )
            })}
          </svg>
        </div>

        <div className="w-full flex justify-between text-fii-grey-2 text-xs mt-1">
          {[_.first(dataset), _.last(dataset)].map((labelInfo, i) => {
            const isLast = i === 1

            return labelInfo ? (
              <div
                key={labelInfo.name}
                // TODO: shitty code, this should be handled by the legend pieces logic
                style={{ marginRight: isLast ? 2 * strokeWidth : 0 }}
              >
                {getTranslation(labelInfo.name)}
              </div>
            ) : null
          })}
        </div>
      </div>
    )
  }
)
