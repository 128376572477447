import { observer } from 'mobx-react-lite'

import { useMst } from '../state'
import { ExplorerToolPageCountryContent } from './ExplorerToolPageCountryContent'
import { ExplorerToolPagePriorityContent } from './ExplorerToolPagePriorityContent'

export const ExplorerToolPageContent = observer(() => {
  const {
    explorerToolSection: { isCountry },
  } = useMst()

  return (
    <div className="min-h-[calc(100vh-452px)] col-span-12 lg:col-span-9 flex flex-col gap-16 px-4 lg:px-0">
      {isCountry ? <ExplorerToolPageCountryContent /> : <ExplorerToolPagePriorityContent />}
    </div>
  )
})
