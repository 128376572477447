import { types as t, getRoot, Instance } from 'mobx-state-tree'

import type { StateInstance } from '.'
import type {
  SourceDatumContextualSectionInfo,
  SourceDatumContextualSectionModalInfo,
} from '../types/contextual'
import { COUNTRIES_INFO } from '../constants/geo'

export const ContextualSectionModel = t
  .model('ContextualSectionModel', {
    sourceContextualDataset: t.frozen([] as Array<SourceDatumContextualSectionInfo>),
    currentCountry: t.maybe(t.string),
    sourceContextualDatasetInfo: t.frozen([] as Array<SourceDatumContextualSectionModalInfo>),
  })
  .views((self) => ({
    get root(): StateInstance {
      return getRoot(self)
    },
  }))
  .views((self) => ({
    get currentContextualData() {
      const alpha2Code = self.currentCountry || window.location.pathname.split('-')[1]
      const studyArea: string = self.root.explorerToolSection.selectedNavigationButton
      const countryName = COUNTRIES_INFO.find((d) => d.alpha2Code === alpha2Code)?.countryName
      return self.sourceContextualDataset.find((d) => {
        return d.country === countryName && d.id === studyArea
      })
    },
  }))
  .actions((self) => ({
    updateSourceContextualDataset(dataset: SourceDatumContextualSectionInfo[]) {
      self.sourceContextualDataset = dataset
    },

    updateSourceContextualDatasetInfo(dataset: SourceDatumContextualSectionModalInfo[]) {
      self.sourceContextualDatasetInfo = dataset
    },

    updateCurrentCountry(countryCode: string) {
      self.currentCountry = countryCode
    },
  }))
  .actions((self) => ({
    async fetchSourceContextualDataset() {
      if (!self.root.fetchJsonDataset) {
        return
      }
      const sourceDataset = await self.root.fetchJsonDataset<SourceDatumContextualSectionInfo>(
        'datasets/formatted/contextual/contextualDataset.json'
      )

      const sourceInfoDataset =
        await self.root.fetchJsonDataset<SourceDatumContextualSectionModalInfo>(
          'datasets/formatted/contextual/contextualDatasetInfo.json'
        )
      // rename id values
      const dataset = sourceDataset.map((d) => {
        return {
          ...d,
          id: STUDY_AREAS_MAP[d.id],
        }
      })
      self.updateSourceContextualDataset(dataset)
      self.updateSourceContextualDatasetInfo(sourceInfoDataset)
    },
  }))
  .actions((self) => ({
    async afterCreate() {
      await self.fetchSourceContextualDataset()
    },
  }))

export const mapSectionModelStore = ContextualSectionModel.create({})

export interface ContextualSectionModelInstance extends Instance<typeof ContextualSectionModel> {}

const STUDY_AREAS_MAP: Record<string, string> = {
  sustainability: 'climate-change',
  technology: 'technology-and-innovation',
  humanResilience: 'health-and-well-being',
  politicalAndLegal: 'political-and-legal-landscape',
  economicGrowth: 'economic-growth',
  socialAndCulturalWellBeing: 'social-and-cultural-well-being',
}
