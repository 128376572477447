import { observer } from 'mobx-react-lite'

import type { NavigatorPrioritiesGroupedBy } from '../types/navigator'
import { useMst, useTranslations } from '../state'

interface Props {
  className?: string
}

export const ExplorerToolGroupByButtons = observer(({ className }: Props) => {
  const {
    explorerToolSection: { priorityGroupedBy, updatePriorityGroupedBy },
  } = useMst()
  const { getTranslation } = useTranslations()

  return (
    <div className={`flex justify-end gap-x-2 ${className}`}>
      {(['countries', 'regions'] as NavigatorPrioritiesGroupedBy[]).map((groupType) => {
        const isSelected = groupType === priorityGroupedBy

        return (
          <button
            key={groupType}
            className={`w-fit ${
              isSelected ? 'bg-fii-grey-2' : 'bg-fii-grey-1'
            } hover:bg-opacity-80 rounded-lg text-sm font-medium leading-6 px-3 py-1`}
            onClick={() => updatePriorityGroupedBy(groupType)}
          >
            {getTranslation(`navigatorByPriority-groupBy-${groupType}`)}
          </button>
        )
      })}
    </div>
  )
})
