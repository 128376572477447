import { observer } from 'mobx-react-lite'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/navigation'
import 'swiper/css'

import type { InsightCard } from '../types/insights'
import { ArrowPrevIcon } from './ArrowPrevIcon'
import { ArrowNextIcon } from './ArrowNextIcon'
import { InsightSlide } from './InsightSlide'
import { carouselBreakpoints } from '../lib/carousel'

export const WidgetInsights = observer(({ cards }: { cards: InsightCard[] }) => {
  return (
    <div className="w-full mr-4">
      <Swiper
        speed={500}
        loop={true}
        breakpoints={carouselBreakpoints}
        className={'swiper-custom cursor-grab'}
        navigation={{
          prevEl: '.image-swiper-button-prev',
          nextEl: '.image-swiper-button-next',
          disabledClass: 'swiper-button-disabled',
        }}
        style={{ gap: '32px' }}
        modules={[Navigation]}
      >
        {cards.map((card, i) => {
          return (
            <SwiperSlide
              key={i}
              className="rounded-xl bg-fii-white"
              style={{
                width: 300,
                height: 430,
              }}
            >
              <InsightSlide card={card} />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="flex w-full justify-center pt-8">
        <div className="swiper-button image-swiper-button-prev">
          <ArrowPrevIcon />
        </div>
        <div className="swiper-button image-swiper-button-next">
          <ArrowNextIcon />
        </div>
      </div>
    </div>
  )
})
