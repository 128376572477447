import { Fragment, ReactNode } from 'react'
import ReactMarkdown from 'react-markdown'
import { observer } from 'mobx-react-lite'

import { Dialog, Transition } from '@headlessui/react'
import { useTranslations, useUi } from '../state'
import { CloseIcon } from './Icons'
import { MethodologyModalContent } from './MethodologyModalContent'

export const Modal = observer(
  ({
    title,
    content,
    show,
    onClose,
  }: {
    title: string
    content: ReactNode
    show: boolean
    onClose: () => void
  }) => {
    return (
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="border-2 border-fii-black relative z-50 text-fii-black"
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fii-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto scrollbar-hidden">
            <div className="flex min-h-full justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="absolute  md:relative flex flex-col max-w-4xl h-[calc(100%-56px)] md:h-auto md:max-h-[80vh] overflow-y-auto scrollbar-hidden transform transition-all md:rounded-2xl bg-white gap-4 md:gap-8 mt-14 md:mt-auto m-auto px-4 md:pl-28 md:pr-48 py-8 md:py-8 text-left  shadow-xl ">
                  <div>
                    <div className="flex justify-end md:hidden">
                      <button onClick={onClose}>
                        <CloseIcon color="black" />
                      </button>
                    </div>
                    <Dialog.Title className="leading-[52px] text-[40px] lg:text-5.5xl pr-[110px]">
                      {title}
                    </Dialog.Title>
                  </div>
                  <div className="max-w-xl flex flex-col gap-2 my-2">{content}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  }
)

export const ModalAbout = observer(() => {
  const { isAboutModalOpen, closeAboutModal } = useUi()

  const { getTranslation } = useTranslations()

  const title = getTranslation('aboutModalTitle')
  const content = <ReactMarkdown>{getTranslation('aboutModalText')}</ReactMarkdown>

  return <Modal title={title} content={content} show={isAboutModalOpen} onClose={closeAboutModal} />
})

export const ModalMethodology = observer(() => {
  const { isMethodologyModalOpen, closeMethodologyModal } = useUi()

  const { getTranslation } = useTranslations()

  const title = getTranslation('methodologyModalTitle')
  const content = <MethodologyModalContent />

  return (
    <Modal
      title={title}
      content={content}
      show={isMethodologyModalOpen}
      onClose={closeMethodologyModal}
    />
  )
})
