import { Fragment } from 'react'
import * as _ from 'lodash-es'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Listbox, Transition } from '@headlessui/react'

import { createPriorityExplorerToolRoutes } from '../lib/routes'
import { useMst, useTranslations } from '../state'
import { ArrowDropdown } from './Icons'
import { COUNTRIES_INFO_BY_COUNTRY } from '../constants/geo'

export const ExplorerSectionDropdown = observer(({ id }: { id: string }) => {
  const navigate = useNavigate()
  const { getTranslation } = useTranslations()
  const {
    explorerToolSection: {
      isCountry,
      updateDropdownItem,
      fetchCountrySourceDataset,
      fetchPrioritySourceDataset,
    },
    contextualSection: { updateCurrentCountry },
  } = useMst()

  const [countriesRoutes, prioritiesRoutes] = _.partition(
    createPriorityExplorerToolRoutes(),
    (d) => d.isCountry
  )
  const itemsDropDown = isCountry
    ? _.cloneDeep(countriesRoutes).sort((a, b) => a.id.localeCompare(b.id))
    : prioritiesRoutes

  return (
    <Listbox value={id}>
      <div className="relative">
        <Listbox.Button
          className={`relative w-full h-10 flex items-center rounded-lg ${
            isCountry ? 'bg-fii-green-1' : 'bg-fii-blue-light'
          } pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm cursor-pointer`}
        >
          {id && (
            <span
              className={`block truncate leading-120% mt-1 ${
                isCountry ? 'text-fii-green-3' : 'text-fii-blue-dark'
              }`}
            >
              {isCountry
                ? getTranslation(`countryCardTitle-country-${id}`)
                : getTranslation(`priorityCardTitle-${id}`)}
            </span>
          )}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ArrowDropdown color={`${isCountry ? '#3A6600' : '#29414D'}`} />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 xl:mb-1  max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {itemsDropDown.map((section) => {
              return (
                <Listbox.Option
                  key={section.id}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 pl-2 pr-4 ${
                      active
                        ? isCountry
                          ? 'bg-fii-green-4 text-fii-black'
                          : 'bg-fii-blue-light text-fii-black'
                        : 'text-gray-900'
                    }`
                  }
                  onClick={async () => {
                    updateDropdownItem(isCountry ? `country-${id}` : `study-area-${section.id}`)
                    if (isCountry) {
                      const alpha2Code = COUNTRIES_INFO_BY_COUNTRY[section.id].alpha2Code
                      updateCurrentCountry(alpha2Code)
                      await fetchCountrySourceDataset(section.id)
                    } else {
                      await fetchPrioritySourceDataset(section.id)
                    }
                    navigate(`/${section.path}`)
                  }}
                  value={section}
                >
                  {({ selected }) => (
                    <span
                      className={`block text-sm leading-120% truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {isCountry ? section.id : getTranslation(`priorityCardTitle-${section.id}`)}
                    </span>
                  )}
                </Listbox.Option>
              )
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
})
