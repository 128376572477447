import { merge } from 'lodash-es'

export function formatNumber(value: number, lang: string | string[] | undefined = 'en') {
  return Intl.NumberFormat(lang).format(value)
}

type FormatPercentageOptions = {
  showAlwaysSign?: boolean
  minPrecision?: number
  maxPrecision?: number
}

export function formatPercentage(value: number, options?: FormatPercentageOptions) {
  const defaultOptions = { showAlwaysSign: false, minPrecision: 2, maxPrecision: 2 }
  const { showAlwaysSign, minPrecision, maxPrecision } = merge(defaultOptions, options)

  const roundedValue = value.toLocaleString('en-US', {
    maximumFractionDigits: minPrecision,
    minimumFractionDigits: maxPrecision,
  })

  const labelWithPercentageSymbol = `${roundedValue}%`

  if (showAlwaysSign) {
    if (value >= 0) {
      return `+${labelWithPercentageSymbol}`
    } else {
      return labelWithPercentageSymbol
    }
  }
  return labelWithPercentageSymbol
}
