import ReactMarkdown from 'react-markdown'
import remarkUnwrapImages from 'remark-unwrap-images'

const GRADIENT_TEMPLATE = '*g*'

interface RichTextProps {
  children: string
  className?: string
}

export const RichText = ({ children, className }: RichTextProps) => {
  const isGradient = children.startsWith(GRADIENT_TEMPLATE) && children.endsWith(GRADIENT_TEMPLATE)

  // NOTE: shitty code
  const supRegexResult = children.replace(/[^^]/g, '').length
  const isSup = supRegexResult === 2

  if (isGradient) {
    return <span className="text-gradient">{children.replaceAll(GRADIENT_TEMPLATE, '')}</span>
  }

  if (isSup) {
    const childrenSplitted = children.split('^')
    return (
      <span
        className={`prose prose-a:text-blue-600 hover:prose-a:text-blue-500 prose-code:before:content-none prose-code:after:content-none ${className}`}
      >
        <span>{childrenSplitted[0]}</span>
        <span>
          <sup>{childrenSplitted[1]}</sup>
        </span>
        <span>{childrenSplitted[2]}</span>
      </span>
    )
  }

  return (
    <ReactMarkdown
      className={`prose prose-a:text-blue-600 hover:prose-a:text-blue-500 prose-code:before:content-none prose-code:after:content-none ${className}`}
      children={children}
      // This package is a unified (remark) plugin that searches for paragraphs which contain only images
      // (possibly in links) and nothing else, and then remove those surrounding paragraphs.
      remarkPlugins={[remarkUnwrapImages]}
      components={{
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        a: ({ node, ...props }) => <a target="_blank" {...props} />,
      }}
    />
  )
}
