import { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useMst, useTranslations } from '../state'
import { RichText } from './RichText'

export const MapDescription = observer(() => {
  const [showPanel, setShowPanel] = useState(false)
  const { getTranslation } = useTranslations()
  const {
    mapSection: { selectedMapSectionId },
  } = useMst()

  const titleKey = `${selectedMapSectionId}Title`
  const descriptionKey = `${selectedMapSectionId}Description`

  return (
    <div className="mt-5 md:mt-0">
      <div className="text-4xl md:text-3.5xl mb-5 text-black">{getTranslation(titleKey)}</div>
      <div>
        <RichText className="text-base text-black leading-120%">
          {getTranslation(descriptionKey)}
        </RichText>
      </div>

      <div className="mt-5 pointer-events-auto">
        <button
          className="lg:min-w-[207px] h-10 flex items-center text-sm leading-120% bg-fii-menu-bg hover:bg-fii-grey-2 shadow-button px-4 rounded-lg text-fii-white w-full md:w-fit"
          onClick={() => setShowPanel(!showPanel)}
        >
          <div className="text-left">{getTranslation('downloadFIIPriorityCompassButton')}</div>
        </button>
      </div>

      {showPanel && (
        <div className="max-w-[301px] p-3 bg-fii-white absolute bottom-14 rounded-xl shadow-md flex flex-col gap-y-5 pointer-events-auto">
          <div>
            <DownloadButton link="reports/FII PRIORITY compass - Executive Report.pdf">
              {getTranslation('downloadFIIPriorityCompassModalButton-1')}
            </DownloadButton>
            <p className="mt-2 text-sm leading-120%">
              {getTranslation('downloadFIIPriorityCompassModalButtonDescription-1')}
            </p>
          </div>
          <div>
            <DownloadButton link="reports/FII PRIORITY compass - Full Report.pdf">
              {getTranslation('downloadFIIPriorityCompassModalButton-2')}
            </DownloadButton>
            <p className="mt-2 text-sm leading-120%">
              {getTranslation('downloadFIIPriorityCompassModalButtonDescription-2')}
            </p>
          </div>

          <p className="text-fii-grey-2 text-sm leading-120%">
            {getTranslation('downloadFIIPriorityCompassModalNote')}
          </p>
        </div>
      )}
    </div>
  )
})

interface DownloadButtonProps {
  link: string
  children: string
}

function DownloadButton({ link, children }: DownloadButtonProps) {
  return (
    <a
      href={link}
      target="_blank"
      className="w-full min-h-[40px] flex justify-center items-center py-1 px-3 bg-fii-menu-bg hover:bg-fii-grey-2 shadow-button rounded-lg text-fii-white text-sm leading-normal"
      rel="noreferrer"
    >
      <span className="">{children}</span>
    </a>
  )
}
