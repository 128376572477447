import ReactMarkdown from 'react-markdown'

import { useTranslations } from '../state'
import {
  AgeIcon,
  EducationIcon,
  EmploymentStatusIcon,
  GenderIcon,
  IncomeIcon,
  SocialClassIcon,
} from './MethodologyIcons'

const MethodologyModalContent = () => {
  const { getTranslation } = useTranslations()

  return (
    <div className="flex flex-col gap-8">
      <ReactMarkdown>{getTranslation('methodologyModalText')}</ReactMarkdown>
      <div className="model-card-container">
        <div className="model-card">
          <div className="model-card-number">23</div>
          <p>Countries</p>
        </div>
        <div className="model-card">
          <div className="model-card-number">50,000</div>
          <p>Respondents</p>
        </div>
        <div className="model-card">
          <div className="model-card-number">60%</div>
          <p>Global Population</p>
        </div>
      </div>

      <div>
        <h3 className="subtitle-model">Demographic Coverage</h3>
        <div className="model-card-container">
          <div className="model-card">
            <div>
              <AgeIcon />
            </div>
            <p>Age</p>
          </div>
          <div className="model-card">
            <div>
              <GenderIcon />
            </div>
            <p>Gender</p>
          </div>
          <div className="model-card">
            <div>
              <EducationIcon />
            </div>
            <p>Education</p>
          </div>

          <div className="model-card">
            <div>
              <IncomeIcon />
            </div>
            <p>Income</p>
          </div>
          <div className="model-card">
            <div>
              <SocialClassIcon />
            </div>
            <p>
              Social
              <br />
              Class
            </p>
          </div>
          <div className="model-card">
            <div>
              <EmploymentStatusIcon />
            </div>
            <p>
              Employment
              <br /> Status
            </p>
          </div>
        </div>
      </div>

      <div>
        <h3 className="subtitle-model">Geographic Coverage</h3>
        <div>
          <figure className="w-full">
            <img src="/map.png" alt="Logo" />
          </figure>
        </div>
      </div>
      <div>
        <h3 className="subtitle-model">{getTranslation('methodologyModalGlossaryTitle')}</h3>
        <ReactMarkdown className="max-w-lg leading-120%">
          {getTranslation('methodologyModalGlossaryText')}
        </ReactMarkdown>
        <div className="mt-4">
          <div className="flex border-b border-black w-full gap-10 md:gap-36 uppercase">
            <div className="w-1/2">Survey Terminology</div>
            <div className="w-1/2">Updated Terminology</div>
          </div>
          {DATA.surveyTerminology.map((item, i) => {
            return (
              <div className="flex border-b border-black w-full gap-10 md:gap-36" key={item.key}>
                <div className="w-1/2 leading-120% mt-4 mb-1">{item.value}</div>
                <div className="w-1/2 leading-120% mt-4 mb-1">
                  {DATA.updatedTerminology[i].value}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export { MethodologyModalContent }

const DATA = {
  surveyTerminology: [
    {
      type: 'surveyTerminology',
      key: 'economicGrowth',
      value: 'Economic growth and development\u00a0',
    },
    {
      type: 'surveyTerminology',
      key: 'humanResilience\u00a0',
      value: 'Human resilience\u00a0',
    },
    {
      type: 'surveyTerminology',
      key: 'politicalAndLegal',
      value: 'Political and legal stability',
    },
    {
      type: 'surveyTerminology',
      key: 'socialAndCultural',
      value: 'Social and cultural well-being',
    },
    {
      type: 'surveyTerminology',
      key: 'technologicalProgress',
      value: 'Technological progress',
    },
    {
      type: 'surveyTerminology',
      key: 'inflationOrRising',
      value: 'Inflation or rising cost of living',
    },
    {
      type: 'surveyTerminology',
      key: 'misinformation',
      value: 'Misinformation',
    },
    {
      type: 'surveyTerminology',
      key: 'iFeelEmotionally',
      value: 'I feel emotionally stable and resilient',
    },
    {
      type: 'surveyTerminology',
      key: 'iOftenFeelAlone',
      value: 'I often feel alone',
    },
    {
      type: 'surveyTerminology',
      key: 'iAmSatisfied',
      value: 'I am satisfied with the voice I have as a citizen of my country',
    },
    {
      type: 'surveyTerminology',
      key: 'myReligiousBeliefs',
      value: 'My religious beliefs and values influence my daily life',
    },
    {
      type: 'surveyTerminology',
      key: 'theDecisionsMade',
      value: 'The decisions made by public authorities are mostly fair and transparent',
    },
  ],
  updatedTerminology: [
    {
      type: 'updatedTerminology',
      key: 'costOfLivingAndQualityOfLife',
      value: 'Cost of living and quality of life',
    },
    {
      type: 'updatedTerminology',
      key: 'healthcare',
      value: 'Healthcare',
    },
    {
      type: 'updatedTerminology',
      key: 'governance',
      value: 'Governance',
    },
    {
      type: 'updatedTerminology',
      key: 'socialInclusion',
      value: 'Social inclusion',
    },
    {
      type: 'updatedTerminology',
      key: 'technology',
      value: 'Technology',
    },
    {
      type: 'updatedTerminology',
      key: 'costOfLiving',
      value: 'Cost of living',
    },
    {
      type: 'updatedTerminology',
      key: 'misleadingInformation',
      value: 'Misleading information',
    },
    {
      type: 'updatedTerminology',
      key: 'emotionalWellBeing',
      value: 'Emotional well-being',
    },
    {
      type: 'updatedTerminology',
      key: 'loneliness',
      value: 'Loneliness',
    },
    {
      type: 'updatedTerminology',
      key: 'freedomOfSpeech',
      value: 'Freedom of speech',
    },
    {
      type: 'updatedTerminology',
      key: 'identityAndValues',
      value: 'Identity and values',
    },
    {
      type: 'updatedTerminology',
      key: 'socialJustice',
      value: 'Social justice',
    },
  ],
}
