import { PillContent } from '../components/InsightSlide'

export const carouselBreakpoints = {
  300: {
    slidesPerView: 1.2,
  },
  400: {
    slidesPerView: 1.4,
  },
  460: {
    slidesPerView: 1.8,
  },
  640: {
    slidesPerView: 2,
  },
  690: {
    slidesPerView: 2.4,
  },
  768: {
    slidesPerView: 2.6,
  },
  840: {
    slidesPerView: 3.3,
  },
  1024: {
    slidesPerView: 3.3,
  },
  1280: {
    slidesPerView: 4.3,
  },
  1380: {
    slidesPerView: 4.6,
  },
  1420: {
    slidesPerView: 4.6,
  },
  1780: {
    slidesPerView: 5.3,
  },
  2080: {
    slidesPerView: 6.3,
  },
  2380: {
    slidesPerView: 7.3,
  },
  2500: {
    slidesPerView: 8.3,
  },
}

export function getPillTextContent(text: string) {
  switch (text) {
    case 'Cost of living and quality of life':
      return PillContent({ pillNumber: 1, text })
    case 'Social inclusion':
      return PillContent({ pillNumber: 2, text })
    case 'Climate change':
      return PillContent({ pillNumber: 3, text })
    case 'Governance':
      return PillContent({ pillNumber: 4, text })
    case 'Healthcare':
      return PillContent({ pillNumber: 5, text })
    case 'Technology':
      return PillContent({ pillNumber: 6, text })
    default:
      return text
  }
}

